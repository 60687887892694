import {Injectable} from '@angular/core';
import { ElectricityService} from './electricity.service';
import {Observable, of, throwError} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {
    BillPredictionResponse, BillPredictionResponseData
} from '../shared/interfaces/plain-responses/electricity-response.interface';

@Injectable({
    providedIn: 'root'
})
export class FinanceDataService {

    private billPredictionResponseCache: BillPredictionResponseData | null = null;
    private billPredictionLastRequest: number | null = null;
    private billPredictionCacheTime = 300000;

    constructor(
        private electricity: ElectricityService
    ) {
    }

    /**
     * Returns the mapped financial trend data
     */
    getFinancialTrend(): Observable<FinancialTrend> {
        return this.requestBillPredictionData().pipe(
            mergeMap((response) => {
                try {
                    if (response.sum_of_prepayments > 12) {
                        return of(response);
                    }
                } catch (error) {
                    throwError(error);
                }
            }),
            mergeMap((response) =>
                this.calculateTrend(response)
            )
        );
    }


    /**
     * Returns raw bill prediction data.
     * Either cached or fresh.
     * @private
     */
    private requestBillPredictionData(): Observable<BillPredictionResponseData> {
        let useCachedData = false;
        if (this.billPredictionResponseCache) {
            const now = new Date().getTime();
            const delta = now - this.billPredictionLastRequest;
            if (delta < this.billPredictionCacheTime) {
                useCachedData = true;
            } else {
                useCachedData = false;
            }
        }

        if (useCachedData) {
            return of(this.billPredictionResponseCache);
        } else {
            return this.electricity.getBillPrediction().pipe(
                tap((response) => {
                    this.billPredictionLastRequest = new Date().getTime();
                    this.billPredictionResponseCache = response;
                })
            );
        }
    }


    /**
     * Calculates a financial trend based on bill prediction response
     * @param responseData
     * @private
     */
    private calculateTrend(responseData: BillPredictionResponseData): Observable<FinancialTrend> {
        return new Observable<FinancialTrend>(observer => {
            const trend: FinancialTrend = {
                trend: 0,
                amount: 0,
                percentage: 0,
                available: false,
                timeframe: {
                    begin: null,
                    end: null
                }
            };

            try {
                const estimatedCost = responseData.estimated_cost_billing_period;
                const sumPrepayments = responseData.sum_of_prepayments;

                const difference = estimatedCost - sumPrepayments;
                const percentage = 100 * (difference) / sumPrepayments;

                const validData = sumPrepayments > 12 && (percentage > -50 && percentage < 50);
                if (!validData) {
                    observer.error(new Error('Data invalid for further calculation'));
                    return;
                }
                trend.available = validData;
                trend.percentage = Math.round(percentage);

                if (percentage > 10 && difference > 10) { // additional payment
                    trend.amount = Math.ceil(difference / 10) * 10;
                    trend.trend = 1;
                } else if (percentage < -10 && difference < -10) { // credit voucher
                    trend.amount = Math.abs(Math.floor(difference / 10) * 10);
                    trend.trend = -1;
                } else {
                    trend.trend = 0;
                    trend.amount = 0;
                }


                trend.timeframe.begin = new Date(responseData.billing_period_from);
                trend.timeframe.end = new Date(responseData.billing_period_until);

                observer.next(trend);
            } catch (error) {
                observer.error(error);
            }
            observer.complete();
        });
    }
}

export interface FinancialTrend {
    trend: number;
    amount: number;
    percentage: number;
    available: boolean;
    timeframe: {
        begin: Date;
        end: Date;
    };
}
