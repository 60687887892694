import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {InitializationService} from './initialization.service';
import {MeterService} from './meter.service';
import {AccountRewriteService} from './account-rewrite.service';
import {
    initialMeterBaseStats,
    initialMeterData,
    initialMeterValues,
    MeterBaseStats,
    MeterData,
    MeterValues
} from '../shared/interfaces/meter.interfaces';
import {
    InitializationProfile
} from '../shared/interfaces/plain-responses/initialization-response.interface';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {InternalMeterConnectionStatus} from '../shared/enums/meter-status.enum';
import {ViewState} from '../shared/enums/view-state.enum';
import {ElectricityService} from './electricity.service';
import * as moment from 'moment/moment';


@Injectable({
    providedIn: 'root'
})
export class MeterDataProviderService implements OnDestroy {
    private meterValueSubscription: Subscription | null = null;

    combinedMeterData$ = new BehaviorSubject<MeterData>({
        ...initialMeterData
    });

    dateSpecificMeterData$ = new BehaviorSubject<MeterValues>({
        ...initialMeterValues
    });


    constructor(
        private initializationService: InitializationService,
        private meterService: MeterService,
        private accountRewriteService: AccountRewriteService,
        private electricityService: ElectricityService,
    ) {
    }


    ngOnDestroy() {
        if (this.meterValueSubscription) {
            this.meterValueSubscription.unsubscribe();
            this.meterValueSubscription = null;
        }
        this.dateSpecificMeterData$.next(initialMeterValues);
        this.combinedMeterData$.next(initialMeterData);
    }


    /**
     * Request meter values for a specific date.
     * @param date
     */
    requestMeterValuesForDate(date: Date): void {
        const offtake$ = this.requestConsumptionForDate$(date);
        const feedin$ = this.requestFeedinForDate$(date);
        combineLatest([offtake$, feedin$]).pipe(
            map(([offtake, feedin]) =>
                ({offtake, feedin})
            )
        ).subscribe({
            next: (meterValues) => {
                this.dateSpecificMeterData$.next(meterValues);
            }
        });

    }


    /**
     * Initializes the combined meter data update.
     * Combines:
     *   - meter status
     *   - meter values (from meter/info)
     *   - and meter stats (from initialization)
     * to be consumed by the meter tile & detail view.
     * @private
     */
    initializeCombinedMeterDataUpdate(): void {
        this.combinedMeterData$.next(initialMeterData);
        if (this.meterValueSubscription) {
            return;
        }
        this.meterValueSubscription = this.requestMeterStatusConnectionQuality$().pipe(
            mergeMap((meterWifiConnectionQuality) => {
                return combineLatest([
                    of(meterWifiConnectionQuality),
                    this.meterService.getCurrentMeterValueNew(),
                    this.requestMeterStatsFromInitialization$()
                ]).pipe(
                    map(([wifiConnectionQuality, meterValues, meterStats]) => {
                        return {
                            wifiConnectionQuality,
                            meterValues,
                            meterStats,
                            viewState: ViewState.SUCCESS
                        } as MeterData;
                    }),
                );
            }),
            catchError(error => {
                console.log('Error in meter status update initialization: ', error);
                return of({
                    ...initialMeterData,
                    viewState: ViewState.ERROR
                });
            })
        ).subscribe({
            next: (meterStatus) => {
                this.combinedMeterData$.next(meterStatus);
            }
        });
    }


    /**
     * Request the meter status from the meter service.
     * Only returns the wifi connection quality since all other info is not required.
     * @private
     */
    private requestMeterStatusConnectionQuality$(): Observable<number> {
        this.meterService.startLiveUpdate();
        return this.meterService.onMeterStatus.pipe(
            map((meterStatus) => {
                return meterStatus.wlan_rssi;
            })
        );
    }


    /**
     * Request the meter stats from the initialization service.
     * Contains the meter serial number, connection status and if the meter is connected.
     * @private
     */
    private requestMeterStatsFromInitialization$(): Observable<MeterBaseStats> {
        return this.initializationService.getWithCache(
            this.accountRewriteService.accountRewriteEnabled()
        ).pipe(
            mergeMap((response: InitializationProfile) => {
                return of({
                    connectionStatus: this.determineMeterStatus(response.meter_status_electricity),
                    meterSerialNumber: this.formatMeterSerialNumber(response.meter_serial_number),
                } as MeterBaseStats);
            }),
            catchError((error) => of({
                ...initialMeterBaseStats
            }))
        );
    }


    /**
     * Requests consumption aka offtake meter value for a specific date
     * @param date
     * @private
     */
    private requestConsumptionForDate$(date: Date): Observable<number> {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        return this.electricityService.getConsumptionForMeterDetailSpecificDate(formattedDate)
            .pipe(
                catchError((error) => of(null))
            );
    }


    /**
     * Requests feedin meter value for a specific date
     * @param date
     * @private
     */
    private requestFeedinForDate$(date: Date): Observable<number> {
        return this.electricityService.getFeedinDataForMeterDetailSpecificDate(date)
            .pipe(
                catchError((error) => of(null))
            );
    }


    /**
     * Determine the internal meter status based on the value returned from the API.
     * @param value
     * @private
     */
    private determineMeterStatus(value: number): InternalMeterConnectionStatus {
        switch (value) {
            case 0:
                return InternalMeterConnectionStatus.CONNECTED;
            case 1:
                return InternalMeterConnectionStatus.DISCONNECTED;
            default:
                return InternalMeterConnectionStatus.PENDING;
        }
    }


    /**
     * Returns the meter serial number in a segmented more readable format.
     * @param serialNumber
     * @private
     */
    private formatMeterSerialNumber(serialNumber: string): string {
        if (serialNumber === null || serialNumber === undefined || serialNumber.length < 1) {
            return serialNumber;
        }
        let formatted = '';
        for (let i = 0; i < serialNumber.length; i++) {
            if (i === 1 || i === 4 || i === 6 || i === 10) {
                formatted += ' ';
            }
            formatted += serialNumber.charAt(i);
        }
        return formatted;
    }
}
