<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Vergleich - Statisch; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading">{{ 'screens.dashboard.comparison.tileHeading' | translate }}</h3>
            <div class="tile-content">

                <div #p class="comparison-chart-container">
                    <div class="tile-chart-abs" [hidden]="!diagramInitialized" [chart]="chart"></div>
                </div>

                <div class="comparison-stats center-contents">
                    <div class="p-b-s">
                        <div class="eon-regular date m-b-s">{{formerValues.name}}</div>
                        <div class="eon-bold value">{{formerValues.empty ? '-' : formerValues.y}} kWh</div>
                    </div>
                    <div class="p-b-s">
                        <div class="eon-regular date m-b-s">{{currentValues.name}}</div>
                        <div class="eon-bold value">{{currentValues.empty ? '-' : currentValues.y }} kWh</div>
                    </div>
                </div>

                <div class="center-contents comparison-summary">
                    <h4 *ngIf="trend.percent > 0 && trend.percent < 9999" class="eon-regular">
                        <strong>{{ trend.percent }} % {{ trend.direction > 0 ? ('screens.dashboard.comparison.comparisonSummary.more' | translate) : ('screens.dashboard.comparison.comparisonSummary.less' | translate) }}</strong>
                        {{'common.asIn' | translate}} {{ trend.monthname }}</h4>
                </div>

            </div>
        </div>
    </div>
</div>
