<div class="overlay-minimal">
    <div class="overlay-container">
        <h1 class="font-bold">Info</h1>
        <p class="font-regular" *ngFor="let p of content">
            {{p}}
        </p>
    </div>
    <button class="eon-button-new turquoise"
            (click)="close(null)">
        Okay
    </button>
</div>
