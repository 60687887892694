import {Component, OnInit} from '@angular/core';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/household-comparison-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {
    HouseholdComparisonData,
    HouseholdComparisonRankingData,
    RankingPosition
} from '../../../shared/interfaces/household-comparison.interfaces';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-household-comparison-rank',
    templateUrl: './household-comparison-rank.component.html',
    styleUrls: ['./household-comparison-rank.component.scss']
})
export class HouseholdComparisonRankComponent implements OnInit {
    LABELS;
    TEXTS;

    protected readonly RankingPosition = RankingPosition;
    protected readonly ViewState = ViewState;

    combinedClusterRankData$: Observable<{
        householdRankData: HouseholdComparisonRankingData,
        combinedClusterRankData: HouseholdComparisonData
    }> = this.dataProvider.rankingData$.pipe(
        mergeMap(householdRankData => {
            return this.dataProvider.combinedClusterRankingData$.pipe(
                map(combinedClusterRankData => ({householdRankData, combinedClusterRankData})),
            );
        }),
    );


    constructor(
        private dataProvider: HouseholdComparisonDataProviderService,
        private translate: TranslateService,
    ) {
    }


    ngOnInit(): void {
        this.dataProvider.getRankingData();
        this.translate.get('screens.dashboard.householdComparison.detailTexts').subscribe(texts => {
            this.TEXTS = texts;
        });

        this.translate.get('screens.dashboard.householdComparison.detailLabels').subscribe(labels => {
            this.LABELS = labels;
        });
    }

}
