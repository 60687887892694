import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/household-comparison-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';


@Component({
    selector: 'app-household-comparison-total',
    templateUrl: './household-comparison-total.component.html',
    styleUrls: ['./household-comparison-total.component.scss']
})
export class HouseholdComparisonTotalComponent implements OnInit {
    LABELS;
    protected readonly ViewState = ViewState;


    householdComparisonData$ =
        this.householdComparisonDataProvider.combinedClusterRankingData$;

    constructor(
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService,
        private translate: TranslateService
    ) {
    }


    ngOnInit(): void {
        this.translate.get('screens.dashboard.householdComparison.detailLabels').subscribe(labels => {
            this.LABELS = labels;
        });
    }

}
