<div class="connection-container">
    <div class="battery-state-container">
        <div class="battery-state-icon"
             [ngStyle]="{
                 'mask-image': getBatteryStateIconUrl(currentBatteryState),
                 '-webkit-mask-image': getBatteryStateIconUrl(currentBatteryState),
                 'background-color': currentBatteryState === 1 ? '#b00402' : '#1ea2b1'}"
        ></div>
    </div>
    <div [style.color]="determineTextColor()"
         [ngClass]="{'eon-bold': !inSettings}">
        <ng-container *ngIf="isAvailable; else notAvailable">
            {{ currentBatteryState > 0 ? (currentBatteryState * 2) + '% ' + (currentBatteryState > 0 ? ('connection.charging' | translate) : ('connection.pluggedIn' | translate)) : ('connection.pluggedIn' | translate) }}
        </ng-container>
        <ng-template #notAvailable>
            {{ 'common.notAvailable' | translate }}
        </ng-template>
    </div>
    <div class="warn-triangle" *ngIf="!inSettings && currentBatteryState === 1"></div>
</div>
