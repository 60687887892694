import {Component, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';


@Component({
    selector: 'app-comparison-chart-base',
    templateUrl: './comparison-chart-base.component.html',
    styleUrls: ['./comparison-chart-base.component.scss']
})
export class ComparisonChartBaseComponent implements OnInit {

    protected isCostDisplayMode = false;
    protected chart: Chart = null;


    constructor() {
    }


    ngOnInit(): void {
    }


    /**
     * Updates the chart's display mode.
     * @param isCost
     */
    updateDisplayMode(isCost: boolean): void {
        this.isCostDisplayMode = isCost;
    }


    /**
     * Resets the chart by removing all series.
     */
    reset(): void {
        if (!this.chart) {
            console.log('Chart not initialized yet');
            return;
        }
        this.chart.ref$.subscribe({
            next: ref => {
                this.removeAllSeries(ref);
            }
        });
    }


    /**
     * Removes all series from the chart.
     * @param chart
     * @protected
     */
    protected removeAllSeries(chart: Highcharts.Chart): void {
        if (!this.chart) {
            console.log('Chart not initialized yet');
            return;
        }
        while (chart.series.length > 0) {
            chart.series[0].remove(false);
        }
    }


}
