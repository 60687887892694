<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">
            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">{{config.title}}</h1>
                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>
                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>
            <section #detailContainer class="detail-content detail-content-override">
                <iframe #frame class="mvp-details-iframe inflate" frameborder="0" (load)="onIFrameLoaded()"></iframe>
            </section>
        </div>
    </div>
</div>
