import {ViewState} from '../enums/view-state.enum';
import {ApplianceDiagramSeriesData} from './appliances-categories.interfaces';

export interface AppliancesTileData {
    series: ApplianceDiagramSeriesDataWrapper;
    nilmDataAvailable: boolean;
    nilmProfileComplete: boolean;
    nilmRetrainingInProgress: boolean;
    currentMonth: string;
    viewState: ViewState;
}


export const initialAppliancesTileData: AppliancesTileData = {
    series: {
        series: [],
        nilm: []
    },
    nilmDataAvailable: false,
    nilmProfileComplete: false,
    nilmRetrainingInProgress: false,
    currentMonth: '',
    viewState: ViewState.INITIAL
};


export interface ApplianceDiagramSeriesDataWrapper {
    series: ApplianceDiagramSeriesData[];
    nilm: boolean[];
}
