import {PopoverRef} from '../popovers/popover/popover-ref';
import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

// TODO: Add Angular decorator.
@Component({
    selector: 'base-popover',
    template: ''
})
export class BasePopover implements OnDestroy {

    private static instances: BasePopover[] = [];

    protected subscriptions: Subscription[] = [];


    static closeAll(): void {
        for (const instance of BasePopover.instances) {
            instance.close();
        }
    }


    constructor(protected popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
        BasePopover.instances.push(this);
    }


    ngOnDestroy(): void {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }


    addSub(s: Subscription): void {
        this.subscriptions.push(s);
    }


    close(value = false): void {
        this.popoverRef.close(value);
    }


    private initializePopoverData(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
    }
}
