<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="{{ 'common.menu' | translate }}" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> {{ 'common.menu' | translate }}</span>
        </a>
        <app-menu activeElement="imprint"></app-menu>
    </aside>
    <div id="inner">
        <main class="page">
            <div class="page-card">
                <section #imprintContainer class="imprint"></section>
            </div>
        </main>
    </div>
</div>
