import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-eon-toggle',
    templateUrl: './eon-toggle.component.html',
    styleUrls: ['./eon-toggle.component.scss']
})
export class EonToggleComponent implements OnInit {
    @Output() private stateChanged = new EventEmitter<boolean>();

    @Input() state = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    onToggle(event): void {
        this.state = !this.state;
        event.stopPropagation();
        this.stateChanged.emit(this.state);
    }
}
