import {NgModule} from '@angular/core';
import {Routes, RouterModule, Router, NavigationStart} from '@angular/router';

import {DashboardComponent} from './views/dashboard/dashboard.component';
import {ProfileComponent} from './views/profile/profile.component';
import {HouseholdComponent} from './views/household/household.component';
import {SettingsComponent} from './views/settings/settings.component';
import {ContactComponent} from './views/contact/contact.component';
import {ImprintComponent} from './views/imprint/imprint.component';
import {LoginComponent} from './views/login/login.component';
import {RegisterComponent} from './views/register/register.component';
import {AuthGuardService} from './services/auth-guard.service';
import {TrackAnalyticsService} from './services/track-analytics.service';
import {HelpComponent} from './help/help.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuardService],

    },
    {
        path: 'meine-daten',
        component: ProfileComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'mein-haushalt',
        component: HouseholdComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'einstellungen',
        component: SettingsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'einstellungen/:open',
        component: SettingsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'kontakt',
        component: ContactComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'hilfe',
        component: HelpComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'impressum',
        component: ImprintComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'login/demo',
        component: LoginComponent
    },
    {
        path: 'registrieren',
        component: RegisterComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: false}),
    ],
    exports: [
        RouterModule,
    ]
})

export class AppRoutingModule {

    previous_page: string = null;

    constructor(private _router: Router,
                private analytics: TrackAnalyticsService) {

        this._router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.trackPage(event.url, this.previous_page);

                this.previous_page = event.url;
            }
        });

    }

    /**
     * Google Analytics Track Page Event
     *
     * @param page
     * @param previous_page
     */
    trackPage(page: string, previous_page: string) {
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: ' + this.getPageByRoute(page) + '; previous_screen: ' + this.getPageByRoute(previous_page)
            }
        });
    }

    /**
     * Seiten-Name anhand der Route identifizieren (Für Google Analytics)
     *
     * @param page
     */
    getPageByRoute(page: string) {
        switch (page) {
            case '/':
                return 'Übersicht';
            case '/meine-daten':
                return 'Meine Daten';
            case '/mein-haushalt':
                return 'Mein Haushalt';
            case '/mein-kundenkonto':
                return 'Mein Kundenkonto';
            case '/einstellungen':
                return 'Einstellungen';
            case '/kontakt':
                return 'Kontakt';
            case '/impressum':
                return 'Impressum';
            case '/datenschutz':
                return 'Datenschutz';
            case '/login':
                return 'Login';
            case '/registrieren':
                return 'Registrieren';
            case '/passwort-vergessen':
                return 'Passwort vergessen';
            default:
                return 'Unbekannt';
        }
    }

}

export const routedComponents: any[] = [
    DashboardComponent,
    ProfileComponent,
    HouseholdComponent,
    SettingsComponent,
    ContactComponent,
    ImprintComponent,
    LoginComponent,
    RegisterComponent,
];
