<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">
            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">{{ 'screens.dashboard.consumptionAlert.title' | translate }}</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Verbrauchs-Alarm"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <h2 class="eon-black">{{ 'screens.dashboard.consumptionAlert.info.title' | translate }}</h2>
                    <p>{{ 'screens.dashboard.consumptionAlert.info.text' | translate }}</p>
                </div>
            </section>

            <section class="detail-content">
                <ng-container *ngIf="!showEmptyState; else emptyState">
                    <app-consumption-alert-chart
                        [isDetail]="true"
                        (chartLoaded)="onChartLoaded($event)">
                    </app-consumption-alert-chart>

                    <div class="diagram-controls">
                        <div class="left">
                            <button class="eon-icon-button small"
                                    [disabled]="currentDataOffset === maxDataOffset"
                                    (click)="stepBack()">
                                <i class="chevron-left-s white"></i>
                            </button>
                        </div>
                        <div class="right">
                            <button class="eon-icon-button small"
                                    [disabled]="currentDataOffset === 0"
                                    (click)="stepForward()">
                                <i class="chevron-right-s white"></i>
                            </button>
                            <div class="m-r-m"></div>
                            <button class="eon-button-new small"
                                    [disabled]="currentDataOffset === 0"
                                    (click)="resetHistory()">
                                {{ 'common.now' | translate }}
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-template #emptyState>
                    <div class="animation-wrapper">
                        <div class="animation-container"
                             lottie [options]="lottieConfig">
                        </div>
                    </div>
                </ng-template>
            </section>
        </div>
    </div>
</div>
