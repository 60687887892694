<div class="overlay-minimal">
    <div class="overlay-container-cdk">
        <h1>{{title}}</h1>
        <p>{{text}}</p>
        <div class="interaction-area">
            <button class="eon-button-new"
                    [class.turquoise]="turquoise"
                    (click)="close(true)">
                {{positive}}
            </button>

            <ng-container *ngIf="negative !== ''">
                <div class="m-h-m"></div>
                <button class="eon-button-new"
                        [class.turquoise]="turquoise"
                        (click)="close(false)">
                    {{negative}}
                </button>
            </ng-container>

            <ng-container *ngIf="infoLink">
                <button class="eon-button-new turquoise"
                        (click)="openInfo(infoLink)">
                    {{'popovers.moreInfo' | translate}}
                </button>
            </ng-container>
        </div>
    </div>
</div>
