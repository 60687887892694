import {Injectable} from '@angular/core';
import {User} from '../classes/user';
import {Subject} from 'rxjs';
import {MvpConfig} from './mvp.service';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';
import {constants} from '../shared/constants/constants';
import {HouseholdComparisonProps} from '../shared/interfaces/household-comparison.interfaces';
import {HouseholdComparisonTimeframe} from '../shared/enums/household-comparison-timeframe.enum';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public plugAttributesChanged = new Subject();


    constructor() {
    }


    setCurrentUser(user: User): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        let users_obj;
        if (users_str === null || users_str === undefined) {
            users_obj = [];
            // set initial value of consumption alert to false
            user.initial_consumption_alert = true;
            user.tiles = null;
            users_obj.push(user);
            localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
        } else {
            users_obj = JSON.parse(users_str);
            let user_exists = false;
            for (const u of users_obj) {
                if (u.email === user.email) {
                    u.access_token = user.access_token;
                    u.refresh_token = user.refresh_token;
                    u.token_expires = user.token_expires;
                    user_exists = true;
                }
            }

            if (!user_exists) {
                users_obj.push(user);
            }

            localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
        }

        this.setActiveUser(user.email);
    }


    getUser(email: string): User {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        return users_obj.find((user: User) => user.email === email);
    }


    getActiveUserName(): string {
        return this.getElementForKey(StorageAttributes.USER_ACTIVE);
    }


    setActiveUserProvider(provider: string): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }

        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.provider = provider;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getActiveUserProvider(): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.provider.toLowerCase();
            }
        }
        return null;
    }


    setActiveUserTiles(tiles: any[]): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.tiles = tiles;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getActiveUserTiles(): any[] {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.tiles;
            }
        }
        return null;
    }


    getActiveUserAccessToken(): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                const token = user.access_token;
                return token;
            }
        }
        return null;
    }


    updateActiveUserAccessToken(access_token: string): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = access_token;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getActiveUserRefreshToken(): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.refresh_token;
            }
        }
        return null;
    }


    updateActiveUserRefreshToken(refresh_token: string): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.refresh_token = refresh_token;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getActiveUserTokenExpire(): Date {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.token_expires;
            }
        }
        return null;
    }


    updateActiveUserTokenExpire(new_expire: Date): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let expires;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.token_expires = new_expire;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    logoutActiveUser(): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return;
        }
        const users = JSON.parse(users_str) as any[];
        const userFoundIdx = users.findIndex(el => el.email === this.getActiveUserName());
        if (userFoundIdx >= 0) {
            users.splice(userFoundIdx, 1);
        }
        this.removeActiveUser();
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users));
    }


    getActiveUserNilmStatus(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.nilm_status;
            }
        }
        return null;
    }


    updateActiveUserNilmStatus(nilm_status): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.nilm_status = nilm_status;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    updateActiveUserNilmStatusForAppliance(appliance, model_count): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let nilm_values;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                nilm_values = user.nilm_status;
            }
        }

        nilm_values['timeBasedAppliances'][appliance].models = model_count;

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getLocalKeyValueStore(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.storage;
            }
        }
        return null;
    }


    getPlugAttributes(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    return null;
                }
                if (user.storage.power_checker === null) {
                    return null;
                }
                return user.storage.power_checker;
            }
        }
        return null;
    }


    updatePlugAttributes(attributes: any): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    user.storage = {};
                }
                user.storage.power_checker = attributes;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    deleteAccessToken(): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = null;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    isBoxUser(): boolean {
        const device = this.getUserDevice();
        switch (device) {
            case constants.application.devices.plug:
            case constants.application.devices.plug_optical:
                return false;
            default:
                return true;
        }
    }


    getUserDevice(): string | 'box' | 'plug' {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.device === null || user.device === undefined) {
                    return null;
                }
                return user.device;
            }
        }
        return null;
    }


    updateUserDevice(device: string): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.device = device;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    setConsumptionAlertAttribute(val: boolean): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.initial_consumption_alert = val;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getLastMvpTileConfigNew(): MvpConfig[] {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if ('mvp_config_new' in user) {
                    if (user.mvp_config_new === null || user.mvp_config_new === undefined) {
                        return null;
                    }
                } else {
                    return null;
                }
                return user.mvp_config_new;
            }
        }
        return null;
    }


    setMvpTileConfigNew(configs: MvpConfig[]): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.mvp_config_new = configs;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    hasPhaseChecker(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.phaseCheckerAvailable;
            }
        }
        return null;
    }


    setPhaseCheckerAvailability(status: boolean): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.phaseCheckerAvailable = status;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getPhaseThreshold(): number {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.phaseThreshold;
            }
        }
        return null;
    }


    setPhaseThreshold(value: number): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.phaseThreshold = value;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    addTarrifInfo(info): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.tariffs) {
                    user.tariffs.push(info);
                } else {
                    user.tariffs = [];
                    user.tariffs.push(info);
                }
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getTariffInfo(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.tariffs;
            }
        }
        return null;
    }


    setEDGUser(is_edg: boolean): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.edg_user = is_edg;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    isEDGUser(): boolean {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.edg_user;
            }
        }
        return false;
    }


    isMMEWMSBUser(): boolean {
        return this.getActiveUserProvider() === 'mme@wmsb'
            || this.getActiveUserProvider() === 'mME@WMSB';
    }


    hasUser(): boolean {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email !== 'demo') {
                return true;
            }
        }
        return false;
    }


    setLiveDetailZoomLevel(level: number): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.liveZoomLevel = level;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getLiveDetailZoomLevel(): number | null {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.liveZoomLevel ? user.liveZoomLevel : null;
            }
        }
    }


    setLastYearsTotalConsumption(value: number): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.lastYearElectricityConsumption = value;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getLastYearsTotalConsumption(): number {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.lastYearElectricityConsumption ?
                    user.lastYearElectricityConsumption :
                    null;
            }
        }
    }


    setCurrentHouseholdComparisonFilter(props: HouseholdComparisonProps): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.householdComparisonFilterProps = props;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getCurrentHouseholdComparisonFilter(): HouseholdComparisonProps | null {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user?.householdComparisonFilterProps ?
                    user?.householdComparisonFilterProps :
                    null;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    setCurrentHouseholdComparisonTimeframe(timeframe: HouseholdComparisonTimeframe): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.householdComparisonTimeframe = timeframe;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    getCurrentHouseholdComparisonTimeframe(): HouseholdComparisonTimeframe | null {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user?.householdComparisonTimeframe ?
                    user?.householdComparisonTimeframe :
                    null;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }


    private setActiveUser(email: string): void {
        localStorage.setItem(StorageAttributes.USER_ACTIVE, email);
    }


    private removeActiveUser(): void {
        localStorage.removeItem(StorageAttributes.USER_ACTIVE);
    }


    private getElementForKey(key: string): any {
        return localStorage.getItem(key);
    }
}
