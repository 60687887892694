import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {InitializationService} from '../../services/initialization.service';
import {ProfileService} from '../../services/profile.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {RegistrationService} from '../../services/registration.service';
import {MeterService} from '../../services/meter.service';
import * as moment from 'moment';
import {Popover} from '../../popovers/popover/popover.service';
import {ChangeEmailComponent} from '../../popovers/change-email/change-email.component';
import {ResetPasswordComponent} from '../../popovers/reset-password/reset-password.component';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {MfaService} from '../../services/mfa.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {PopoverConfigService} from '../../popovers/static.popover.config';
import {iif, of, throwError} from 'rxjs';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {AccountRewriteService} from '../../services/account-rewrite.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'iona-app',
    styleUrls: ['profile.component.scss'],
    templateUrl: './profile.component.html',
    viewProviders: []
})

export class ProfileComponent extends BaseComponent implements OnInit, OnDestroy {
    moment = moment;

    // provider
    provider = 0;
    username = null;

    // tariff
    contract: any = {
        name: '',
        tarif: null,
        basicprice: null,
        workingprice: null
    };

    mfaEnabled = false;
    accountRewriteEnabled = false;

    constructor(public accountRewrite: AccountRewriteService,
                public application: ApplicationService,
                private title: Title,
                private toastrService: ToastrService,
                private userService: UserService,
                private initialization: InitializationService,
                private profile: ProfileService,
                private versionService: VersionService,
                private meter: MeterService,
                private registration: RegistrationService,
                private popover: Popover,
                private optInService: LocalOptInService,
                private mfaService: MfaService,
                private gtm: GTMWrapperService,
                private popoverConfigService: PopoverConfigService,
                private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.translate.get('screens.profile.pageTitle').subscribe((title: string) => {
            this.title.setTitle(title);
        });
        this.optInService.checkStatus();
        this.username = this.userService.getActiveUserName();

        this.initialize();
        this.requestMFAStatus();
    }

    initialize(): void {
        const initSub = this.initialization.get(
            this.accountRewrite.accountRewriteEnabled()
        ).subscribe((res) => {
            if (!res || !('profile' in res)) {
                return;
            }
            this.processContractData(res);
        });
        this.addSub(initSub);

        const profileSub = this.profile.getContract(
            this.accountRewrite.accountRewriteEnabled()
        ).subscribe(
            (res: any) => {
                if (!('profile' in res)) {
                    return;
                }
                this.processProfileData(res);
            }
        );
        this.addSub(profileSub);
    }

    ngOnDestroy() {
    }

    openChangeEmailPopover(): void {
        const s = this.popover.open({
            content: ChangeEmailComponent,
            data: {},
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res: any) => {
            if (!res.data) {
                s.unsubscribe();
                return;
            }
            if (res.data.success) {
                this.changeEmail(res.data.value);
            }
            s.unsubscribe();
        });
    }

    openResetPasswordPopover(): void {
        const s = this.popover.open({
            content: ResetPasswordComponent,
            data: {},
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res: any) => {
            if (!res.data.success) {
                s.unsubscribe();
                return;
            }
            const d = res.data.values as {
                oldPassword: string,
                firstNewPassword: string,
                secondNewPassword: string
            };
            this.changePassword(d.oldPassword, d.firstNewPassword, d.secondNewPassword);
            s.unsubscribe();
        });
    }

    enableDisableMFA(): void {
        if (!this.mfaEnabled) {
            this.triggerMfaEnablePipeline();
            return;
        }
        this.triggerMfaDisablePipeline();
    }

    openLink() {
        window.open('https://www.eon.de/de/meineon/start.html');
    }

    determineDisplayUsername(): string {
        if (this.accountRewrite.accountRewriteEnabled()) {
            return this.accountRewrite.userToRewrite;
        }
        return this.username === 'Demo' || this.username === 'demo'
            ? 'smartcontrol@eon.com' : this.username;
    }

    private changeEmail(email: string) {
        if (this.application.isDemoMode()) {
            this.toastrService.info(this.translate.instant('screens.profile.demoModeEmailChange'));
            return;
        }
        const sub = this.registration.setEmail(email).subscribe(
            (res) => {
                this.toastrService.success(this.translate.instant('screens.profile.emailChangeSuccess'));
                this.username = email;
                this.trackEmailChangeSuccessful();
            },
            (error: any) => {
                this.trackEmailChangeErrorEvent(error);
                if (error._body) {
                    const err: any = JSON.parse(error._body);
                    switch (error.error.code) {
                        case 104: {
                            this.toastrService.error(this.translate.instant('screens.profile.invalidEmail'));
                            break;
                        }
                        default: {
                            this.toastrService.error(this.translate.instant('screens.profile.emailChangeFailed'));
                        }
                    }
                } else {
                    this.toastrService.error(this.translate.instant('screens.profile.emailChangeFailed'));
                }
                sub.unsubscribe();
            }
        );
    }

    private changePassword(oldPass: string, newPass: string, newPassRepeat: string) {
        if (this.application.isDemoMode()) {
            return this.toastrService.info(this.translate.instant('screens.profile.demoModePasswordChange'));
        }

        if (oldPass.length === 0 || newPassRepeat.length === 0 || newPassRepeat.length === 0) {
            this.toastrService.error(this.translate.instant('screens.profile.fillAllFields'));
        }

        this.registration.updatePassword(oldPass, newPass, newPassRepeat).subscribe(
            () => {
                this.toastrService.success(this.translate.instant('screens.profile.passwordChangeSuccess'));
                this.trackPasswordChangeSuccessful();
            },
            (error: any) => {
                if (!('error' in error)) {
                    return;
                }
                if (!('error' in error.error)) {
                    return;
                }

                this.trackPasswordChangeErrorEvent(error);
                if (error.error.error.code === 264) {
                    const msg = error.error.error.message;
                    if (msg.includes('MAX length')) {
                        this.toastrService.error(
                            this.translate.instant('common.errors.passwordTooLong'),
                            this.translate.instant('common.errors.invalidPassword'),
                            {timeOut: 6000}
                        );
                    } else if (msg.includes('Special characters')) {
                        this.toastrService.error(this.translate.instant('screens.profile.invalidSpecialChars'),
                            this.translate.instant('common.errors.invalidPassword'),
                            {timeOut: 6000}
                        );
                    }
                }
            }
        );
    }

    private triggerMfaEnablePipeline(): void {
        const mfaCodeConfig = this.popoverConfigService.getMFACodePopoverConfig();
        this.mfaService.enableMFA().pipe(
            mergeMap(secretCode => {
                mfaCodeConfig.data.setupCode = secretCode;
                return of(mfaCodeConfig);
            }),
            mergeMap(config =>
                this.popover.open(config).afterClosed$.pipe(
                    mergeMap(event => of(event.data))
                )),
            mergeMap((result: any) => {
                    const config = this.popoverConfigService.getMFACodeEntryPopoverConfig();
                    return result ? this.popover.open(config).afterClosed$ : throwError(null);
                }
            )
        ).subscribe((result => {
            if (result) {
                this.requestMFAStatus();
            }
        }));
    }

    private triggerMfaDisablePipeline(): void {
        const config = this.popoverConfigService.getDisableMFAConfirmationPopoverConfig();
        this.popover.open(config).afterClosed$.pipe(
            mergeMap((closedEvent: any) => of(closedEvent.data)),
            mergeMap((result: boolean) =>
                iif(() => result === true,
                    this.mfaService.disableMFA().pipe(
                        mergeMap(response =>
                            of({success: true, data: response, reason: null})),
                        catchError(error =>
                            of({success: false, data: null, reason: 'api-error'}))
                    ),
                    of({success: false, data: null, reason: 'user-abort'})
                ))
        ).subscribe((result: { success: boolean, data: any, reason: string }) => {
            if (!result.success) {
                if (result.reason === 'api-error') {
                    this.toastrService.error(this.translate.instant('screens.profile.mfaDisableError'), this.translate.instant('common.errors.error'));
                }
            } else {
                this.requestMFAStatus();
            }
        });
    }

    private processContractData(data): void {
        if (this.application.isDemoMode()) {
            this.contract.name = 'Maxi Mustermann';
        } else {
            this.contract.name = data.profile.customer_name;
        }
        this.contract.tarif = data.profile.product_name;
    }

    private processProfileData(data): void {
        let basicprice = 0;
        if (typeof data.profile.e_fixed_tariff !== 'undefined') {
            basicprice = parseFloat(data.profile.e_fixed_tariff);
        } else {
            basicprice = parseFloat(data.profile.budget_bill);
        }

        // Strompreise auslesen
        const workingprice = parseFloat(data.profile.e_tariff);
        this.contract.basicprice = basicprice.toLocaleString('de-DE',
            {
                style: 'currency',
                currency: 'EUR'
            }
        );
        const tempWorkingPrice = (workingprice * 100).toFixed(2).replace('.', ',');
        this.contract.workingprice = `${tempWorkingPrice} ct/kWh`;
    }

    private requestMFAStatus(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        this.mfaService.getMFAStatus()
            .subscribe((mfaStatus) => this.mfaEnabled = mfaStatus);
    }

    private trackEmailChangeErrorEvent(error: any): void {
        this.gtm.trackEvent({
            event: 'error',
            eventCategory: 'monitoring',
            eventAction: 'error',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit email address',
            errorMessage: error.error.message,
            errorReason: error.error.code,
        });
    }

    private trackEmailChangeSuccessful(): void {
        this.gtm.trackEvent({
            event: 'submit',
            eventCategory: 'conversion',
            eventAction: 'submit',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit email address'
        });
    }

    private trackPasswordChangeErrorEvent(error: any): void {
        this.gtm.trackEvent({
            event: 'error',
            eventCategory: 'monitoring',
            eventAction: 'error',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit password',
            errorMessage: error.error.error.message,
            errorReason: error.error.error.code,
        });
    }

    private trackPasswordChangeSuccessful(): void {
        this.gtm.trackEvent({
            event: 'submit',
            eventCategory: 'conversion',
            eventAction: 'submit',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit password'
        });
    }
}
