<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Zählerstand; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <!-- ABOLUTE TILE CLOSE BUTTON-->
        <div class="tile-close-control">
            <span *ngIf="showMeterReadingsBadge" class="meter-readings-badge"></span>
        </div>

        <div class="tile-inner">
            <h3 class="tile-heading ">{{TEXTS.TITLE}}</h3>
            <div class="tile-content col">
                <ng-container *ngIf="meterData$ | async as meterData">
                    <ng-container [ngSwitch]="meterData.viewState">
                        <ng-container *ngSwitchCase="ViewState.ERROR">
                            <div class="grow-space center-contents">
                                {{TEXTS.ERROR}}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.LOADING">
                            <div class="grow-space center-contents">
                                {{TEXTS.LOADING}}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.SUCCESS">
                            <div class="meter-data">
                                <div class="meter-value center-contents eon-bold">
                                    <div>
                                        {{ meterData.meterValues.offtake | paddedMeterValue }}
                                        {{TEXTS.UNIT}}
                                    </div>
                                </div>

                                <div *ngIf="meterData.meterStats.meterSerialNumber"
                                     class="col-centered meter-info">
                                    <div class="label eon-bold">
                                        {{TEXTS.LABEL_METER_ID}}
                                    </div>
                                    <div class="meter-id eon-regular">
                                        {{meterData.meterStats.meterSerialNumber}}
                                    </div>
                                </div>
                            </div>

                            <div class="meter-connection">
                                <div class="connection-container">
                                    <div class="p-r-s">
                                        <ng-container
                                            *ngIf="isMeterConnected(meterData.meterStats.connectionStatus); else disonnected">
                                            <i class="wifi-sm turquoise"></i>
                                        </ng-container>
                                        <ng-template #disonnected>
                                            <i class="alert-sm bordeaux"></i>
                                        </ng-template>
                                    </div>
                                    <h4 class="connection-text eon-regular"
                                        [class.connected]="isMeterConnected(meterData.meterStats.connectionStatus)"
                                        [class.disconnected]="!isMeterConnected(meterData.meterStats.connectionStatus)">
                                        {{determineMeterStatusMessage(meterData.meterStats.connectionStatus)}}
                                    </h4>
                                </div>
                            </div>

                        </ng-container>
                    </ng-container>
                </ng-container>


            </div>
        </div>
    </div>
</div>
