<div class="overlay-small">
    <div class="overlay-container">
        <h1>{{ 'popovers.mfaLoginPopover.twoFactorTitle' | translate }}</h1>
        <p>{{ 'popovers.mfaLoginPopover.twoFactorDescription' | translate }}</p>
        <form [formGroup]="form" (ngSubmit)="submitCode()">
            <div>
                <fieldset class="eon-input">
                    <legend>{{ 'common.password' | translate }}</legend>
                    <input #inputField id="code-input" class="eon-input" type="tel"
                           pattern="^[0-9]*$"
                           (keydown)="onKeyDown($event)"
                           (keyup)="onKeyUp($event)"
                           placeholder="{{ 'popovers.mfaLoginPopover.codePlaceholder' | translate }}"
                           formControlName="code">
                    <label for="code-input"></label>
                </fieldset>
                <div class="loading-indicator"
                     [class.hidden]="!isLoading">
                    <ng-lottie class="loading-spinner" height="35px"
                               [options]="loadingAnimation"></ng-lottie>
                </div>
            </div>
            <button class="eon-button-new"
                    type="submit"
                    [disabled]="submitDisabled">
                {{ 'popovers.mfaLoginPopover.submitButtonText' | translate }}
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
