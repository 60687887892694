import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-nilm-device-popover',
    templateUrl: './nilm-device-popover.component.html',
    styleUrls: ['./nilm-device-popover.component.scss']
})
export class NilmDevicePopoverComponent implements OnInit {

    amount = 0;
    icon = '';
    appliance = '';
    appliancePlural = '';

    @ViewChild('applianceIcon', {static: true})
    applianceImageElement: ElementRef<HTMLDivElement>;

    constructor(public popoverRef: PopoverRef,
                private renderer: Renderer2,
                private translate: TranslateService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.determineApplianceData(this.appliance);
        this.setCurrentApplianceImage();
    }


    close(value = null): void {
        this.popoverRef.close(value);
    }


    raiseAmount(): void {
        this.amount++;
    }


    lowerAmount(): void {
        if (this.amount > 0) {
            this.amount--;
        }
    }


    confirm(): void {
        const result = {
            amount: this.amount,
            appliance: this.appliance,
            applianceId: this.icon
        };
        this.close(result);
    }


    private setCurrentApplianceImage(): void {
        this.renderer.setStyle(
            this.applianceImageElement.nativeElement,
            'mask-image',
            this.getApplianceImagePath()
        );
        this.renderer.setStyle(
            this.applianceImageElement.nativeElement,
            '-webkit-mask-image',
            this.getApplianceImagePath()
        );
    }


    private getApplianceImagePath(): string {
        return `url("/assets/img/icons/household/svg/${this.icon}.svg")`;
    }


    public determineApplianceData(name: string): void {
        switch (name) {
            case 'dishWasher':
                this.appliance = this.translate.instant('screens.household.appliances.dishwasher');
                this.appliancePlural = this.translate.instant('screens.household.appliances.dishwasher');
                this.icon = 'A.10';
                break;
            case 'washingMachine':
                this.appliance = this.translate.instant('screens.household.appliances.washingMachine');
                this.appliancePlural = this.translate.instant('screens.household.appliances.washingMachinePlural');
                this.icon = 'A.11';
                break;
            case 'dryer':
                this.appliance = this.translate.instant('screens.household.appliances.dryer');
                this.appliancePlural = this.translate.instant('screens.household.appliances.dryer');
                this.icon = 'A.12';
                break;
            case 'oven':
                this.appliance = this.translate.instant('screens.household.appliances.oven');
                this.appliancePlural = this.translate.instant('screens.household.appliances.ovenPlural');
                this.icon = 'A.04';
                break;
        }
    }


    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            if (this.popoverRef.data.nullableBackdrop) {
                this.close(null);
            } else {
                this.close(false);
            }
        });

        if (this.popoverRef.data) {
            console.log('POPOVER DATA', this.popoverRef.data);
            this.amount = this.popoverRef.data.amount;
            this.appliance = this.popoverRef.data.appliance;
        }
    }

}
