import {Component, OnDestroy, OnInit} from '@angular/core';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/household-comparison-data-provider.service';
import {translateApplianceLowercase} from '../../../tiles/appliances/appliances.utils';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-household-comparison-appliances',
    templateUrl: './household-comparison-appliances.component.html',
    styleUrls: ['./household-comparison-appliances.component.scss']
})
export class HouseholdComparisonAppliancesComponent implements OnInit, OnDestroy {

    LABELS;

    protected readonly ViewState = ViewState;
    protected readonly translateApplianceLowercase;

    householdDeviceCategoriesData$ =
        this.householdComparisonDataProvider.deviceCategoriesData$;


    constructor(
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService,
        private translate: TranslateService
    ) {
        this.translateApplianceLowercase = translateApplianceLowercase(this.translate);
    }


    ngOnInit(): void {
        this.householdComparisonDataProvider.getDeviceCategoriesData();
        this.householdComparisonDataProvider.setDeviceCategoriesModeIsActive(true);
        this.translate.get('screens.dashboard.householdComparison.detailLabels').subscribe(labels => {
            this.LABELS = labels;
        });
    }


    ngOnDestroy(): void {
        this.householdComparisonDataProvider.setDeviceCategoriesModeIsActive(false);
    }


    /**
     * Returns the url for the appliance icon.
     * @param name
     */
    getApplianceIconUrl(name: string): string {
        if (name === 'vehicle') {
            name = 'electricvehicle';
        }
        return `url("assets/img/graphics/appliances/${name}.svg")`;
    }

}
