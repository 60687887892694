<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">{{ 'screens.dashboard.appliances.heading' | translate }}</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Meine Geräte"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <ng-container [ngSwitch]="currentDisplayMode">
                        <ng-container *ngSwitchCase="DisplayMode.DATA">
                            <h2 class="font-bold">{{ 'screens.dashboard.appliances.info.title' | translate }}</h2>
                            <p *ngFor="let paragraph of TEXTS.info.paragraphs">
                                {{ paragraph }}
                            </p>
                        </ng-container>
                        <ng-container *ngSwitchCase="DisplayMode.RETRAINING">
                            <h2 class="font-bold">{{ 'screens.dashboard.appliances.infoAlt.title' | translate }}</h2>
                            <p *ngFor="let paragraph of TEXTS.infoAlt.paragraphs">
                                {{ paragraph }}
                            </p>
                        </ng-container>
                    </ng-container>
                </div>
            </section>

            <!-- BANNER -->
            <ng-container *ngIf="detailBanner$ | async as detailBannerVisibility">
                <app-detail-banner
                    *ngIf="detailBannerVisibility.showProfileUpdateBanner && profileUpdateBannerVisible"
                    [bannerData]="profileUpdateBanner"
                    (hideBanner)="hideProfileUpdateBanner()"
                    (bannerInteraction)="onProfileUpdateBannerInteraction()">
                </app-detail-banner>
                <app-detail-banner
                    *ngIf="detailBannerVisibility.showRetrainingBanner"
                    [bannerData]="retrainingBanner"
                    (sideButtonInteraction)="showRetrainingView()">
                </app-detail-banner>
            </ng-container>

            <!-- TAB BAR -->
            <div class="tab-bar">
                <div [class.active]="currentMode === DataMode.MONTH && currentDisplayMode === DisplayMode.DATA"
                     (click)="setMode(DataMode.MONTH)">
                    {{ 'screens.dashboard.appliances.tab.overviewMonths' | translate }}
                </div>
                <div [class.active]="currentMode === DataMode.YEAR && currentDisplayMode === DisplayMode.DATA"
                     (click)="setMode(DataMode.YEAR)">
                    {{ 'screens.dashboard.appliances.tab.overviewYears' | translate }}
                </div>
                <div class="grow-space"></div>
                <ng-container *ngIf="currentNilmPhaseAllowsRetraining$ | async as retrainingAllowed">
                    <ng-container *ngIf="retrainingAllowed">
                        <div [class.active]="currentDisplayMode === DisplayMode.RETRAINING"
                             (click)="setDisplayMode(DisplayMode.RETRAINING)">
                            {{ 'screens.dashboard.appliances.tab.retraining' | translate }}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <section class="detail-content">

                <ng-template
                    [cdkPortalOutlet]="selectedPortal"
                    (attached)="onComponentAttachedToPortal($event)">
                </ng-template>

            </section>

        </div>
    </div>
</div>
