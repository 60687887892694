<div class="view-container">
    <h2 class="font-bold">
        {{ TEXTS.filter.title }}
    </h2>
    <div>
        <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
            <div class="filter-list scroll-container-vertical household-comparison">

                <fieldset>
                    <legend class="font-black"> {{ LABELS.filterHouseholdSectionTitle }} </legend>

                    <label for="occupants" class="eon-checkbox" [class.disabled]="fieldsDisabled.occupants">
                        <input id="occupants" type="checkbox" [formControl]="filterForm.controls.occupants">
                        {{ LABELS.filterHouseholdOccupants }}
                    </label>
                    <label for="space-heating" class="eon-checkbox" [class.disabled]="fieldsDisabled.spaceHeating">
                        <input id="space-heating" type="checkbox" [formControl]="filterForm.controls.spaceHeating">
                        {{ LABELS.filterHouseholdSpaceHeating }}
                    </label>
                    <label for="water-heating" class="eon-checkbox" [class.disabled]="fieldsDisabled.waterHeating">
                        <input id="water-heating" type="checkbox" [formControl]="filterForm.controls.waterHeating">
                        {{ LABELS.filterHouseholdWaterHeating }}
                    </label>
                    <label for="property" class="eon-checkbox" [class.disabled]="fieldsDisabled.property">
                        <input id="property" type="checkbox" [formControl]="filterForm.controls.property">
                        {{ LABELS.filterHouseholdProperty }}
                    </label>
                </fieldset>

                <fieldset>
                    <legend class="font-black"> {{ LABELS.filterApplianceSectionTitle }} </legend>

                    <label for="ev-charger" class="eon-checkbox" [class.disabled]="fieldsDisabled.evCharger">
                        <input id="ev-charger" type="checkbox" [formControl]="filterForm.controls.evCharger">
                        {{ LABELS.filterApplianceEvCharger }}
                    </label>

                    <label for="pool" class="eon-checkbox" [class.disabled]="fieldsDisabled.pool">
                        <input id="pool" type="checkbox" [formControl]="filterForm.controls.pool">
                        {{ LABELS.filterAppliancePool }}
                    </label>
                    <label for="sauna" class="eon-checkbox" [class.disabled]="fieldsDisabled.sauna">
                        <input id="sauna" type="checkbox" [formControl]="filterForm.controls.sauna">
                        {{ LABELS.filterApplianceSauna }}
                    </label>
                </fieldset>
            </div>

            <div class="button-wrap">
                <button class="eon-button-new turquoise" type="submit">
                    {{ LABELS.filterButtonApply }}
                </button>
            </div>
            <p class="info small centered m-t-m m-b-n" *ngIf="showFilterInfo()">
                {{ TEXTS.filterInfo }}
            </p>
        </form>
    </div>
</div>
