import {DetailBannerData} from '../../interfaces/detail-banner.interfaces';

export const financeProfileUpdateBanner: DetailBannerData = {
    paragraphs: [
        {
            type: 'text',
            text: '',
            buttonText: '',
            buttonId: ''
        },
    ],
    sideButton: {
        visible: true,
        isClose: true
    }
};
