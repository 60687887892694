import {Component, OnInit} from '@angular/core';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Globals} from '../../../services/globals.service';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/household-comparison-data-provider.service';
import {share} from 'rxjs';
import {ViewState} from '../../../shared/enums/view-state.enum';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-household-comparison-tile',
    templateUrl: './household-comparison-tile.component.html',
    styleUrls: ['./household-comparison-tile.component.scss'],
    providers: [Globals]
})
export class HouseholdComparisonTileComponent implements OnInit {


    constructor(
        private globals: Globals,
        private tiles: TileService,
        private analytics: TrackAnalyticsService,
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService,
        private translate: TranslateService
    ) {
    }
    private readonly type: TILE_TYPE = TILE_TYPE.HOUSEHOLD_COMPARISON;

    LABELS;

    householdComparisonData$ =
        this.householdComparisonDataProvider.combinedClusterRankingData$.pipe(
            share(),
        );


    protected readonly ViewState = ViewState;


    ngOnInit(): void {
        this.householdComparisonDataProvider.getCombinedClusterRankingData();
        this.translate.get('screens.dashboard.householdComparison.comparisonsLabel').subscribe(labels => {
            this.LABELS = labels;
        });
    }


    /**
     * Handles on tile clicked Event
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * Handles tracking events on opening detail
     */
    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    /*
     * ANALYTICS
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Household-Comparison',
            }
        });
    }


    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Household-Comparison-Details'
            }
        });
    }
}
