import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {constants} from '../shared/constants/constants';
import * as moment from 'moment';
import {catchError, map} from 'rxjs/operators';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class ConsumptionService extends BaseService {

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }


    /**
     * Requests the consumption by appliance on a monthly basis
     * @param monthFrom - from date
     * @param monthTo - to date
     */
    getConsumptionForMonthsPerAppliance(monthFrom: Date, monthTo: Date): Observable<any> {
        const d1 = moment(monthFrom).format('YYYY-MM');
        const d2 = moment(monthTo).format('YYYY-MM');
        let url = this.API_BASE_URL +
            constants.api.routes.consumption.electricity.appliances.months;
        url = `${url}/${d1}/${d2}`;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.appliances}.json`;
        }
        return this.http.get( url ).pipe(
            map(res => this.mapDefault(res)),
            catchError(error => this.handleError(error))
        );
    }


    /**
     * Requests the overall consumption for a certain timeframe.
     * Consumption is returned summed by month.
     * @param from - from date
     * @param to - from date
     */
    getConsumptionForMonths(from: Date, to: Date): Observable<MonthlyConsumptionData[]> {
        const dFrom = moment(from).format('YYYY-MM');
        const dTo = moment(to).format('YYYY-MM');

        let url = this.API_BASE_URL + constants.api.routes.consumption.electricity.months;
        url = `${url}/${dFrom}/${dTo}`;

        return this.http.get(url).pipe(
            map(res => this.mapDefault(res)),
            catchError(error => this.handleError(error))
        );
    }
}


export interface MonthlyConsumptionData {
    calculated: number;
    compare: number;
    cost_calculated: number;
    cost_compare: number;
    cost_measured: number;
    fixed_cost: number;
    measured: number;
    temperature: number;
    timestamp: string;
}
