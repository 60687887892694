import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../../services/language.service';

@Component({
    selector: 'app-language-modal',
    templateUrl: './language-modal.component.html',
    styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent  {
    languages = [
        { key: 'de', displayTextKey: 'screens.settings.language.languages.de' },
        { key: 'en', displayTextKey: 'screens.settings.language.languages.en' },
        { key: 'fr', displayTextKey: 'screens.settings.language.languages.fr' }
    ];
    selectedLanguage: string;

    constructor(
        public dialogRef: MatDialogRef<LanguageModalComponent>,
        private languageService: LanguageService,
    ) {
        this.selectedLanguage = this.languageService.getLanguage();
    }

    onClose(): void {
        this.dialogRef.close();
    }

    onSelect(): void {
        console.log('Selected language:', this.selectedLanguage);
    }

    onSave(): void {
        console.log('Saving language:', this.selectedLanguage);
        this.languageService.setLanguage(this.selectedLanguage);
        this.dialogRef.close(this.selectedLanguage);
    }
}
