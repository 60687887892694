import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {padNumber} from '../../shared/utility';


@Component({
    selector: 'app-meter-value-display',
    templateUrl: './meter-value-display.component.html',
    styleUrls: ['./meter-value-display.component.scss']
})
export class MeterValueDisplayComponent implements OnInit {

    @Input() value = 12345;
    @Input() icon = 'lightning';
    // tslint:disable-next-line:max-line-length
    @Input() title = this.translate.instant('screens.dashboard.meterDetail.labels.VALUE_CONSUMPTION');
    @Input() unit = 'kWh';
    @Input() forHeader = false;

    padNumberInternal = padNumber;


    constructor(private translate: TranslateService) {
    }


    ngOnInit(): void {
    }

}
