<div class="overlay-minimal">
    <div class="overlay-container">
        <h1 class="eon-bold">{{ 'popovers.update.title' | translate }}</h1>
        <p class="eon-regular">
            {{ 'popovers.update.description' | translate }}
        </p>
        <div class="center-contents">
            <div class="eon-logo-animated"></div>
        </div>
        <div class="center-contents eon-regular">
            {{ 'popovers.update.progress' | translate: { currentProgress: currentProgress } }}
        </div>
        <div *ngIf="env.isStaging" class="center-contents m-t-x">
            <button class="eon-button-new"
                    (click)="onLogoutButtonClick()">
                {{ 'common.logout' | translate }}
            </button>
        </div>
    </div>
</div>
