<div id="container">
    <!-- Menü -->
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="{{ 'common.menu' | translate }}" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text">{{ 'common.menu' | translate }}</span>
        </a>
        <app-menu activeElement="dashboard"></app-menu>
    </aside>

    <!-- Dashboard Content -->
    <div id="inner">
        <!-- BANNER -->
        <app-banner #banner></app-banner>

        <!-- DASHBOARD MENU -->
        <header class="row-align-right">
            <div class="battery-status">
                <ng-container *ngIf="userService.isEDGUser()">
                    <div class="eon-bold">{{ 'screens.dashboard.batteryStatus' | translate }}</div>
                    <app-battery-load [currentBatteryState]="currentORBatteryState"
                                      [inSettings]="false"
                                      [isAvailable]="currentORBatteryState > -1">
                    </app-battery-load>
                </ng-container>
            </div>
            <div class="center-contents pointer"
                 (click)="onSortButtonClick()"
                 angulartics2On="click" angularticsCategory="Dashboard" angularticsAction="edit_mode_start">
                <button class="eon-icon-button small reversed">
                    <i class="filter-s white"></i>
                </button>
                <span class="m-l-s eon-bold">{{ 'screens.dashboard.sort' | translate }}</span>
            </div>
            <div class="center-contents pointer"
                 (click)="onAddTileButtonClicked()">
                <button class="eon-icon-button small reversed">
                    <i class="plus-s white"></i>
                </button>
                <span class="m-l-s eon-bold">{{ 'screens.dashboard.addTile' | translate }}</span>
            </div>
        </header>

        <!-- DASHBOARD -->
        <div class="tile-view">
            <ng-container *ngFor="let tile of dashboardTiles" class="">
                <ng-container [ngSwitch]="tile.type">
                    <ng-container *ngSwitchCase="TILE_TYPE.LIVE">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-live-tile></app-live-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.TODAY">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-today-tile></app-today-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.APPLIANCES">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-appliances-tile *ngIf="tile.selected"></app-appliances-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.COMPARISON">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-comparison-tile *ngIf="tile.selected"></app-comparison-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.METER">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-meter-tile *ngIf="tile.selected"></app-meter-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.FINANCE">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-finance-tile *ngIf="tile.selected"></app-finance-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="userHasPlug">
                        <ng-container *ngSwitchCase="TILE_TYPE.POWER_CHECKER">
                            <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                                <app-powerchecker-tile *ngIf="tile.selected"></app-powerchecker-tile>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.CONSUMPTION_ALERT">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-consumption-alert-tile *ngIf="tile.selected"></app-consumption-alert-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="mvpTileAvailable">
                        <ng-container *ngSwitchCase="TILE_TYPE.MVP">
                            <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                                <app-mvp-tile *ngIf="tile.selected" [config]="tile.mvpConfig"></app-mvp-tile>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.HOUSEHOLD_COMPARISON">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-household-comparison-tile></app-household-comparison-tile>
                        </div>
                    </ng-container>

                </ng-container>
            </ng-container>

            <div class="tile-wrapper" appMatchHeight *ngIf="tileService.tilesAvailable()">
                <div class="tile-container">
                    <div class="tile tile-add" (click)="onAddTileButtonClicked()">
                        <div class="tile-inner center-content">
                            <div class="inflate center-contents">
                                <i class="plus-sm dark-grey"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
