import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {iif, Observable, of, Subject} from 'rxjs';
import {RegistrationService} from './registration.service';
import {Popover} from '../popovers/popover/popover.service';
import {mergeMap} from 'rxjs/operators';
import {ApplicationService} from './application.service';
import {OptInPopoverComponent} from '../popovers/opt-in-popover/opt-in-popover.component';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';

@Injectable({
    providedIn: 'root'
})
export class LocalOptInService {

    onDialogSettingsChange = new Subject<boolean>();


    constructor(
        private registrationService: RegistrationService,
        private popover: Popover,
        private apiService: ApiService,
        private application: ApplicationService
    ) {
    }


    checkStatus(fromDashboard = false): void {
        if (this.application.isDemoMode()) {
            return;
        }
        const value = localStorage.getItem(StorageAttributes.OPT_IN_REQUIRED);
        if (value === null) {
            return;
        }
        const parsedValue = parseInt(value, 10) === 1;
        if (!parsedValue) {
            this.showOptInPopover(fromDashboard);
        }
    }


    triggerOptInPopover(showFeatures = false): Observable<boolean> {
        return this.popover.open({
            content: OptInPopoverComponent,
            hasBackdrop: true,
            data: {showFeatures}
        }).afterClosed$.pipe(
            mergeMap(result => {
                const value = result.data as unknown as boolean;
                return of(value);
            }),
        );
    }


    private showOptInPopover(showFeatures = false): void {
        this.triggerOptInPopover(showFeatures).pipe(
            mergeMap((result: boolean) =>
                iif(
                    () => result,
                    this.registrationService.optInDevice(result),
                    of(false)
                ))
        ).subscribe(result => {
            if (!result) {
                this.onDialogSettingsChange.next(false);
                this.exitApplication();
            } else {
                this.onDialogSettingsChange.next(true);
            }
            localStorage.removeItem(StorageAttributes.OPT_IN_REQUIRED);
        });
    }


    private exitApplication(): void {
        this.apiService.logoutUser();
    }
}
