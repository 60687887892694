import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, share} from 'rxjs';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/household-comparison-data-provider.service';
import {ProfileService} from '../../../services/profile.service';
import {map, mergeMap} from 'rxjs/operators';
import {HouseholdAttributeIdentifierMapping} from '../../../lib/household.mapping';
import {HouseholdProfile} from '../../../shared/interfaces/profile-attributes.interfaces';
import {BenchmarkFilterPayloadProps} from '../../../shared/interfaces/benchmark.interfaces';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {ApplicationService} from '../../../services/application.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-household-comparison-filter-display',
    templateUrl: './household-comparison-filter-display.component.html',
    styleUrls: ['./household-comparison-filter-display.component.scss']
})
export class HouseholdComparisonFilterDisplayComponent implements OnInit {
    protected readonly ViewState = ViewState;
    LABELS;
    TEXTS;

    enterFormMode = new BehaviorSubject<boolean>(null);

    householdComparisonData$ =
        this.householdComparisonDataProvider.combinedClusterRankingData$.pipe(
            share()
        );

    currentFilter$: Observable<Array<HouseholdComparisonFilterDisplayItem>> =
        this.householdComparisonDataProvider.filterChanged$.pipe(
            mergeMap(props => {
                return this.profileService.getAttributesWithCache().pipe(
                    map((profile) => ({props, profile}))
                );
            }),
            map(({props, profile}) =>
                this.createFilterDisplayItem(props, profile)
            )
        );

    private mapping: Array<HouseholdComparisonFilterDisplayItem> = [
        {
            propKey: 'occupants',
            profileCategory: 'Attributes',
            profileKey: 'Occupants',
            title: this.translate.instant('screens.dashboard.householdComparison.detailLabels.filterHouseholdOccupants'),
            wording: 'Person'
        },
        {
            propKey: 'space_heating',
            profileCategory: 'Attributes',
            profileKey: 'SpaceHeating',
            title:  this.translate.instant('screens.dashboard.householdComparison.detailLabels.filterHouseholdSpaceHeating'),
            wording: 'Heizung',
        },
        {
            propKey: 'water_heating',
            profileCategory: 'Attributes',
            profileKey: 'WaterHeating',
            title: this.translate.instant('screens.dashboard.householdComparison.detailLabels.filterHouseholdWaterHeating'),
            wording: 'Warmwasser',
        },
        {
            propKey: 'property_type',
            profileCategory: 'Attributes',
            profileKey: 'PropertyType',
            title: this.translate.instant('screens.dashboard.householdComparison.detailLabels.filterHouseholdProperty'),
            wording: 'Gebäudetyp',
        },
        {
            propKey: 'ev_charger',
            profileCategory: 'Appliances',
            profileKey: 'A.21',
            title: this.translate.instant('screens.dashboard.householdComparison.detailLabels.filterApplianceEvCharger'),
            wording: 'Elektroauto',
        },
        {
            propKey: 'swimming_pool',
            profileCategory: 'Appliances',
            profileKey: 'A.22',
            title: this.translate.instant('screens.dashboard.householdComparison.detailLabels.filterAppliancePool'),
            wording: 'Pool',
        },
        {
            propKey: 'sauna',
            profileCategory: 'Appliances',
            profileKey: 'A.23',
            title: this.translate.instant('screens.dashboard.householdComparison.detailLabels.filterApplianceSauna'),
            wording: 'Sauna',
        }
    ];


    constructor(
        private profileService: ProfileService,
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService,
        public application: ApplicationService,
        private translate: TranslateService
    ) {
    }


    ngOnInit(): void {
        this.translate.get('screens.dashboard.householdComparison.detailTexts').subscribe(texts => {
            this.TEXTS = texts;
        });

        this.translate.get('screens.dashboard.householdComparison.detailLabels').subscribe(labels => {
            this.LABELS = labels;
        });
    }


    onEnterFormMode(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        this.enterFormMode.next(true);
    }


    determineFilterIcon(propKey: string): string {
        return `url("/assets/img/icons/household/active/${propKey}.png")`;
    }


    /**
     * Creates the filter display items for the current filter.
     * @param props
     * @param profile
     * @private
     */
    private createFilterDisplayItem(
        props: BenchmarkFilterPayloadProps,
        profile: HouseholdProfile
    ): Array<HouseholdComparisonFilterDisplayItem> {
        const positiveFilteredKeys = Object.keys(props).filter(key =>
            props[key] !== undefined
        );
        const copiedMapping = JSON.parse(JSON.stringify(this.mapping));
        return positiveFilteredKeys.map(key => {
            const mapping = copiedMapping.find(el => el.propKey === key);
            const value = profile[mapping.profileCategory][mapping.profileKey];
            mapping.value = value;
            const mappingType = typeof value === 'string' ? 'string' : 'number';
            if (mappingType === 'string') {
                const translationKey = HouseholdAttributeIdentifierMapping
                    .find(el => el.key === value).value;
                mapping.wording = this.translate.instant(translationKey);
                mapping.profileKey = value;
            } else if (mappingType === 'number') {
                mapping.wording = `Anzahl: ${value}`;
            }
            return mapping;
        });
    }

}


interface HouseholdComparisonFilterDisplayItem {
    propKey: string;
    profileCategory: string;
    profileKey: string;
    wording: string;
    title: string;
    value?: any;
    type?: 'numerical' | 'categorical';
}
