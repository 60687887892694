<div class="overlay">
    <div class="overlay-container-cdk">
        <div class="header">
            <div class="grow-space">
                <h2 class="m-a-n">{{ 'popovers.tariffDataEntry.newTariff' | translate }}</h2>
            </div>
            <button type="button" class="icn-btn-close"
                    (click)="close(null)"></button>
        </div>

        <div class="content">
            <div class="tariff-creation">
                <form (ngSubmit)="submit()" (change)="onFormChange()">
                    <div>
                        <input type="text" class="text-input"
                               [placeholder]="'popovers.tariffDataEntry.namePlaceholder' | translate"
                               name="name" [(ngModel)]="data.name">
                    </div>
                    <div>
                        <input #dateInput type="text" class="text-input"
                               [placeholder]="'popovers.tariffDataEntry.startPlaceholder' | translate"
                               name="start" [min]="minDate" [max]="maxDate"
                               (focus)="dateInput.type = 'date'"
                               [(ngModel)]="data.dateStart">
                    </div>
                    <div>
                        <input type="number" class="text-input"
                               [placeholder]="'popovers.tariffDataEntry.basePricePlaceholder' | translate"
                               name="baseprice" [(ngModel)]="data.basePrice">
                    </div>
                    <div>
                        <input type="number" class="text-input"
                               [placeholder]="'popovers.tariffDataEntry.workPricePlaceholder' | translate"
                               name="workprice" [(ngModel)]="data.workPrice">
                    </div>
                    <div>
                        <button type="submit" class="btn-scale"
                                [disabled]="confirmDisabled">
                            {{ 'popovers.tariffDataEntry.saveButton' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
