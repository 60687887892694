<div class="overlay">
    <div class="overlay-container-cdk">
        <div class="col changelogs">
            <div *ngFor="let logs of changelog.versions">
                <h4>{{ logs.version }}</h4>
                <ul *ngIf="logs.features.length > 0">
                    <li class="head">{{ 'screens.dashboard.changelog.newFeatures' | translate }}</li>
                    <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
                </ul>
                <ul *ngIf="logs.adjustments.length > 0">
                    <li class="head">{{ 'screens.dashboard.changelog.adjustments' | translate }}</li>
                    <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
                </ul>
                <ul *ngIf="logs.bugfixes.length > 0">
                    <li class="head">{{ 'screens.dashboard.changelog.bugfixes' | translate }}</li>
                    <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
