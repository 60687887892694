<div class="overlay-small">
    <div class="overlay-container">
        <h1>{{ 'screens.profile.twoFactorAuthTitle' | translate }}</h1>
        <p class="eon-regular">
            {{ 'screens.profile.twoFactorAuthDescription1' | translate }}
            <strong>{{ 'common.code' | translate }}</strong>:
        </p>
        <p class="secret-code" (click)="copyCode()">
            {{ setupCode }}
            <i></i>
        </p>
        <div>
            <button class="eon-button-new"
                    (click)="close(true)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
