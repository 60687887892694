import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {ApplicationService} from '../application.service';
import {DisaggregationService} from '../disaggregation.service';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {ConsumptionService} from '../consumption.service';
import * as moment from 'moment/moment';
import {NilmService} from '../nilm.service';
import {ViewState} from '../../shared/enums/view-state.enum';
import {DataProviderBaseService} from './data-provider-base.service';
import {AppliancesDetailDataMode} from '../../shared/enums/appliances-detail-data-mode.enum';
import {
    AppliancesDetailData, AppliancesDetailListElement,
    initialAppliancesDetailData
} from '../../shared/interfaces/appliances-detail.interfaces';
import {ApplianceDiagramSeriesData} from '../../shared/interfaces/appliances-categories.interfaces';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class AppliancesDetailDataProviderService extends DataProviderBaseService {

    private readonly colors = ['#1ea2b1', '#56b9c5', '#616161', '#747475'];

    private currentOffset = 0;

    currentModeChange: BehaviorSubject<string> = new BehaviorSubject<string>(AppliancesDetailDataMode.MONTH);
    currentMode;
    private defaultApplianceCategories;

    private monthlyDataSub: Subscription | null = null;
    private yearlyDataSub: Subscription | null = null;


    appliancesDetailData$ =
        new BehaviorSubject<AppliancesDetailData>(initialAppliancesDetailData);


    constructor(
        private application: ApplicationService,
        private disaggregation: DisaggregationService,
        private consumptionService: ConsumptionService,
        private nilmService: NilmService,
        private translate: TranslateService
    ) {
        super();
        this.currentMode = AppliancesDetailDataMode.MONTH;
        this.initializeApplianceCategories();

    }


    destroy() {
        super.destroy();
        if (this.monthlyDataSub) {
            this.monthlyDataSub.unsubscribe();
            this.monthlyDataSub = null;
        }
        if (this.yearlyDataSub) {
            this.yearlyDataSub.unsubscribe();
            this.yearlyDataSub = null;
        }
    }


    initializeApplianceCategories() {
        const categories = [
            'other', 'alwaysOn', 'refrigeration', 'cooking',
            'entertainment', 'lighting', 'laundry', 'waterHeating',
            'spaceHeating', 'electricVehicle', 'poolOrSauna'
        ];

        this.defaultApplianceCategories = {};

        categories.forEach(category => {
            this.defaultApplianceCategories[category.charAt(0).toUpperCase() + category.slice(1)] = {
                text: this.translate.instant(`applianceCategories.${category}.text`),
                appliances: category === 'laundry' ? [
                    {name: 'DishWasher', icon: 'dish-washer'},
                    {name: 'WashingMachine', icon: 'washing-machine'},
                    {name: 'Dryer', icon: 'dryer'}
                ] : []
            };
        });
    }

    /**
     * Updates the current offset
     * @param offset
     */
    updateCurrentOffset(offset: number): void {
        this.currentOffset = offset;
    }


    /**
     * Updates the current data mode
     * @param mode
     * @param fetchData
     */
    setCurrentDataMode(mode: AppliancesDetailDataMode, fetchData = true): void {
        this.currentModeChange.next(mode);
        this.currentMode = mode;
        this.currentOffset = 0;
        if (fetchData) {
            this.getAppliancesDataForCurrentMode();
        }
    }


    /**
     * resets appliances data
     */
    resetApplianceData(): void {
        this.appliancesDetailData$.next(initialAppliancesDetailData);
    }


    /**
     * Requests the disaggregation data for the currently set mode
     */
    getAppliancesDataForCurrentMode(): void {
        switch (this.currentMode) {
            case AppliancesDetailDataMode.MONTH:
                this.requestElectricalApplianceForCurrentMonthOffset();
                break;
            case AppliancesDetailDataMode.YEAR:
                this.requestElectricalApplianceForCurrentYearOffset();
                break;
        }
    }


    hasAppliancesInRetraining(): Observable<boolean> {
        return of(this.nilmService.hasAppliancesInRetraining());
    }


    /**
     * Requests the disaggregation data for a month with the service wide defined offset
     * @private
     */
    private requestElectricalApplianceForCurrentMonthOffset(): void {
        if (this.yearlyDataSub) {
            this.yearlyDataSub.unsubscribe();
            this.yearlyDataSub = null;
        }
        if (this.monthlyDataSub) {
            this.monthlyDataSub.unsubscribe();
            this.monthlyDataSub = null;
        }
        this.appliancesDetailData$.next({
            ...initialAppliancesDetailData,
            viewState: ViewState.LOADING
        });

        this.monthlyDataSub = this.disaggregation.getDisaggregationHistoryByOffset(
            this.currentOffset
        ).pipe(
            mergeMap((dataset) =>
                this.alignApplianceData([dataset])
            ),
            mergeMap(aligned => {
                const date = moment().subtract(this.currentOffset, 'months').toDate();
                return this.consumptionService.getConsumptionForMonthsPerAppliance(date, date).pipe(
                    map(res => ({appliances: aligned, consumptions: res})),
                    catchError(err =>
                        of({appliances: aligned, consumptions: null})
                    )
                );
            }),
            mergeMap(data =>
                of(this.generateDiagramSeries(data.appliances, data.consumptions))
            ),
            map(data => {
                return {
                    series: {
                        series: data.series,
                        nilm: data.nilm
                    },
                    categories: data.applianceCategories,
                    viewState: ViewState.SUCCESS
                } as AppliancesDetailData;
            }),
            catchError(error => {
                console.log('Error requesting appliance data for currently selected month:', error);
                return of({
                    ...initialAppliancesDetailData,
                    viewState: ViewState.ERROR
                });
            })
        ).subscribe({
            next: (data) => {
                this.appliancesDetailData$.next(data);
            },
            error: (error) => {
                console.log('Error requesting appliance data for currently selected month:', error);
            },
        });
    }


    /**
     * Requests the disaggregation data for an entire year with the service wide defined offset
     */
    requestElectricalApplianceForCurrentYearOffset(): void {
        if (this.monthlyDataSub) {
            this.monthlyDataSub.unsubscribe();
            this.monthlyDataSub = null;
        }
        if (this.yearlyDataSub) {
            this.yearlyDataSub.unsubscribe();
            this.yearlyDataSub = null;
        }
        this.appliancesDetailData$.next({
            ...initialAppliancesDetailData,
            viewState: ViewState.LOADING
        });
        let months = Array.from(Array(12).keys()).map(i => i + 1);
        if (this.currentOffset === 0) {
            months = Array.from(Array(moment().month() + 1).keys()).map(i => i + 1);
        }
        this.yearlyDataSub = of(months).pipe(
            mergeMap(month => month),
            mergeMap(month =>
                this.disaggregation.getDisaggregationHistoryForMonth(
                    this.currentOffset,
                    month
                ).pipe(
                    catchError(err => of(null))
                )
            ),
            toArray(),
            mergeMap(rawDisaggregationData =>
                this.alignApplianceData(rawDisaggregationData)),
            mergeMap(disaggregationData =>
                this.requestAppliancesCategoryData(disaggregationData)),
            map(data =>
                this.generateDiagramSeries(data.appliances, data.consumptions)
            ),
            map(data => {
                return {
                    series: {
                        series: data.series,
                        nilm: data.nilm
                    },
                    categories: data.applianceCategories,
                    viewState: ViewState.SUCCESS
                } as AppliancesDetailData;
            }),
            catchError(error => {
                console.log('Error requesting appliance data for currently selected year:', error);
                return of({
                    ...initialAppliancesDetailData,
                    viewState: ViewState.ERROR
                });
            })
        ).subscribe({
            next: (data) => {
                this.appliancesDetailData$.next(data);
            }
        });
    }


    /**
     * Align appliances disaggregation data
     * @param rawData
     */
    private alignApplianceData(rawData: any[]): Observable<any> {
        return new Observable<any>((observer) => {
            try {
                const unifiedData = {};
                for (const monthlyData of rawData) {
                    if (!monthlyData) {
                        continue;
                    }
                    const electricity = monthlyData.electricity;
                    for (const appliance of Object.keys(electricity.used_budget.categories)) {
                        const usage = electricity.used_budget.categories[appliance].usage ?
                            electricity.used_budget.categories[appliance].usage :
                            0;
                        const cost = electricity.used_budget.categories[appliance].cost ?
                            electricity.used_budget.categories[appliance].cost :
                            0;

                        if (!unifiedData[appliance]) {
                            unifiedData[appliance] = {usage, cost};
                            continue;
                        }
                        unifiedData[appliance].usage += usage;
                        unifiedData[appliance].cost += cost;
                    }
                }
                const mapped = Object.keys(unifiedData).map(element => {
                    return {
                        name: element,
                        cost: unifiedData[element].cost,
                        usage: unifiedData[element].usage
                    };
                });
                mapped.sort((a, b) => b.usage - a.usage);
                observer.next(mapped);
                observer.complete();
            } catch (e) {
                console.log('Error in data alignment', e);
                observer.error(e);
                observer.complete();
            }
        });
    }


    /**
     * Generates a diagram series from temporary data
     */
    private generateDiagramSeries(
        applianceData: any[], categoryData: any = null
    ): {
        series: ApplianceDiagramSeriesData[],
        nilm: any[],
        applianceCategories: AppliancesDetailListElement[]
    } {

        const applianceCategories: AppliancesDetailListElement[] = [];
        const series: ApplianceDiagramSeriesData[] = [];

        for (const applianceCategory of applianceData) {
            const applianceCategoryElement =
                this.generateInitialApplianceListElement(applianceCategory);

            const currentCategory = this.defaultApplianceCategories[applianceCategory.name];
            let text = 'default';
            if (currentCategory) {
                text = this.defaultApplianceCategories[applianceCategory.name].text;
                if (categoryData) {
                    try {
                        const cat = categoryData.appliances[0].energy_per_appliance;
                        for (const appliance of currentCategory.appliances) {
                            const found = cat.find(
                                el => el.appliance_instance_id === appliance.name);
                            if (found) {
                                applianceCategoryElement.appliances.push(found);
                                if (!this.application.isDemoMode()) {
                                    found.energy_ws = found.energy_ws / 3600000;
                                    found.cost = found.cost.toFixed(2);
                                } else {
                                    found.energy_ws = (applianceCategory.usage / 3).toFixed(2);
                                    found.cost = (applianceCategory.cost / 3).toFixed(2);
                                }
                                found.icon = appliance.icon;
                            }
                        }
                    } catch (e) {
                        // console.log('Error:', e);
                    }

                }
            }
            applianceCategoryElement.description = text;

            if (applianceCategory.usage > 0) {
                applianceCategories.push(applianceCategoryElement);
                series.push({
                    name: applianceCategory.name,
                    y: Math.round(applianceCategory.usage),
                    x: applianceCategory.cost.toLocaleString(),
                    color: null,
                    sliced: false
                });
            }
        }

        // assign colors
        let colorCounter = 0;
        for (let i = 0; i < series.length; ++i) {
            let color = this.colors[colorCounter];
            if (!color) {
                color = this.colors.last();
            }
            series[i].color = color;
            applianceCategories[i].color = color;
            ++colorCounter;
        }
        return {series, nilm: [], applianceCategories};
    }


    /**
     * Request category data for available appliances
     * @param disaggregationData
     */
    private requestAppliancesCategoryData(disaggregationData): Observable<any> {
        const t = moment().subtract(this.currentOffset, 'year');
        const dateStart = moment(t).month(0).toDate();
        const dateEnd = moment(t).month(11).toDate();
        return this.consumptionService.getConsumptionForMonthsPerAppliance(dateStart, dateEnd).pipe(
            mergeMap(response => {
                const aligned = {};
                for (const monthlyDataset of response.appliances) {
                    const currentElement = monthlyDataset.energy_per_appliance;
                    for (const appliance of currentElement) {
                        const id = appliance.appliance_instance_id;
                        const cost = appliance.cost;
                        const energy_ws = appliance.energy_ws;
                        if (!aligned[id]) {
                            aligned[id] = {cost, energy_ws};
                            continue;
                        }
                        aligned[appliance.appliance_instance_id].cost += cost;
                        aligned[appliance.appliance_instance_id].energy_ws += energy_ws;
                    }
                }
                const mapped = Object.keys(aligned).map(el => {
                        return {
                            appliance_instance_id: el,
                            energy_ws: aligned[el].energy_ws,
                            cost: aligned[el].cost
                        };
                    }
                );
                return of({appliances: [{energy_per_appliance: mapped}]});
            }),
            map(mappedData => {
                return {appliances: disaggregationData, consumptions: mappedData};
            })
        );
    }


    /**
     * Wrapper for creating a base appliance list element
     * @param applianceCategory - raw appliance data from API
     */
    private generateInitialApplianceListElement(applianceCategory: any):
        AppliancesDetailListElement {

        const categoryComplete = this.nilmService.nilmCategoryIsComplete(
            applianceCategory.name
        );
        const categoryRetraining = this.nilmService.nilmCategoryIsRetraining(
            applianceCategory.name
        );

        return {
            name: applianceCategory.name,
            kwh: Math.round(applianceCategory.usage).toLocaleString('de-DE'),
            cost: applianceCategory.cost.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR'
            }),
            appliances: [],
            description: 'default',
            accordionOpen: false,
            color: null,
            categoryProfileIncompleteOrRetraining: !categoryComplete || categoryRetraining,
        };
    }
}

