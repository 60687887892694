<div class="overlay-minimal">
    <div class="overlay-container">
        <h1>{{ 'screens.profile.emailChange' | translate }}</h1>
        <div>
            {{ 'screens.profile.emailChangeDescription' | translate }}
        </div>

        <form [formGroup]="formGroup" (ngSubmit)="submitChange()">
            <fieldset class="eon-input m-b-s">
                <legend>{{ 'screens.profile.newEmailLabel' | translate }}</legend>
                <input type="email" class="eon-input" formControlName="email">
            </fieldset>

            <fieldset class="eon-input m-b-s">
                <legend>{{ 'screens.profile.repeatEmailLabel' | translate }}</legend>
                <input type="email" class="eon-input" formControlName="email2">
            </fieldset>

            <button class="eon-button-new"
                    type="submit"
                    [disabled]="submitDisabled">
                {{ 'screens.profile.changeButton' | translate }}
            </button>
        </form>

    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
