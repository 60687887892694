<h2 mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content>{{ data.message }}</div>
<div mat-dialog-actions>
    <div class="flex-col center-contents">
        <button class="eon-button-new turquoise-inverted" *ngIf="data.showButton1" (click)="onButtonClick('button1')">
            {{ data.button1Text }}
        </button>
        <button class="eon-button-new turquoise-inverted" *ngIf="data.showButton2" (click)="onButtonClick('button2')">
            {{ data.button2Text }}
        </button>
        <button class="eon-button-new turquoise-inverted" *ngIf="data.showButton3" (click)="onButtonClick('button3')">
            {{ data.button3Text }}
        </button>
    </div>
</div>
