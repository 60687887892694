import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'largeInt'
})
export class LargeIntPipe implements PipeTransform {


    transform(value: number, ...args: unknown[]): string {
        if (value > 99999) {
            const divided = (value / 1000).toString();
            const decimal = divided.split('.')[0];
            return parseInt(decimal, 10).toLocaleString('de-DE') + 'k +';
        }
        return value.toString();
    }

}
