<div class="login-container">
    <div class="login-card">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>

        <ng-container [ngSwitch]="state">

            <!-- * INITIAL * -->
            <ng-container *ngSwitchCase="LoginState.INITIAL">
                <h1 class="eon-headline">
                    {{ 'screens.login.welcomeLine1' | translate }}
                    <br>
                    {{ 'screens.login.welcomeLine2' | translate }}
                </h1>

                <div class="grow-space">
                    <p>
                        {{ 'screens.login.firstTime1' | translate }}
                        <strong>{{ 'common.code' | translate }}</strong>
                        {{ 'screens.login.firstTime2' | translate }}
                    </p>
                </div>

                <div class="center-contents btn-container">
                    <button class="eon-button-new" (click)="onFirstTimeButtonPress()">
                        {{ 'screens.login.register' | translate }}
                    </button>
                </div>

                <div class="center-contents">
                    <a href="javascript:;" target="_self" class="eon-link-new" (click)="changeState(LoginState.LOGIN)">
                        {{ 'screens.login.hasPassword' | translate }}
                    </a>
                </div>

                <div class="demo-btn pointer eon-bold">
                    <span (click)="continueWithDemoMode()">
                        {{ 'screens.login.demoMode' | translate }}
                    </span>
                </div>
            </ng-container>

            <!-- * LOGIN * -->
            <ng-container *ngSwitchCase="LoginState.LOGIN">
                <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
                    <div class="grow-space">
                        <div class="btn-container">
                            <fieldset class="eon-input">
                                <legend>{{ 'screens.login.placeholderEmail' | translate }}</legend>
                                <input id="login-email" type="email" class="eon-input" formControlName="email">
                            </fieldset>
                        </div>

                        <div class="text-field-error eon-regular" *ngIf="displayEmailError">
                            <i class="alert-s bordeaux m-r-s"></i>
                            {{ 'screens.login.emailError' | translate }}
                        </div>

                        <div class="btn-container">
                            <fieldset class="eon-input">
                                <legend>{{ 'common.password' | translate }}</legend>
                                <input class="eon-input" [type]="passwordVisible ? 'text' : 'password'" formControlName="password">
                                <i [class.password-hidden]="!passwordVisible" [class.password-visible]="passwordVisible" (click)="passwordVisible = !passwordVisible"></i>
                            </fieldset>
                        </div>

                        <div class="text-field-error eon-regular" *ngIf="displayPasswordError">
                            <i class="alert-s bordeaux m-r-s"></i>
                            {{ 'screens.login.passwordError' | translate }}
                        </div>

                        <div class="split-row">
                            <div class="demo-btn pointer eon-bold">
                                <span (click)="continueWithDemoMode()">
                                    {{ 'screens.login.demoMode' | translate }}
                                </span>
                            </div>
                            <div class="password-reset eon-bold">
                                <span (click)="onResetPasswordLinkClick()">
                                    {{ 'screens.login.forgotPassword' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="center-contents btn-container">
                        <button class="eon-button-new" [disabled]="loginDisabled">
                            {{ 'common.login' | translate }}
                        </button>
                    </div>
                    <div class="center-contents">
                        <a href="javascript:;" target="_self" class="eon-link-new" (click)="onRegisterLinkClick()">
                            {{ 'screens.login.register' | translate }}
                        </a>
                    </div>
                </form>
            </ng-container>

            <!-- * PASSWORD RESET * -->
            <ng-container *ngSwitchCase="LoginState.PASSWORD_RESET">
                <h1 class="eon-headline">
                    {{ 'screens.login.welcomeLine1' | translate }}
                    <br>
                    {{ 'screens.login.welcomeLine2' | translate }}
                </h1>

                <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">
                    <div class="grow-space p-t-l">
                        <fieldset class="eon-input">
                            <legend>{{ 'screens.login.placeholderEmail' | translate }}</legend>
                            <input class="eon-input" type="email" formControlName="email">
                        </fieldset>
                    </div>

                    <div class="text-field-error eon-regular" *ngIf="displayEmailResetError">
                        <i class="alert-s bordeaux m-r-s"></i>
                        {{ 'screens.login.emailError' | translate }}
                    </div>

                    <div class="center-contents btn-container">
                        <button class="eon-button-new" type="submit" [disabled]="emailResetDisabled">
                            {{ 'screens.login.reset' | translate }}
                        </button>
                    </div>
                </form>

                <div class="center-contents">
                    <a href="javascript:;" target="_self" class="eon-link-new" (click)="changeState(LoginState.LOGIN)">
                        {{ 'screens.login.backToLogin' | translate }}
                    </a>
                </div>

                <div class="demo-btn pointer eon-bold">
                    <span (click)="continueWithDemoMode()">
                        {{ 'screens.login.demoMode' | translate }}
                    </span>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
