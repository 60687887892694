import {Component, Input, OnInit} from '@angular/core';
import {
    HouseholdComparisonRankingDataEntry
} from '../../../shared/interfaces/household-comparison.interfaces';
import {TranslateService} from '@ngx-translate/core';


export interface HouseholdComparisonRankRowConfig {
    rankingItem: HouseholdComparisonRankingDataEntry;
    motivationalMessage: string;
    showDots: boolean;
}


const emptyConfig: HouseholdComparisonRankRowConfig = {
    rankingItem: null,
    motivationalMessage: '',
    showDots: false
};


@Component({
    selector: 'app-household-comparison-rank-row',
    templateUrl: './household-comparison-rank-row.component.html',
    styleUrls: ['./household-comparison-rank-row.component.scss']
})
export class HouseholdComparisonRankRowComponent implements OnInit {
    TEXTS;
    protected readonly valueUnit = 'kWh';

    @Input() config: HouseholdComparisonRankRowConfig = emptyConfig;


    constructor(private translate: TranslateService) {
    }


    ngOnInit(): void {
        this.translate.get('detailTexts').subscribe(texts => {
            this.TEXTS = texts;
        });
    }

}
