import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Chart} from 'angular-highcharts';
import { numberFormat } from 'highcharts';
import {
    ComparisonChartBaseComponent
} from '../comparison-chart-base/comparison-chart-base.component';
import {ComparisonMapping} from '../../services/comparison-data-provider.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-comparison-chart',
    templateUrl: './comparison-chart.component.html',
    styleUrls: ['./comparison-chart.component.scss']
})
export class ComparisonChartComponent extends ComparisonChartBaseComponent implements OnInit {

    @Output() chartLoaded = new EventEmitter<ComparisonChartComponent>();
    @Output() seriesAdded = new EventEmitter<SeriesLegendData>();


    constructor(private translate: TranslateService) {
        super();
    }


    ngOnInit(): void {
        this.initializeChart();
    }


    /**
     * Wrapping function to add a series to the chart.
     * @param result - the result of the comparison presented as comparison mapping
     * @param mergeSeries - flag to indicate whether to merge the series (used for dynamic data)
     */
    addPlottableChartData(result: ComparisonMapping[], mergeSeries = false): void {
        let categories;

        if (JSON.stringify(result[0].categories) !== JSON.stringify(result[1].categories)) {
            const missing = result[1].categories.filter(x => !result[0].categories.includes(x));
            if (missing[0].toLowerCase().includes('februar')) {
                const index = result[0].categories.indexOf('28 Februar');
                if (index !== -1 && result[0].categories?.[index + 1] === '') {
                    result[0].categories[index + 1] = '29 Februar';
                } else if (index !== -1) {
                    result[0].categories.splice(index + 1, 0, '29 Februar');
                    result[0].categories.splice(result[0].categories.length, 1);
                    result[0].series.data[index + 2] = result[0].series.data[index + 1];
                    result[0].series.data[index + 1] = 0;
                }
                categories = result[0].categories;
            }
        } else {
            categories = result[0].categories;
        }

        let series: any;
        if (mergeSeries) {
            series = {
                name: null,
                data: result.map(element => {
                    return {
                        name: element.series.name,
                        y: element.series.data[0],
                        color: element.series.color
                    };
                })
            };
        } else {
            series = result.map(element => element.series);
        }

        this.chart.ref$.subscribe({
            next: (ref) => {
                this.removeAllSeries(ref);
                ref.update(
                    {
                        xAxis: {categories},
                        series: series as any,
                    }, true, true, true);
                ref.hideLoading();
            }
        });
    }


    /**
     * Shows the loading state of the chart.
     */
    showLoadingState(): void {
        this.translate.get('screens.dashboard.comparison.loading').subscribe((text: string) => {
            this.chart.ref$.subscribe({
                next: ref => {
                    ref.showLoading(text);
                }
            });
        });
    }


    /**
     * Shows the error state of the chart.
     */
    showErrorState(): void {
        this.translate.get('screens.dashboard.comparison.error').subscribe((text: string) => {
            this.chart.ref$.subscribe({
                next: ref => {
                    ref.showLoading(text);
                }
            });
        });
    }


    /**
     * Hides the loading state of the chart.
     */
    hideLoadingState(): void {
        this.chart.ref$.subscribe({
            next: ref => {
                ref.hideLoading();
            }
        });
    }


    /**
     * Initializes the chart
     * @private
     */
    private initializeChart() {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                marginLeft: 80,
                marginBottom: 30,
                marginTop: 50,
                events: {
                    addSeries(evt) {
                        const name = evt.options.name;
                        const color = evt.options.color as string;
                        self.seriesAdded.emit({name, color});
                    },
                    load() {
                        self.chartLoaded.emit(self);
                    }
                },

            },
            title: {
                text: null
            },
            xAxis: {
                // type: 'categories',
                categories: [],
                labels: {
                    style: {
                        fontFamily: 'EONBrixSans, sans-serif',
                        fontWeight: 'bold',
                        fontSize: '18px'
                    },
                    rotation: 0,
                    autoRotation: [0]
                }
            },
            yAxis: {
                title: {
                    text: self.isCostDisplayMode ? '€' : 'Watt',
                    align: 'high',
                    textAlign: 'right',
                    x: 36,
                    y: -30,
                    rotation: 0,
                    style: {
                        fontFamily: 'EONBrixSans, sans-serif',
                        fontSize: '16px',
                    }
                },
                labels: {
                    align: 'right',
                    x: -8,
                    y: 5,
                    step: 2,
                    formatter() {
                        if (this.value >= 1) {
                            const unit = self.isCostDisplayMode ? '€' : 'kWh';
                            return `${this.value.toLocaleString('de-DE')} ${unit}`;
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontFamily: 'EONBrixSans, sans-serif',
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }
                }
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const style = `style="border-color: ${this.color}"`;
                    const unit = self.isCostDisplayMode ? '€' : 'kWh';
                    let valueFormatted = this.y.toFixed(2);
                    if (self.isCostDisplayMode) {
                        valueFormatted = numberFormat(this.y, 2, ',', ' ').toString();
                    }
                    const value = `<div class="body"> ${valueFormatted} ${unit}</div>`;

                    const year = this.series.userOptions.name;
                    const category = this.key;
                    const calloutTitleStr = `${category} ${year ? year : ''}`;

                    return `<div class="column-callout" ${style}>${calloutTitleStr} ${value}</div>`;
                },
                borderColor: 'white',
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: 'transparent',
                shadow: false,
            },
            plotOptions: {
                column: {
                    minPointLength: 3,
                    borderRadius: 3,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                },
            },
            legend: {enabled: false},
            series: [],
            credits: {
                enabled: false
            }
        });
    }
}


export interface SeriesLegendData {
    name: string;
    color: string;
}
