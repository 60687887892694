<div class="today-stats">
    <div>
        <div class="eon-regular day m-b-s">
            {{comparisonDate.date | date:'dd.MM.yyyy'}}
        </div>
        <div class="eon-bold cost m-b-s">
            {{ comparisonDate.costs | todayValueFormat }}
            <small>{{LABELS.UNIT_MONEY}}</small>
        </div>
        <div class="eon-bold cost m-b-s">
            {{ comparisonDate.consumption | todayValueFormat }}
            {{LABELS.UNIT_CONSUMPTION}}
        </div>
    </div>
    <div>
        <div class="eon-regular day m-b-s">
            {{LABELS.LABEL_COMPARISON_TODAY}}
        </div>
        <div class="eon-bold cost m-b-s">
            {{ today.costs | todayValueFormat }}
            <small>{{ LABELS.UNIT_MONEY }} </small>
        </div>
        <div class="eon-bold cost m-b-s">
            {{ today.consumption | todayValueFormat }}
            {{ LABELS.UNIT_CONSUMPTION }}
        </div>
    </div>
</div>
