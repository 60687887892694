
/// bill prediction
/// ================================================================================================
export interface BillPredictionResponse {
    status: string;
    data: BillPredictionResponseData;
}


export interface BillPredictionResponseData {
    actual_consumption: number;
    base_fee: number;
    billing_period_from: string;
    billing_period_until: string;
    current_tariff: number;
    energy_covered_by_prepayments: number;
    estimated_consumption_billing_period: number;
    estimated_cost_billing_period: number;
    planned_meter_reading: string;
    sum_of_prepayments: number;
    timestamp: string;
}


/// consumption
/// ================================================================================================
export interface ConsumptionResponseData {
    calculated: number;
    compare: number;
    cost_calculated: number;
    cost_compare: number;
    cost_measured: number;
    fixed_cost: number;
    measured: number;
    temperature: number;
    timestamp: string;
}

export interface SingleDayConsumptionItem {
    current_summation: number;
    timestamp: string;
}

/// feedin
/// ================================================================================================
export interface FeedinResponse {
    status: string;
    data: FeedinResponseData;
}

export interface FeedinResponseData {
    timestamp: string;
    measured: number;
    cost_measured: number;
}



