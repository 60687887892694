import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {SeriesPieOptions} from 'highcharts';
import {
    AppliancesDiagramCallout,
    translateAppliance
} from '../../tiles/appliances/appliances.utils';
import {ViewState} from '../../shared/enums/view-state.enum';
import {
    ApplianceDiagramSeriesDataWrapper
} from '../../shared/interfaces/appliances-tile-data.interfaces';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-appliance-chart',
    templateUrl: './appliance-chart.component.html',
    styleUrls: ['./appliance-chart.component.scss']
})
export class ApplianceChartComponent implements OnInit, OnChanges {

    private defaultCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', value: '', applianceProfileComplete: false
    };
    private readonly applianceImagePath = 'assets/img/graphics/appliances/';
    private readonly applianceImageType = 'svg';
    private animateChartUpdate = true;
    private chartIsInitialized = false;

    private readonly translateAppliance;

    @Input() isDetail = false;
    @Input() series: ApplianceDiagramSeriesDataWrapper | null = null;
    @Input() viewState: ViewState = ViewState.LOADING;

    @Output() chartLoaded = new EventEmitter();

    chart: Chart = null;

    currentCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', value: '', applianceProfileComplete: false
    };

    calloutHidden = false;


    constructor(private translate: TranslateService) {
        this.translateAppliance = translateAppliance(this.translate);
    }


    ngOnInit() {
        this.initializeChart();
    }


    ngOnChanges(changes: SimpleChanges) {
        this.calloutHidden = true;
        if (this.isDetail) {
            if (this.viewState === ViewState.LOADING) {
                this.clearChart();
                this.showLoadingState(true);
            } else if (this.viewState === ViewState.SUCCESS) {
                this.createAndAddSeries(this.series);
            }
        } else {
            this.createAndAddSeries(this.series);
        }
    }


    /**
     * Creates a final drawable series from given data and adds it to the chart
     * @param seriesData
     * @private
     */
    private createAndAddSeries(seriesData: ApplianceDiagramSeriesDataWrapper): void {
        const series = {
            name: 'Series',
            type: 'pie',
            data: seriesData.series as any,
            custom: seriesData.nilm
        } as SeriesPieOptions;

        if (!this.chart) {
            return;
        }

        this.clearChart();
        this.currentCallout = this.defaultCallout;

        this.chart.ref$.subscribe({
            next: (chart) => {
                chart.addSeries(series, true, this.animateChartUpdate);
                this.calloutHidden = false;
                this.showLoadingState(false);
            }
        });
    }


    /**
     * Add a new series to the diagram
     * @param series - the series to be added
     */
    addSeries(series: SeriesPieOptions): void {
        if (!this.chart) {
            return;
        }
        this.clearChart();
        this.chart.addSeries(series, true, this.animateChartUpdate);
        this.showLoadingState(false);
        this.animateChartUpdate = false;
    }


    /**
     *
     * @param show
     */
    showLoadingState(show: boolean = true): void {
        if (!this.chart) {
            return;
        }
        if (!this.chart.ref) {
            return;
        }
        this.chart.ref.hideLoading();
        if (show) {
            this.translate.get('screens.dashboard.comparison.loading').subscribe((text: string) => {
                this.chart.ref$.subscribe({
                    next: ref => {
                        ref.showLoading(text);
                    }
                });
            });
            this.calloutHidden = true;
        } else {
            this.chart.ref.hideLoading();
            this.calloutHidden = false;
        }
    }


    showErrorState(show: boolean = true): void {
        if (!this.chart) {
            return;
        }
        if (!this.chart.ref) {
            return;
        }
        this.chart.ref.hideLoading();
        if (show) {
            const error = this.translate.instant('screens.dashboard.comparison.error');
            this.chart.ref.showLoading(`${error}…`);
        } else {
            this.chart.ref.hideLoading();
        }
    }


    clearChart(): void {
        if (!this.chart) {
            return;
        }
        if (!this.chart.ref) {
            return;
        }
        const seriesCount = this.chart.ref.series.length;
        for (let i = 0; i < seriesCount; ++i) {
            this.chart.removeSeries(i);
        }
        this.showLoadingState();
        this.calloutHidden = true;
    }


    /**
     * On Chart initialize callback
     *  - will try to draw a series if one is available
     * @private
     */
    private onChartInit(): void {
        this.chartIsInitialized = true;
        if (this.series) {
            this.createAndAddSeries(this.series);
        }
        this.showLoadingState();
        this.chartLoaded.emit();
    }


    private determineApplianceImagePath(appliance: string): string {
        return `${this.applianceImagePath}${appliance}.${this.applianceImageType}`;
    }


    private determineCalloutData(chartRef): AppliancesDiagramCallout {
        const pointName = chartRef.point.name.toLowerCase();
        const value = Math.floor(chartRef.y);
        const price = chartRef.point.options.x;

        const name = this.translateAppliance(chartRef.point.name);
        // const percentage = Math.floor(chartRef.point.percentage).toString();

        const pointIdx = chartRef.point.index;
        const profileCompleteMapping = chartRef.series.options.custom;
        const callout: AppliancesDiagramCallout = {
            image: `url(${this.determineApplianceImagePath(pointName)})`,
            color: chartRef.point.color,
            label: this.isDetail ? `${chartRef.y} kWh` : `${name}`,
            value: this.isDetail ? `${price} €` : `${value} kWh`,
            applianceProfileComplete:
                profileCompleteMapping ? profileCompleteMapping[pointIdx] : true
        };
        return callout;
    }


    private initializeChart(): void {
        const self = this;

        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [self.isDetail ? 25 : 10, 10, 25, 10],
                events: {
                    redraw() {
                        this.reflow();
                    },
                    render(event) {
                        if (!('series' in event.target)) {
                            return;
                        }
                        if (event.target['series']['length'] <= 0) {
                            return;
                        }
                        const points = event.target['series'][0]['points'] as any[];
                        const sorted = points.sort((a, b) => {
                            return b.percentage - a.percentage;
                        });
                        if (!sorted[0]) {
                            return;
                        }
                        const name = self.translate.instant(`screens.dashboard.appliances.${sorted[0].name.toLowerCase()}`);
                        const image = `${sorted[0].name.toLowerCase()}`;
                        const imagePath = self.determineApplianceImagePath(image);

                        let value = Math.floor(sorted[0].y);
                        let price = sorted[0].y;

                        // if the chart is presented in the detail view
                        // sum everything up for default callout
                        if (self.isDetail) {
                            value = 0;
                            price = 0;
                            for (const point of points) {
                                value += Math.floor(point.y);
                                price += Math.floor(parseFloat(point.x));
                            }
                        }

                        self.defaultCallout.color = sorted[0].color;
                        self.defaultCallout.image = `url(${imagePath})`;
                        self.defaultCallout.label =
                            self.isDetail ? `${value.toFixed(0)} kWh` : `${name}`;
                        self.defaultCallout.value =
                            self.isDetail ? `${price.toFixed(0)} €` : `${value} kWh`;
                        self.currentCallout = self.defaultCallout;
                    },
                    load(ref) {
                        self.onChartInit();
                    }
                }
            },
            title: {
                text: null
            },
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner(boxWidth: number, boxHeight: number) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter() {
                    self.currentCallout = self.determineCalloutData(this);
                    self.calloutHidden = false;
                    return '';
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter() {
                            const value = Math.ceil(this.percentage);
                            const style = `style="color:${this.color}"`;
                            return `<div class="label" ${style}> ${value} %</div>`;
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 0,
                        connectorColor: 'white',
                        softConnector: false,
                        style: {
                            fontSize: '20px',
                            fontFamily: 'EONBrixSans, sans-serif',
                            color: '#39393a'
                        }
                    },
                    innerSize: '90%',
                    borderWidth: 1,
                    borderColor: '#fff',
                    // slicedOffset: 0,
                    startAngle: -180,
                    states: {
                        hover: {
                            brightness: 0
                        }
                    },
                    point: {
                        events: {
                            mouseOut() {
                                self.currentCallout = self.defaultCallout;
                            },
                        }
                    }
                }
            },
            series: [],
            credits: {enabled: false}
        });

    }

}
