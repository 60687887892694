<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">{{ 'common.plug' | translate }}</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="PowerChecker"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
                <section class="powerchecker-details-head">

                    <div>
                        <app-eon-toggle
                            [state]="state"
                            (stateChanged)="onPlugStateChange($event)">
                        </app-eon-toggle>
                    </div>

                    <div>
                        <div class="plug-labels" *ngIf="!editModeEnabled; else editMode">
                            <div> {{ plugRoom }} </div>
                            <div>{{ plugName }}</div>
                        </div>
                        <ng-template #editMode>
                            <div class="plug-label-inputs">
                                <div>
                                    <fieldset class="eon-input">
                                        <legend>{{ 'screens.dashboard.powerCheckerDetails.myRoom' | translate }}</legend>
                                        <input #room
                                               type="text" class="eon-input"
                                               [value]="plugRoom">
                                    </fieldset>
                                </div>
                                <div>
                                    <fieldset class="eon-input">
                                        <legend>{{ 'screens.dashboard.powerCheckerDetails.myDevice' | translate }}</legend>
                                        <input #name
                                               type="text" class="eon-input"
                                               [value]="plugName">
                                    </fieldset>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div>
                        <div class="pointer" (click)="onSaveChanges()">
                            <ng-container *ngIf="!editModeEnabled; else editModeIcon">
                                <i class="edit-sm dark-grey"></i>
                            </ng-container>
                            <ng-template #editModeIcon>
                                <i class="check-sm dark-grey"></i>
                            </ng-template>
                        </div>
                    </div>


                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <h2 class="eon-black">{{ 'screens.dashboard.powerCheckerDetails.whatIsSmartControlPlug' | translate }}</h2>
                    <p>
                        {{ 'screens.dashboard.powerCheckerDetails.smartControlDescription' | translate }}
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <h3>{{ 'screens.dashboard.powerCheckerDetails.discoverSmartControlPlug' | translate }}</h3>
                <p></p>

                <div class="m-b-m carousel-container">
                    <ngu-carousel #carousel [inputs]="carouselTileConfig" [dataSource]="slideshowAnimations">
                        <ngu-tile *nguCarouselDef="let item; let j = index" class="image-tile">
                            <div lottie [options]="item"></div>
                        </ngu-tile>


                        <div NguCarouselPoint
                             class="image-tile-point-list m-a-s">
                            <div *ngFor="let image of slideshowAnimations; let i = index"
                                 [class.active]="i===carousel.activePoint"
                                 (click)="carousel.moveTo(i)"
                                 class="image-tile-point m-a-s">
                            </div>
                        </div>

                        <div NguCarouselPrev class="carousel-control carousel-prev">
                            <button class="eon-icon-button small turquoise"
                                    [disabled]="carousel.isFirst">
                                <i class="chevron-left-s white"></i>
                            </button>
                        </div>
                        <div NguCarouselNext class="carousel-control carousel-next">
                            <button class="eon-icon-button small turquoise"
                                    [disabled]="carousel.isLast">
                                <i class="chevron-right-s white"></i>
                            </button>
                        </div>

                    </ngu-carousel>
                </div>
            </section>
        </div>
    </div>
</div>
