<div class="overlay overlay-nilm">
    <div class="overlay-container-cdk">
        <h1 class="font-bold">{{ 'popovers.nilmDevice.deviceDetectedTitle' | translate }}</h1>
        <h2 class="font-regular">{{ 'popovers.nilmDevice.deviceCountQuestion' | translate: {appliancePlural: appliancePlural} }}</h2>

        <div #applianceIcon class="appliance-icon"></div>

        <div class="interaction-area">
            <button class="icon-only turqouise"
                    (click)="lowerAmount()">
                <i class="minus-s white"></i>
            </button>
            <span class="value">{{ amount }}</span>
            <button class="icon-only turqouise"
                    (click)="raiseAmount()">
                <i class="plus-s white"></i>
            </button>
        </div>

        <div class="center-contents">
            <button class="eon-button-new turquoise"
                    (click)="confirm()">
                {{ 'popovers.nilmDevice.confirmButtonText' | translate }}
            </button>
        </div>
    </div>
</div>
