import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable, of} from 'rxjs';
import {Tariff} from '../classes/user';
import {mergeMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {ProfileService} from './profile.service';


@Injectable({
    providedIn: 'root'
})
export class UserTariffService {

    tariffs: Tariff[] = null;


    constructor(private userService: UserService,
                private profileService: ProfileService) {
    }


    tariffsAvailable(): boolean {
        return this.tariffs !== null;
    }


    getTariffs$(): Observable<Tariff[] | null> {
        if (this.tariffs) {
            return of(this.tariffs);
        }
        return of(this.userService.getTariffInfo()).pipe(
            mergeMap(tariffs => tariffs ? of(tariffs) : of(null)),
            mergeMap((tariffs: any) => {
                if (tariffs) {
                    this.tariffs = tariffs;
                    return of(tariffs);
                }
                return this.profileService.getContract().pipe(
                    mergeMap(contract =>
                        this.constructBaseTariff(contract).pipe(
                            // tap(tariff => console.log('base tariff after construction', tariff)),
                            mergeMap(tariff => this.tariffs = [tariff])
                        )
                    )
                );
            })
        );
    }


    /**
     * Returns an observable containing the users currently active tariff.
     * Filtering is done by the current date.
     */
    getActiveTariff$(): Observable<Tariff | null> {
        return this.getTariffs$().pipe(
            mergeMap((tariffs) => {
                try {
                    const today = new Date();
                    console.log('tariffs in service', this.tariffs);
                    const tariff = tariffs.find((el) => {
                        const start = moment(el.dateStart).startOf('day').toDate();
                        const end = el.dateEnd ? new Date(el.dateEnd) : new Date();
                        return today >= start && today <= end;
                    });
                    return of(tariff);
                } catch (error) {
                    return of(null);
                }
            })
        );
    }


    /**
     * Determines multiplier in stored tariffs by an entered timestamp
     * @param timestamp - string representing a timestamp
     * @private
     */
    getTariffMultiplier(timestamp: Date): number {
        try {
            const foundTariff = this.tariffs.find((el) => {
                const start = moment(el.dateStart)
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0)
                    .toDate();
                const end = el.dateEnd ? new Date(el.dateEnd) : new Date();
                return timestamp >= start && timestamp <= end;
            });
            return foundTariff.workPrice;
        } catch (error) {
            return 1;
        }
    }


    /**
     * Generates a base tariff for some reason
     * @param response
     */
    private constructBaseTariff(response: any): Observable<any> {
        return new Observable<any>((observer) => {
            try {
                const basePrice = typeof response.profile.e_fixed_tariff !== 'undefined' ?
                    parseFloat(response.profile.e_fixed_tariff) :
                    parseFloat(response.profile.budget_bill);
                const workPrice = parseFloat(response.profile.e_tariff);
                const t: Tariff = {
                    name: 'default',
                    dateStart: moment().subtract(1, 'day').toDate(),
                    dateEnd: moment().add(2, 'days').toDate(),
                    basePrice,
                    workPrice
                };
                observer.next(t);
            } catch (error) {
                observer.error(error);
            }
        });
    }


    private initializeTariffs(): void {
        if (this.tariffs) {
            console.log('Tariffs already initialized.');
            return;
        }
        of(this.userService.getTariffInfo()).pipe(
            mergeMap(tariffs => tariffs ? of(tariffs) : of(null)),
            mergeMap((tariffs: any) => {
                if (tariffs) {
                    this.tariffs = tariffs;
                    return of(tariffs);
                }
                return this.profileService.getContract().pipe(
                    mergeMap(contract =>
                        this.constructBaseTariff(contract).pipe(
                            mergeMap(tariff => this.tariffs = [tariff])
                        )
                    )
                );
            })
        ).subscribe((tariffs) => {
            this.tariffs = tariffs;
        });
    }
}
