import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import * as moment from 'moment';
import {months} from 'moment';
import {ElectricityService} from '../../../services/electricity.service';
import {HappyHourService} from '../../../services/happy-hour.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Tariff} from '../../../classes/user';
import {ProfileService} from '../../../services/profile.service';
import {
    ComparisonDataProviderService,
    ComparisonMapping
} from '../../../services/comparison-data-provider.service';
import {
    ComparisonChartComponent,
    SeriesLegendData
} from '../../../charts/static-comparison-chart/comparison-chart.component';
import {UserTariffService} from '../../../services/user-tariff.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-comparison-details',
    templateUrl: './comparison-details.component.html',
    styleUrls: ['comparison-details.component.scss'],
    viewProviders: []
})

export class ComparisonDetailsComponent extends BasePopover implements OnInit, AfterViewInit {

    readonly comparisonTimeframe = ComparisonTimeframe;
    readonly comparisonMode = ComparisonMode;
    readonly comparisonDisplayMode = ComparisonDisplayMode;
    MONTHS;

    private comparisonChartComponent: ComparisonChartComponent | null = null;

    currentMode = ComparisonMode.STATIC;
    currentTimeframe = ComparisonTimeframe.MONTH;
    currentDisplayMode = ComparisonDisplayMode.CONSUMPTION;

    currentPosition = 1;
    year = 0;

    dynamicComparisonDates: DynamicComparisonDates = {
        lhsDay: 0, lhsCalendarWeek: 0, lhsMonth: 0, lhsYear: 0,
        rhsDay: 0, rhsCalendarWeek: 0, rhsMonth: 0, rhsYear: 0
    };

    seriesLegend = [];

    infoVisible = false;
    interactionDisabled = true;

    currentBasePrice: Observable<string> = this.userTariffService.getActiveTariff$().pipe(
        switchMap(activeTariff => this.handleBasePriceChange(activeTariff))
    );

    constructor(protected popoverRef: PopoverRef,
                public userService: UserService,
                public application: ApplicationService,
                private electricityService: ElectricityService,
                private analytics: TrackAnalyticsService,
                private happyHour: HappyHourService,
                private profile: ProfileService,
                private comparisonDataProvider: ComparisonDataProviderService,
                private userTariffService: UserTariffService,
                private translate: TranslateService
    ) {
        super(popoverRef);
    }


    ngOnInit() {
        this.comparisonDataProvider.resetTimeframeAndOffset();
        this.constructInitialDynamicComparisonDates();
        this.translate.get('screens.dashboard.months').subscribe((monthsList: string[]) => {
            this.MONTHS = monthsList;
        });
    }


    ngAfterViewInit(): void {
    }


    /**
     * Sets the current comparison mode
     * @param newMode
     */
    setMode(newMode: ComparisonMode) {
        if (newMode === this.currentMode) {
            return;
        }
        this.currentMode = newMode;
        this.comparisonDataProvider.resetTimeframeAndOffset();

        this.resetComponent();
        this.trackModeChangeEvent();
    }


    /**
     * Sets the current timeframe
     * @param newTimeframe
     */
    setTimeframe(newTimeframe: ComparisonTimeframe) {
        if (newTimeframe === this.currentTimeframe) {
            return;
        }
        this.currentTimeframe = newTimeframe;
        this.currentPosition = 1;
        this.constructInitialDynamicComparisonDates();
        this.resetComponent();
    }


    /**
     * Updates component value display mode
     * @param mode
     */
    setDisplayMode(mode: ComparisonDisplayMode) {
        if (this.currentDisplayMode === mode) {
            return;
        }
        this.currentDisplayMode = mode;
        if (mode === ComparisonDisplayMode.COST) {
            this.comparisonChartComponent.updateDisplayMode(true);
        } else {
            this.comparisonChartComponent.updateDisplayMode(false);
        }
        this.resetComponent();
    }


    /**
     * Update comparison dates by user selection
     * @param key
     * @param value
     */
    setComparisonDate(key, value) {
        this.dynamicComparisonDates[key] = parseInt(value, 10);
        this.resetComponent();
    }


    /**
     * Skip one timeframe ahead
     */
    positionForward() {
        if ((this.currentPosition > 1) && (!this.interactionDisabled)) {
            this.currentPosition--;
            this.resetComponent();
        }
    }


    /**
     * Determines if the position forward button is disabled
     */
    positionForwardDisabled(): boolean {
        return this.comparisonDataProvider.isStepForwardDisabled();
    }


    /**
     * Skips one timeframe backward
     */
    positionBack() {
        if (this.interactionDisabled) {
            return;
        }
        this.currentPosition++;
        this.resetComponent();
    }


    /**
     * Determines if the position back button is disabled
     */
    positionBackDisabled(): boolean {
        return this.comparisonDataProvider.isStepBackDisabled();
    }


    /**
     * Select position now
     */
    positionNow() {
        if (this.interactionDisabled) {
            return;
        }
        this.currentPosition = 1;
        this.comparisonDataProvider.resetTimeframeAndOffset();
        this.resetComponent();
    }


    /**
     * Reset the chart and reload data
     */
    resetComponent() {
        this.comparisonChartComponent.showLoadingState();

        this.interactionDisabled = true;
        this.comparisonChartComponent.reset();
        this.comparisonChartComponent.showLoadingState();
        this.seriesLegend = [];

        this.updateCurrentBasePrice();
        this.getDataForCurrentTimeframeAndMode();
    }


    /**
     * Proxy function to get a loopable array
     * @param start
     * @param times
     */
    loop(start: number, times: number) {
        const loop = [];
        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }
        return loop;
    }


    /**
     * Callback once the static chart has been loaded.
     * @param chart
     */
    onChartLoaded(chart: ComparisonChartComponent): void {
        this.comparisonChartComponent = chart;
        this.comparisonChartComponent.showLoadingState();
        this.getDataForCurrentTimeframeAndMode();
    }


    /**
     *  Updates the legend each time a new series is added to the chart.
     * @param seriesLegendData
     */
    updateLegend(seriesLegendData: SeriesLegendData): void {
        if (this.currentMode === ComparisonMode.STATIC) {
            this.seriesLegend.push(seriesLegendData);
        }
    }


    /**
     * Construct the initial dates for the dynamic comparison mode.
     * This is used to set the initial values for the individual dropdowns.
     * @private
     */
    private constructInitialDynamicComparisonDates() {
        const rhsDate = moment();
        let lhsDate = moment();

        this.year = rhsDate.year();

        switch (this.currentTimeframe) {
            case ComparisonTimeframe.WEEK: { // Woche
                lhsDate = moment(rhsDate).subtract(1, 'week');
                break;
            }
            case ComparisonTimeframe.MONTH: { // Monat
                lhsDate = moment(rhsDate).subtract(1, 'months');
                break;
            }
            case ComparisonTimeframe.YEAR: { // Jahr
                lhsDate = moment(rhsDate).subtract(1, 'years');
                break;
            }
            default: { // Tag
                lhsDate = moment(rhsDate).subtract(1, 'days');
                break;
            }
        }

        this.dynamicComparisonDates = {
            lhsDay: lhsDate.date(),
            lhsCalendarWeek: lhsDate.week(),
            lhsMonth: lhsDate.month() + 1,
            lhsYear: lhsDate.year(),
            rhsDay: rhsDate.date(),
            rhsCalendarWeek: rhsDate.week(),
            rhsMonth: rhsDate.month() + 1,
            rhsYear: rhsDate.year()
        };
    }


    /**
     * Requests data for the current timeframe and mode from provider.
     * Displays the data in the chart.
     * @private
     */
    private getDataForCurrentTimeframeAndMode() {
        let request: Observable<ComparisonMapping[]>;
        if (this.application.isDemoMode()) {
            if (this.currentMode === ComparisonMode.STATIC) {
                request = this.comparisonDataProvider.getStaticDemoDataForOffsetTimeframe$(
                    this.currentPosition, this.currentTimeframe, this.currentDisplayMode
                );
            } else {
                request = this.comparisonDataProvider.getDynamicDemoDataForComparisonDates$(
                    this.dynamicComparisonDates, this.currentTimeframe, this.currentDisplayMode
                );
            }
        } else {
            if (this.currentMode === ComparisonMode.STATIC) {
                request = this.comparisonDataProvider.getStaticComparisonDataForOffset$(
                    this.currentPosition, this.currentTimeframe, this.currentDisplayMode
                );
            } else {
                request = this.comparisonDataProvider
                    .getDynamicComparisonDataForComparisonDates$(
                        this.dynamicComparisonDates,
                        this.currentTimeframe,
                        this.currentDisplayMode
                    );
            }
        }

        const sub = request.subscribe({
            next: (data) => {
                if (data.first().series.data.length === 0 && data.last().series.data.length === 0) {
                    this.comparisonChartComponent.hideLoadingState();
                    this.comparisonChartComponent.showErrorState();
                    return;
                }

                this.comparisonChartComponent.addPlottableChartData(data);
                // re-enable interaction
                this.interactionDisabled = false;

                // check if there is any valid data present in the constructed series
                const summedConsumptions = data.map(item => {
                    return item.series.data.reduce((a, b) => a + b, 0);
                }).reduce((a, b) => a + b, 0);

                // if all data is zero, show error state
                if (summedConsumptions === 0) {
                    this.comparisonChartComponent.hideLoadingState();
                    this.comparisonChartComponent.showErrorState();
                    return;
                } else {
                    this.comparisonChartComponent.hideLoadingState();
                }
            },
            error: (error) => {
                console.error('Error:', error);
                this.comparisonChartComponent.hideLoadingState();
                this.comparisonChartComponent.showErrorState();
                this.interactionDisabled = true;
            },
            complete: () => {
                sub.unsubscribe();
            }
        });

    }


    /**
     * Updates the current base price by retrieving the active tariff again.
     * This is necessary because the base price varys depending on the timeframe.
     * @private
     */
    private updateCurrentBasePrice(): void {
        this.currentBasePrice = this.userTariffService.getActiveTariff$().pipe(
            switchMap(tariff => this.handleBasePriceChange(tariff))
        );
    }


    /**
     * Returns the formatted base price for the current timeframe
     * @param tariff
     * @private
     */
    private handleBasePriceChange(tariff: Tariff): Observable<string> {
        let valueFormatted = tariff.basePrice;
        let unit = '€ / ';
        switch (this.currentTimeframe) {
            case ComparisonTimeframe.DAY:
                unit += 'Tag';
                valueFormatted = valueFormatted / 365;
                break;
            case ComparisonTimeframe.WEEK:
                unit += 'Woche';
                valueFormatted = valueFormatted / 52.1429;
                break;
            case ComparisonTimeframe.MONTH:
                unit += 'Monat';
                valueFormatted = valueFormatted / 12;
                break;
            case ComparisonTimeframe.YEAR:
                unit += 'Jahr';
                break;
        }
        const valueFormattedStr = valueFormatted.toLocaleString('de-DE', {
            maximumFractionDigits: 2
        });
        return of(`${valueFormattedStr} ${unit}`);
    }


    /**
     * EventTracking
     * @private
     */
    private trackModeChangeEvent(): void {
        const modeStr =
            this.currentMode === ComparisonMode.DYNAMIC ? 'Anpassbar' : 'Statisch';
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: Vergleich - ' + modeStr +
                    '; previous_screen: Vergleich - ' + modeStr
            }
        });
    }

}

export enum ComparisonTimeframe {
    DAY = 'days',
    WEEK = 'week',
    MONTH = 'monts',
    YEAR = 'year'
}

enum ComparisonMode {
    STATIC = 'static',
    DYNAMIC = 'dynamic'
}

export enum ComparisonDisplayMode {
    COST = 'cost',
    CONSUMPTION = 'consumption',
    FEEDIN = 'feedin'
}

export interface DynamicComparisonDates {
    lhsDay: number;
    lhsCalendarWeek: number;
    lhsMonth: number;
    lhsYear: number;
    rhsDay: number;
    rhsCalendarWeek: number;
    rhsMonth: number;
    rhsYear: number;
}
