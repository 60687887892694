<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="{{ 'common.menu' | translate }}" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text">{{ 'common.menu' | translate }}</span>
        </a>
        <app-menu activeElement="settings"></app-menu>
    </aside>

    <div class="page">
        <div id="inner">
            <div class="page-card">
                <h1>{{ 'screens.settings.title' | translate }}</h1>

                <section>
                    <h2 class="extra-space">{{ 'screens.settings.connections.title' | translate }}</h2>
                    <div>
                        <h3>{{ deviceIsPlug ? ('common.device.plug' | translate) : isBox2 ? ('common.device.box2' | translate) : ('common.device.default' | translate) }}</h3>
                        <div class="connection-container p-t-s">
                            <i class="wifi-lg"></i>
                            <div class="p-r-s">
                                <ng-container *ngIf="wifiConnected < 0; else disonnected">
                                    <i class="wifi-sm turquoise"></i>
                                </ng-container>
                                <ng-template #disonnected>
                                    <i class="alert.sm bordeaux"></i>
                                </ng-template>
                            </div>
                            <ng-container *ngIf="lanConnected else wifiConnection">
                                <div>{{ 'common.connected' | translate }}</div>
                            </ng-container>
                            <ng-template #wifiConnection>
                                <div [style.color]="wifiConnected < 0 ? '#1ea2b1' : '#b00402'">
                                    {{ wifiConnected < 0 ? ('screens.settings.connections.wifiConnected' | translate) : ('screens.settings.connections.wifiDisconnected' | translate) }}
                                </div>
                            </ng-template>
                        </div>

                        <h3>{{ 'screens.settings.meter.title' | translate }}</h3>
                        <div class="connection-container p-t-s p-r-s">
                            <div class="p-r-s">
                                <ng-container *ngIf="meterStatus === 'connected'; else disonnected">
                                    <i class="wifi-sm turquoise"></i>
                                </ng-container>
                                <ng-template #disonnected>
                                    <i class="alert-sm bordeaux"></i>
                                </ng-template>
                            </div>
                            <div [style.color]="meterStatus === 'connected' ? '#1ea2b1' : '#b00402'">
                                {{ determineMeterStatusMessage() }}
                            </div>
                        </div>

                        <ng-container *ngIf="pincode">
                            <h3>{{ 'screens.settings.pincode.title' | translate }}</h3>
                            <div class="p-r-s">
                                <div class="p-r-s">
                                    {{ pincode }}
                                </div>
                            </div>
                        </ng-container>

                        <div class="m-t-l" *ngIf="isEDGUser && this.pinEntryUnknownOrOptical">
                            <button class="eon-button-new turquoise"
                                    (click)="onPinEntryOpen()">
                                {{ 'screens.settings.pinEntry.button' | translate }}
                            </button>
                        </div>

                        <ng-container *ngIf="isEDGUser">
                            <h3>{{ 'screens.settings.batteryStatus.title' | translate }}</h3>
                            <div class="p-t-s">
                                <app-battery-load [currentBatteryState]="currentBatteryState"
                                                  [isAvailable]="currentBatteryState > -1">
                                </app-battery-load>
                            </div>

                            <div class="m-t-l">
                                <button class="eon-button-new turquoise"
                                        [disabled]="currentBatteryState === 0"
                                        (click)="onEnergySaverOpen()">
                                    {{ 'screens.settings.energySaver.button' | translate }}
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </section>

                <section>
                    <h2 class="extra-space">{{ 'screens.settings.onlineMode.title' | translate }}</h2>
                    <label class="eon-checkbox" for="checkbox">
                        <input #optInCb type="checkbox" name="checkbox" id="checkbox"
                               [checked]="config.optIn"
                               (change)="onOptInCheckboxChange(optInCb.checked)">
                        {{ 'screens.settings.onlineMode.label' | translate }}
                    </label>
                    <p>
                        {{ 'screens.settings.onlineMode.description' | translate: {device: (deviceIsPlug ? ('common.device.plug' | translate) : (isBox2 ? ('common.device.box2' | translate) : ('common.device.box' | translate)))} }}
                    </p>
                </section>

                <section>
                    <h2 class="extra-space">{{ 'screens.settings.anonymousDataProcessing.title' | translate }}</h2>
                    <label class="eon-checkbox" for="benchmark-checkbox">
                        <input #hhcOptOutCb type="checkbox" name="checkbox" id="benchmark-checkbox"
                               [checked]="config.householdComparisonOptOut"
                               (change)="setHouseholdComparisonOptOut(hhcOptOutCb.checked)">
                        {{ 'screens.settings.anonymousDataProcessing.label' | translate }}
                    </label>
                    <p>
                        {{ 'screens.settings.anonymousDataProcessing.description' | translate }}
                    </p>
                </section>

                <section>
                    <h2 class="extra-space">{{ 'screens.settings.optInDataProcessing.title' | translate }}</h2>
                    <label class="eon-checkbox" for="data-optin-checkbox">
                        <input #dataOptInCheckbox type="checkbox" name="checkbox" id="data-optin-checkbox"
                               [checked]="config.dataOptIn"
                               (change)="setDataOptinCheckBox(dataOptInCheckbox.checked)">
                        {{ 'screens.settings.optInDataProcessing.label' | translate }}
                    </label>
                    <p>
                        {{ 'screens.settings.optInDataProcessing.description' | translate }}
                    </p>
                </section>

                <section>
                    <h2 class="extra-space">{{ 'screens.settings.language.title' | translate }}</h2>
                    <p>
                        {{ 'screens.settings.language.description' | translate }}
                    </p>
                    <button class="eon-button-new" (click)="openLanguageModal()">
                        {{ 'screens.settings.language.changeButton' | translate }}
                    </button>
                </section>

                <button class="eon-button-new"
                        (click)="saveSettings()">
                    {{ 'screens.settings.saveButton' | translate }}
                </button>

            </div>
        </div>
    </div>
</div>
