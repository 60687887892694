<div class="toggle-container"
     (click)="onToggle($event)">
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="32" viewBox="0 0 56 32">
        <path class="outline"
              [class.on]="state"
              d="M39.9955,2 L15.9955,2 C15.9335,2 8.8005,2.08 5.9435,2.604 C4.3725,2.919 3.2105,4.093 2.9275,5.663 C2.3085,9.093 2.0005,12.546 2.0005,16 C2.0005,19.454 2.3085,22.908 2.9275,26.337 C3.2105,27.905 4.3725,29.078 5.9605,29.396 C9.1635,29.927 15.9365,30 16.0055,30 L40.0005,30 C40.2155,29.998 46.8735,29.921 50.0735,29.391 C51.6285,29.078 52.7905,27.905 53.0735,26.337 C53.6915,22.907 54.0005,19.454 54.0005,16 C54.0005,12.546 53.6915,9.092 53.0735,5.663 C52.7905,4.093 51.6285,2.919 50.0405,2.601 C47.2005,2.08 40.0665,2 39.9955,2 L39.9955,2 Z M40.0105,32 L15.9955,32 C15.7015,31.997 8.9895,31.926 5.5995,31.363 C1.5615,30.554 0.0005,27.458 0.0005,16 C0.0005,3.27 2.2415,1.307 5.5655,0.64 C8.6075,0.082 15.6855,0.003 15.9855,8.09593278e-14 L40.0055,8.09593278e-14 C40.3145,0.003 47.3925,0.082 50.4185,0.637 C53.7285,1.301 56.0005,3.248 56.0005,16 C56.0005,28.729 53.7585,30.69 50.4345,31.357 C47.0115,31.926 40.2995,31.997 40.0155,32 L40.0105,32 Z"></path>
        <path class="knob"
              [class.on]="state"
              d="M8.222,5.2397 C6.709,5.5277 5.528,6.7087 5.24,8.2217 C4.253,13.4067 4.253,18.5927 5.24,23.7777 C5.528,25.2907 6.709,26.4717 8.222,26.7607 C10.815,27.2537 13.408,27.4997 16,27.4997 C18.593,27.4997 21.186,27.2537 23.779,26.7607 C25.291,26.4717 26.473,25.2907 26.76,23.7777 C27.746,18.5927 27.746,13.4067 26.76,8.2217 C26.473,6.7087 25.291,5.5277 23.779,5.2397 C21.186,4.7467 18.593,4.4997 16,4.4997 C13.408,4.4997 10.815,4.7467 8.222,5.2397"></path>
    </svg>
</div>
