<div class="overlay-small">
    <div class="overlay-container">
        <h1>{{ 'screens.profile.twoFactorAuthTitle' | translate }}</h1>
        <p>
            {{ 'screens.profile.twoFactorAuthDescription' | translate }}
        </p>
        <form [formGroup]="form" (ngSubmit)="submitCode()">
            <div>
                <fieldset class="eon-input">
                    <legend>{{ 'common.password' | translate }}</legend>
                    <input id="code-input" class="eon-input" type="text"
                           placeholder="{{ 'screens.profile.codePlaceholder' | translate }}"
                           (keydown)="onKeyDown($event)"
                           (keyup)="onKeyUp($event)"
                           formControlName="code">
                    <label for="code-input"></label>
                </fieldset>
                <div class="loading-indicator"
                     [class.hidden]="!isLoading">
                    <ng-lottie class="loading-spinner" height="35px"
                               [options]="loadingAnimation"></ng-lottie>
                </div>
            </div>
            <button class="eon-button-new"
                    type="submit"
                    [disabled]="submitDisabled || isLoading">
                {{ 'screens.profile.submitButton' | translate }}
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
