<div class="view-container">
    <h2 class="household-summary font-black">
        <ng-container *ngIf="householdComparisonData$ | async as householdComparisonData">
            <ng-container *ngIf="householdComparisonData.viewState === ViewState.SUCCESS">
                <ng-container *ngIf="householdComparisonData.comparableHouseholds > 1">
                    <ng-container *ngIf="currentFilter$ | async as currentFilters">
                        <ng-container *ngIf="currentFilters.length === 0; else noFilter">
                            {{ LABELS.displayComparableHouseholdsAll }}
                        </ng-container>
                        <ng-template #noFilter>
                            {{ householdComparisonData.comparableHouseholds }}
                            {{ LABELS.displayComparableHouseholds }}
                        </ng-template>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="householdComparisonData.comparableHouseholds === 1">
                    {{ householdComparisonData.comparableHouseholds }}
                    {{ LABELS.displayComparableHousehold }}
                </ng-container>
                <ng-container *ngIf="householdComparisonData.comparableHouseholds === 0">
                    {{ LABELS.displayComparableHouseholdEmpty }}
                </ng-container>
            </ng-container>
            <ng-container *ngIf="householdComparisonData.viewState === ViewState.LOADING">
                {{ LABELS.stateLoading }}
            </ng-container>
        </ng-container>
    </h2>

    <ng-container *ngIf="currentFilter$ | async as currentFilter">
        <ng-container *ngIf="currentFilter.length > 0; else filterEmptyState">
            <div class="filter-list scroll-container-vertical household-comparison">
                <div class="filter-list-item" *ngFor="let item of currentFilter">
                    <div [style.mask-image]="determineFilterIcon(item.profileKey)"
                         [style.-webkit-mask-image]="determineFilterIcon(item.profileKey)">
                    </div>
                    <strong>
                        {{ item.title }}
                    </strong>
                    {{ item.wording }}
                </div>
            </div>
        </ng-container>
        <ng-template #filterEmptyState>
            <div class="filter-list empty">
                {{ LABELS.stateEmpty }}
            </div>
        </ng-template>
    </ng-container>

    <div class="button-wrap">
        <button class="eon-button-new turquoise"
                (click)="onEnterFormMode()"
                [disabled]="application.isDemoMode()">
            {{ LABELS.displayButtonAdjust }}
        </button>
    </div>

    <p class="info small m-t-m m-b-n">
        <ng-container *ngIf="!application.isDemoMode; else demoModeHint">
            {{ TEXTS.filterDisplay.info }}
        </ng-container>
        <ng-template #demoModeHint>
            {{ TEXTS.filterDisplay.infoDemoMode }}
        </ng-template>
    </p>
</div>
