import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import {MetaResponse} from '../shared/interfaces/meta-response';

export class BaseService {

    static instances = [];

    protected AUTH_BASE_URL = environment.urls.auth;
    protected API_BASE_URL = environment.urls.api;
    protected ACCOUNT_REWRITE_BASE_URL = environment.urls.rewriteUrl;
    protected readonly EWS_BASE_URL = environment.urls.userGroup;


    static killAll(): void {
        for (const i of BaseService.instances) {
            i.destroy();
        }
    }


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        BaseService.instances.push(this);
    }


    destroy(): void {
    }


    /**
     * Handle any ocurring response errors
     * @param error
     */
    protected handleError(error: HttpErrorResponse): Observable<any> {
        if (error.status === 401) {
            this.auth.logoutUser();
            return of(false);
        }
        return throwError(error);
    }


    /**
     * Validate whether the response is generally valid
     * @param res
     */
    protected responseValid(res): boolean {
        if (!res) {
            return false;
        }
        if ('status' in res) {
            if (res.status.toLowerCase() === 'ok') {
                return 'data' in res;
            }
        }
        return false;
    }


    /**
     * Returns a default mapped object from the n2g API
     * @param res
     */
    protected mapDefault(res: any): any {
        if (res) {
            if (this.responseValid(res)) {
                return res['data'];
            }
            console.log('Response invalid');
            return null;
        }

        return null;
    }


    /**
     * Map any input data to meta response type
     * @param data - either real data or error data
     * @param isError - is it an error?
     */
    protected mapToMetaResponse(data: any, isError: boolean): Observable<MetaResponse> {
        return of({status: isError ? 'error' : 'success', data});
    }

}
