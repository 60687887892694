import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';

@Component({
    selector: 'app-add-tile',
    templateUrl: './add-tile.component.html',
    styleUrls: ['./add-tile.component.scss']
})
export class AddTileComponent implements OnInit {

    currentTiles: TileDef[] = [];

    constructor(public popoverRef: PopoverRef,
                private tilesService: TileService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
        this.initTileList();
    }

    ngOnInit() {
    }

    close(): void {
        this.popoverRef.close(null);
    }

    onTileToggle(tile: TileDef) {
        if (tile.selected) {
            return;
        }
        if (tile.type === TILE_TYPE.MVP) {
            this.tilesService.toggleMVPTile(tile.id);
            return;
        }
        this.tilesService.toggleTile(tile);
        this.initTileList();
    }

    private initTileList(): void {
        const tiles = this.tilesService.getCurrentTiles();
        const tilesCpy = JSON.parse(JSON.stringify(tiles));
        const sortedTiles = tilesCpy.sort((a, b) => {
            if (a.addIndex < b.addIndex) {
                return -1;
            }
            if (a.addIndex > b.addIndex) {
                return 1;
            }
            return 0;
        });
        this.currentTiles = sortedTiles;
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
    }

}


