<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Meine Geräte - Monatsübersicht; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <div class="tile-header-row">
                <h3 class="tile-heading">
                    {{TEXTS.TITLE}}
                </h3>

                <div *ngIf="showAttentionIndicator"
                     class="attention-indicator-tile">
                </div>
            </div>
            <div class="tile-content">
                <ng-container *ngIf="combinedApplianceTileData$ | async as tileData">
                    <ng-container [ngSwitch]="tileData.applianceTileData.viewState">
                        <ng-container *ngSwitchCase="ViewState.SUCCESS">
                            <div class="appliance-chart">
                                <div class="tile-chart-abs">
                                    <app-appliance-chart
                                        [series]="tileData.applianceTileData.series">
                                    </app-appliance-chart>
                                </div>
                            </div>
                            <div class="appliance-timeframe center-contents font-medium">
                                <span>
                                    {{TEXTS.LABEL_CONSUMPTION_IN}}
                                    {{tileData.applianceTileData.currentMonth}}
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.ERROR">
                            <div class="inflate center-contents">
                                {{TEXTS.ERROR}}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.LOADING">
                            <div class="inflate center-contents">
                                {{TEXTS.LOADING}}
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
