import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class DataProviderBaseService {
    static instances: DataProviderBaseService[] = [];


    static killAll(): void {
        for (const i of DataProviderBaseService.instances) {
            i.destroy();
        }
    }


    constructor() {
    }


    destroy(): void {

    }
}
