<mat-dialog-content class="modal-content">
    <button *ngIf="showCloseButton" class="close-button" (click)="closeModal()">
        <img src="assets/img/close.webp" alt="Close" width="20px">
    </button>
    <div *ngIf="imageUrl" class="icon-container">
        <img [src]="imageUrl" width="85px">
    </div>
    <div *ngIf="title" class="title-container">
        <h2>{{ title }}</h2>
    </div>
    <div [ngClass]="{'text-container-with-image': imageUrl, 'text-container': !imageUrl}">
        <p>{{ modalText }}</p>
    </div>
    <button class="eon-button-new" (click)="closeModal()">
        {{ buttonText }}
    </button>
</mat-dialog-content>
