import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MockDataService} from '../../../services/mock-data.service';
import {Subscription, timer} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {LiveDataService} from '../../../services/live-data.service';
import {HomestateService} from '../../../services/homestate.service';
import {ApplicationService} from '../../../services/application.service';
import {liveDetailZoomLevels, LiveZoomLevel} from '../constants/live.constants';
import * as moment from 'moment';
import {LiveChartComponent} from '../../../charts/live-chart/live-chart.component';
import {determineDurationPassed} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {Router} from '@angular/router';
import {OpticalReaderService} from '../../../services/optical-reader.service';
import {HeartbeatService} from '../../../services/heartbeat.service';
import {PowerValue} from '../../../shared/interfaces/power-value.interface';
import {absoluteValue} from '../../../lib/Util';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-live-details',
    templateUrl: './live-details.component.html',
    styleUrls: ['./live-details.component.scss'],
    viewProviders: []
})
export class LiveDetailsComponent extends BasePopover implements OnInit, OnDestroy, AfterViewInit {

    readonly abs = absoluteValue;

    private mockDataIntervalSub: Subscription = null;
    private current_demo_idx = 0;
    private mock_data_subscription: Subscription;

    private dateFormat = 'DD.MM.YYYY';
    private dateFormatDebug = 'DD.MM.YYYY hh:mm:ss';

    consumptionDiagramSeriesColor = '#1ea2b1';
    feedinDiagramSeriesColor = '#39393a';

    zoomLevels: Array<LiveZoomLevel> = liveDetailZoomLevels;
    currentZoomLevelIdx = 1;
    position = 1;

    disabled = true;
    currentConsumption = null;

    status = {trend: 0, noZone: true, since: null};

    currentDataset = [];

    // Day based data selection
    today = moment().format('DD.MM.YYYY');
    baseDate = null;
    lastStartDate = null;
    specificDateMode = false;
    specifiedDateDisplay = null;

    infoVisible = false;
    isNotRealtime = false;
    energySaverWarningCollapsed = true;
    energySaverWarningVisible = false;
    lastValueTimestamp = null;


    @ViewChild('liveChart', {static: true}) liveChart: LiveChartComponent;
    @ViewChild('dateInput', {static: true}) dateInput: ElementRef;

    constructor(public userService: UserService,
                protected popoverRef: PopoverRef,
                private mockDataService: MockDataService,
                private liveData: LiveDataService,
                private homeState: HomestateService,
                private application: ApplicationService,
                private electricity: ElectricityService,
                private router: Router,
                private opticalReader: OpticalReaderService,
                private heartbeat: HeartbeatService,
                private translate: TranslateService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.liveChart.setUnit('Watt');
        this.baseDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        this.translateZoomLevels();
    }

    translateZoomLevels(): void {
        this.zoomLevels = liveDetailZoomLevels.map(level => ({
            ...level,
            name: this.translate.instant(level.name),
            hint: this.translate.instant(level.hint),
            format: level.format.replace('{{timeUnit}}', this.translate.instant('screens.dashboard.liveDetailZoomLevels.timeUnit'))
        }));
    }

    ngAfterViewInit(): void {
        if (this.application.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        if (this.userService.isEDGUser()) {
            this.liveChart.updateZoomLevel(
                this.zoomLevels[this.zoomLevels.length - 1].resolution * 1000,
                this.zoomLevels[0].format
            );
            const s = this.opticalReader.onMeterReaderStatus.subscribe((res) => {
                this.isNotRealtime = res.mode === 'RT_INACTIVE' && res.battery_status > 0;
            });
            this.addSub(s);
        }

        this.initializePowerBasedApiConnection();

        // set user stored zoom level
        const zoomlevel = this.userService.getLiveDetailZoomLevel();
        this.setZoom(zoomlevel, true);
    }

    ngOnDestroy() {
        if (this.mockDataIntervalSub) {
            this.mockDataIntervalSub.unsubscribe();
            this.mockDataIntervalSub = null;
        }
        if (this.mock_data_subscription) {
            this.mock_data_subscription.unsubscribe();
            this.mock_data_subscription = null;
        }
        this.heartbeat.destroy();
        super.ngOnDestroy();
    }

    onDatePickerDateChange(selectedDate: Date): void {
        const dParsed = moment(selectedDate).format('YYYY-MM-DD');
        this.onDateChange(dParsed);
        this.resetChart();
    }

    onDateChange(value) {
        this.baseDate = moment(value).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        this.specificDateMode = true;
        this.position = 0;
        this.requestConsumptionForDate();
    }

    zoomIn() {
        if (this.specificDateMode) {
            this.position = 0;
            this.baseDate = this.lastStartDate;
        }
        this.setZoom(this.currentZoomLevelIdx - 1);
    }

    zoomOut() {
        if (this.specificDateMode) {
            this.position = 0;
            this.baseDate = this.lastStartDate;
        }
        this.setZoom(this.currentZoomLevelIdx + 1);
    }

    setZoom(level: number, skipChecks = false) {
        if (!skipChecks) {
            if (this.disabled) {
                return;
            }
            if (level === this.currentZoomLevelIdx) {
                return;
            }
        }

        const zoom = this.zoomLevels.filter((item: any) => item.level === level);
        if (zoom.length < 1) {
            return;
        }
        this.currentZoomLevelIdx = level;
        this.liveChart.updateZoomLevel(zoom[0].resolution * 1000, zoom[0].format);
        this.userService.setLiveDetailZoomLevel(level);
        this.resetChart();
    }

    stepForward() {
        if (this.specificDateMode) {
            this.position--;
            this.requestConsumptionForDate();
            return;
        }

        if ((this.position > 1) && (!this.disabled)) {
            this.position--;
            this.resetChart();
        }
    }

    stepBack() {
        if (this.specificDateMode) {
            ++this.position;
            this.requestConsumptionForDate();
            return;
        }

        if (!this.disabled) {
            this.position++;
            this.resetChart();
        }
    }

    resetPosition() {
        if (!this.disabled) {
            this.position = 1;
            this.specificDateMode = false;
            this.resetChart();
        }
    }

    resetChart() {
        this.liveChart.reset();
        this.disabled = true;

        if (this.application.isDemoMode()) {
            this.current_demo_idx = 0;
            this.getMockConsumption();
            return;
        }

        if (this.specificDateMode) {
            this.requestConsumptionForDate();
        } else {
            this.getLiveConsumption();
        }
    }

    toggleWarning(): void {
        this.energySaverWarningCollapsed = !this.energySaverWarningCollapsed;
    }

    hideWarning(): void {
        this.energySaverWarningVisible = false;
    }

    routeToSettings(): void {
        this.close();
        this.router.navigate(['einstellungen']);
    }

    currentConsumptionFormatted(): string {
        return Math.abs(this.currentConsumption).toLocaleString('de-DE');
    }

    /**
     * Initialize live api connections
     */
    private initializePowerBasedApiConnection(): void {
        this.getLiveConsumption();

        // current consumption value
        const liveDataConsumptionSub = this.liveData.onLiveConsumptionReceived.subscribe({
            next: (res) => {
                if (!res) {
                    return;
                }
                const data = res.results;
                if (data.length === 0) {
                    return;
                }
                const filtered = this.filterValuesForTimeframe(data);
                // find last value
                const fiveMinCopy = filtered.slice().reverse();
                let latest = fiveMinCopy.find((el) => 'power' in el);
                if (latest) {
                    this.determineCurrentConsumption(latest.power);
                    this.lastValueTimestamp = null;
                } else {
                    const fullHourCopy = data.slice().reverse();
                    latest = fullHourCopy.find((el) => 'power' in el);
                    if (latest) {
                        this.determineCurrentConsumption(latest.power);
                        const tsFormat = 'DD.MM.YYYY HH:MM';
                        this.lastValueTimestamp = moment(latest.timestamp).format(tsFormat);
                    } else {
                        this.currentConsumption = '--';
                    }
                }
            }
        });
        this.addSub(liveDataConsumptionSub);
        this.liveData.startCurrentConsumptionUpdate(true);


        const liveDataFilteredSub = this.liveData.onFilteredLiveConsumptionReceived.subscribe(
            (res) => {
                if (!this.specificDateMode) {
                    this.handleLiveDataResponse(res);
                }
                this.disabled = false;
            },
            (error) => {
                console.warn('Error:', error);
                this.liveChart.showLoadingState();
                this.disabled = false;
            }
        );
        this.addSub(liveDataFilteredSub);
        this.liveData.startFilteredLiveConsumptionUpdate();


        this.initializeHomestateStatusUpdate();
    }

    /**
     * Initialize homestate status update
     */
    private initializeHomestateStatusUpdate(): void {
        const homeS = this.homeState.onHomestateInfo.subscribe(
            (res) => {
                if (res) {
                    this.handleHomestateStatusResponse(res.status);
                }
            },
            (error) => {
                console.log('Error in retrieving homestate:', error);
            }
        );
        this.addSub(homeS);
        this.homeState.startLiveUpdateForBundledInfo();
    }


    /**
     * Handle Homestate Status response
     * @param data
     */
    private handleHomestateStatusResponse(data): void {
        if (data.current_zone === 4) {
            this.status.noZone = true;
            return;
        }
        this.determineStatus(data);
    }


    /**
     * Filter Values for a specific timeframe
     * @param values
     */
    private filterValuesForTimeframe(values: Array<PowerValue>): any {
        const start = moment().subtract(5, 'minutes');
        return values.filter((el) => {
            const ts = moment(el.timestamp);
            return ts >= start && ts <= moment();
        });
    }


    /**
     * Handle the live data response
     * @param response
     */
    private handleLiveDataResponse(response: any): void {
        let results: any;
        try {
            results = response.results;
            if (results.length < 1) {
                throw Error();
            }
        } catch (e) {
            this.liveChart.removeSeries(0, 2);
            this.liveChart.showLoadingState();
            return;
        }

        this.currentDataset = results;

        const center = results[Math.floor(results.length / 2)];
        this.specifiedDateDisplay = moment(center.timestamp).format(this.dateFormat);

        this.updateChartWithData(results);

        this.liveChart.showLoadingState(false);
        this.disabled = false;
    }


    private getLiveConsumption() {
        const zoom = this.zoomLevels.filter((item: any) => item.level === this.currentZoomLevelIdx);

        const offset = zoom[0].offset * (this.position);
        const limit = offset - zoom[0].offset;
        const interval = zoom[0].interval;

        this.liveData.setLiveValues(offset, limit, interval, 0);
    }


    private determineCurrentConsumption(value): void {
        const diff = value - Math.floor(value);
        if (diff < 0.5) {
            this.currentConsumption = Math.floor(value);
        } else {
            this.currentConsumption = Math.ceil(value);
        }
        const currentConsumptionFixed = this.currentConsumption.toFixed(0);
        this.currentConsumption = parseInt(currentConsumptionFixed, 10);
    }


    private determineStatus(data) {
        this.status.noZone = false;
        this.status.trend = data.current_zone;
        this.status.since = determineDurationPassed(data.since, this.translate);
    }


    private requestConsumptionForDate() {
        const zoom = this.zoomLevels.filter(
            (item: any) => item.level === this.currentZoomLevelIdx)[0];

        // position needs to be negated to match the desired movement direction
        this.lastStartDate = moment(this.baseDate)
            .add(-this.position * zoom.offset, 'minutes');
        const to = moment(this.lastStartDate)
            .add(zoom.offset, 'minutes');

        const liveDataSub = this.liveData.getLiveDataAlt(
            this.lastStartDate, to.toDate(), zoom.interval, false).subscribe(
            (res) => {
                if (res) {
                    this.handleLiveDataResponse(res);
                }
                liveDataSub.unsubscribe();
            },
            (error) => {
                console.log('Error on live-data subscription: ', error);
                this.liveChart.removeSeries(0, 2);
                this.liveChart.showLoadingState();
            },
        );

        return;
    }


    private updateChartWithData(dataset: Array<PowerValue>): void {
        const fedEnergy = [];
        for (const el of dataset) {
            const element = {
                power: el.power < 0 ? Math.abs(el.power) : null,
                timestamp: el.timestamp
            };
            fedEnergy.push(element);
        }
        const consumedEnergy = [];
        for (const el of dataset) {
            const element = {
                power: el.power >= 0 ? el.power : null,
                timestamp: el.timestamp
            };
            consumedEnergy.push(element);
        }
        this.currentDataset = dataset;
        this.liveChart.showLoadingState(false);
        this.liveChart.addNewSeries(
            consumedEnergy,
            'power',
            2,
            {zindex: 1, isTileChart: true, color: this.consumptionDiagramSeriesColor}
        );
        this.liveChart.addNewSeries(
            fedEnergy,
            'power',
            0,
            {zindex: 2, isTileChart: true, color: this.feedinDiagramSeriesColor}
        );
    }

    /**
     * --- MOCK DATA -------------------------------------------------------------------------------
     */
    private initializeMockData(): void {
        this.mockDataIntervalSub = timer(0, 10000).subscribe(
            () => {
                this.getMockConsumption();
                this.getMockHomestateStatus();
            }
        );
    }

    private getMockConsumption() {
        const zoom = this.zoomLevels.filter((item: any) => item.level === this.currentZoomLevelIdx);

        const offset = zoom[0].offset * this.position;
        const limit = offset - zoom[0].offset;
        const interval = zoom[0].interval;
        const level = zoom[0].level;

        const s = this.mockDataService.getLiveData(offset, level, limit, interval).subscribe(
            (values: any) => {
                if (values.length > 0) {
                    this.updateChartWithData(values);
                    this.disabled = false;
                    this.determineCurrentConsumption(values.last().power);
                } else {
                    this.currentConsumption = '0';
                    this.liveChart.showLoadingState();
                    this.disabled = false;
                }
            },
            () => {
                this.liveChart.showLoadingState();
                this.disabled = false;
            }
        );
        this.addSub(s);
    }

    private getMockHomestateStatus(): void {
        this.mockDataService.getHomeStateStatus().subscribe((data) => {
            this.determineStatus(data.data);
        });
    }

}
