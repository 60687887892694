<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="{{ 'common.menu' | translate }}" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text">{{ 'common.menu' | translate }}</span>
        </a>
        <app-menu activeElement="profile"></app-menu>
    </aside>

    <div id="inner">
        <main class="page">

            <div class="page-card">

                <h1>{{ 'common.myData' | translate }}</h1>
                <section>
                    <h2>{{ 'screens.profile.personalDataHeading' | translate }}</h2>
                    <div class="row">
                        <div>
                            <strong>{{ 'screens.profile.personalDataRealName' | translate }}</strong><br>
                            <strong>{{ 'screens.profile.personalDataUsername' | translate }}</strong>
                        </div>
                        <div>
                            {{ contract.name }} <br>
                            {{ determineDisplayUsername() }}
                        </div>
                    </div>

                    <div class="m-v-l">
                        <button class="eon-button-new"
                                (click)="openChangeEmailPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{ 'screens.profile.emailChange' | translate }}
                        </button>
                    </div>

                    <div class="m-v-l">
                        <button class="eon-button-new"
                                (click)="openResetPasswordPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{ 'screens.profile.changePasswordButton' | translate }}
                        </button>
                    </div>

                    <div class="m-v-l" *ngIf="!application.isDemoMode()">
                        <button class="eon-button-new"
                                (click)="enableDisableMFA()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{ mfaEnabled ? ('screens.profile.mfaDisableButton' | translate) : ('screens.profile.mfaEnableButton' | translate) }}
                        </button>
                    </div>
                </section>

                <section>
                    <h2>{{ 'screens.profile.providerHeading' | translate }}</h2>
                    <div class="row">
                        <div>
                            <strong>{{ 'screens.profile.providerProduct' | translate }}</strong><br>
                            <strong>{{ 'screens.profile.providerBaseprice' | translate }}</strong><br>
                            <strong>{{ 'screens.profile.providerWorkprice' | translate }}</strong>
                        </div>
                        <div>
                            {{ contract.tarif }} <br>
                            {{ contract.basicprice }} <br>
                            {{ contract.workingprice }}
                        </div>
                    </div>

                </section>

                <section>
                    <h2>{{ 'screens.profile.myEonTitle' | translate }}</h2>
                    <h3>{{ 'screens.profile.myEonSubtitle' | translate }}</h3>
                    <ul>
                        <li>{{ 'screens.profile.adjustContract' | translate }}</li>
                        <li>{{ 'screens.profile.adjustPayment' | translate }}</li>
                        <li>{{ 'screens.profile.viewInvoice' | translate }}</li>
                    </ul>
                    <button class="eon-button-new"
                            (click)="openLink()">
                        {{ 'screens.profile.myEonButton' | translate }}
                    </button>
                </section>

            </div>
        </main>
    </div>
</div>
