import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DetailBannerData} from '../../shared/interfaces/detail-banner.interfaces';


@Component({
    selector: 'app-detail-banner',
    templateUrl: './detail-banner.component.html',
    styleUrls: ['./detail-banner.component.scss']
})
export class DetailBannerComponent implements OnInit {

    @Input() bannerData: DetailBannerData;

    @Output() hideBanner = new EventEmitter<null>();
    @Output() bannerInteraction = new EventEmitter<string>();
    @Output() sideButtonInteraction = new EventEmitter<null>();


    constructor() {
    }


    ngOnInit(): void {
    }


    onCloseBanner() {
        this.hideBanner.emit();
    }


    onBannerInteraction(buttonId: string) {
        this.bannerInteraction.emit(buttonId);
    }


    onSideButtonInteraction(): void {
        if (this.bannerData.sideButton.isClose) {
            this.hideBanner.emit();
        }
        this.sideButtonInteraction.emit();
    }

}

