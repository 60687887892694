<section class="detail-banner">
    <div class="icon">
        <i class="info-sm dark-grey"></i>
    </div>
    <div class="banner-content">
        <ng-container *ngFor="let item of bannerData.paragraphs">
            <ng-container [ngSwitch]="item.type">
                <p *ngSwitchCase="'text'">{{item.text}}</p>
                <button class="eon-button-new small turquoise" *ngSwitchCase="'button'"
                        (click)="onBannerInteraction(item.buttonId)">
                    {{item.buttonText}}
                    <i class="chevron-right-s white"></i>
                </button>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="bannerData.sideButton.visible">
        <div class="close">
            <ng-container *ngIf="bannerData.sideButton.isClose ; else altSideBtn">
                <button (click)="onSideButtonInteraction()">
                    <i class="close-s dark-grey"></i>
                </button>
            </ng-container>
            <ng-template #altSideBtn>
                <button (click)="onSideButtonInteraction()">
                    <i class="chevron-right-s dark-grey"></i>
                </button>
            </ng-template>
        </div>
    </ng-container>
</section>
