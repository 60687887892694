import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { constants } from '../../shared/constants/constants';
import { UserService } from '../../services/user.service';
import { MeterService } from '../../services/meter.service';
import { ApplicationService } from '../../services/application.service';
import { VersionService } from '../../services/version.service';
import { BaseComponent } from '../../classes/base-component';
import { RegistrationService } from '../../services/registration.service';
import { InitializationService } from '../../services/initialization.service';
import { map, mergeMap } from 'rxjs/operators';
import { OpticalReaderService } from '../../services/optical-reader.service';
import { SmartBridgeService } from '../../services/smart-bridge.service';
import { Popover } from '../../popovers/popover/popover.service';
import { EnergySaverControlComponent } from '../../popovers/energy-saver-control/energy-saver-control.component';
import { ActivatedRoute } from '@angular/router';
import { TrackAnalyticsService } from '../../services/track-analytics.service';
import {PopoverConfigService} from '../../popovers/static.popover.config';
import { iif, Observable, of, throwError } from 'rxjs';
import { LocalOptInService } from '../../services/local-opt-in.service';
import { StorageAttributes } from '../../shared/constants/storage-attributes.constants';
import { BenchmarkService } from '../../services/benchmark.service';
import { OptInService } from '../../services/opt-in.service';
import { MatDialog } from '@angular/material/dialog';
import { LanguageModalComponent } from '../../components/language-modal/language-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'iona-app',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent extends BaseComponent implements OnInit, OnDestroy {

    config = {
        optIn: false,
        householdComparisonOptOut: true,
        dataOptIn: false
    };

    wifiConnectionQuality = 0;
    wifiConnected = 0;
    lanConnected = false;
    meterConnectionQuality = 0;
    meterStatus = 'disconnected';
    pincode = null;

    deviceIsPlug = false;
    isBox2 = false;

    isEDGUser = false;
    currentBatteryState = 0;
    currentEnergySaverThreshold = 1;
    pinEntryUnknownOrOptical = false;

    constructor(
        public application: ApplicationService,
        private pageTitle: Title,
        private notificationService: ToastrService,
        private userService: UserService,
        private meterService: MeterService,
        private versionService: VersionService,
        private registrationService: RegistrationService,
        private initializationService: InitializationService,
        private analyticsService: TrackAnalyticsService,
        private opticalReader: OpticalReaderService,
        private smartBridge: SmartBridgeService,
        private popover: Popover,
        private route: ActivatedRoute,
        private localOptInService: LocalOptInService,
        private benchmarkService: BenchmarkService,
        private optInService: OptInService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private popoverConfigService: PopoverConfigService
    ) {
        super();
    }


    ngOnInit() {
        this.initComponent();
    }


    ngOnDestroy() {
    }

    openLanguageModal(): void {
        const dialogRef = this.dialog.open(LanguageModalComponent, {
            width: '',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {});
    }


    /**
     * Open the pin entry overlay
     */
    onPinEntryOpen(): void {
        const config = this.popoverConfigService.getPinEntryPopoverConfig();
        delete config.data.hasSkip;
        if (this.pincode) {
            config.data.text += ` ${this.translate.instant('screens.settings.currentPin', { pincode: this.pincode })}`;
        }
        this.popover.open(config).afterClosed$.pipe(
            mergeMap((value: any) => iif(
                () => value.data,
                this.meterService.putOpticalReaderPin(value.data),
                of(false)
            ))
        ).subscribe(res => {
        });
    }


    /**
     * Open the energy-saver overlay
     */
    onEnergySaverOpen(): void {
        this.popover.open({
            content: EnergySaverControlComponent,
            data: { threshold: this.currentEnergySaverThreshold },
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe((res) => {
        });
    }

    /**
     * On Optin Checkbox change update the current value
     */
    onOptInCheckboxChange(newValue: boolean): void {
        this.config.optIn = newValue;
    }


    /**
     * Set the opt-out settings for the household comparison feature.
     */
    setHouseholdComparisonOptOut(value: boolean) {
        this.config.householdComparisonOptOut = value;
    }


    setDataOptinCheckBox(value: boolean): void {
        this.config.dataOptIn = value;
    }


    /**
     * Determines the status message for the meter connection
     */
    determineMeterStatusMessage(): string {
        switch (this.meterStatus) {
            case 'connected':
                return this.translate.instant('common.connected');
            default:
                return this.translate.instant('common.notConnected');
        }
    }


    /**
     * Saves opt in settings made
     */
    saveSettings() {
        if (this.application.isDemoMode()) {
            this.notificationService.info(this.translate.instant('common.demoModeInfo'));
            return;
        }
        this.registrationService.optInDevice(this.config.optIn).subscribe(
            res => null
        );

        this.registrationService.getOptInStatus().subscribe(res => {
            if (!res) {
                localStorage.setItem(StorageAttributes.OPT_IN_REQUIRED, '0');
                this.localOptInService.checkStatus();
            }
        });

        this.optInService.setAnalyticsOptIn(this.config.dataOptIn).subscribe({
            next: (res) => {
                console.log(res);
            }
        });

        this.setBenchmarkingOptOut();

        this.notificationService.success(this.translate.instant('screens.settings.settingsSaved'));
    }


    /**
     * Base component initialization
     */
    private initComponent(): void {
        this.translate.get('screens.settings.pageTitle').subscribe((title: string) => {
            this.pageTitle.setTitle(title);
        });
        this.setupOptInHandling();

        this.deviceIsPlug = this.userService.getUserDevice() === constants.application.devices.plug;
        this.isBox2 = this.userService.getUserDevice() === constants.application.devices.smart_box;
        this.isEDGUser = this.userService.isEDGUser();

        this.addSub(this.initializationService.getMeterConnectionInfo()
            .subscribe(status => this.meterStatus = status));

        this.setupMeterStatusHandling();

        if (this.isEDGUser) {
            this.getOpticalReaderBatteryStatus();
        }

        this.listenForRouteChanges();

        // benchmark optout
        this.benchmarkService.getOptOutSetting().subscribe({
            next: (res) => {
                this.config.householdComparisonOptOut = !res;
            },
        });

        // data optin
        this.optInService.getAnalyticsOptIn().subscribe({
            next: (enabled) => {
                this.config.dataOptIn = enabled;
            }
        });
    }


    private setupMeterStatusHandling(): void {
        // extract wifi connection quality
        this.meterService.startLiveUpdate();
        this.meterService.getStatus().pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(
            result => this.handleMeterWifiValue(result),
            error => null,
            () => null
        );
        this.addSub(this.meterService.onMeterStatus.pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(
            result => this.handleMeterWifiValue(result),
            error => null,
            () => null
        ));

        if (!this.isEDGUser) {
            return;
        }

        this.addSub(this.registrationService.getOnline().pipe(
            mergeMap((res) => {
                if (!this.isEDGUser) {
                    return this.meterService.getStatus().pipe(
                        mergeMap(meterResponse =>
                            of({ meterReaderResponse: null, meterResponse }))
                    );
                }
                return this.opticalReader.getOpticalReaderStatus(true);
            }),
            map((results: any) => {
                this.meterConnectionQuality =
                    ('meter_txrssi' in results) ? results.meter_txrssi : 0;
                try {
                    this.pinEntryUnknownOrOptical =
                        results.electricity.smartreader.pin_entry_mode === 'optical' ||
                        results.electricity.smartreader.pin_entry_mode === 'unknown';
                } catch (e) {
                    return null;
                }
                return null;
            })).subscribe((res) => null));
    }


    /**
     * Fetch optical reader battery status
     */
    private getOpticalReaderBatteryStatus(): void {
        this.opticalReader.getOpticalReaderStatus().subscribe((res) => {
            this.currentBatteryState = res?.battery_status;
            this.meterConnectionQuality = res?.connection_quality;
            this.pincode = res?.pincode;
        });
    }


    /**
     * Listen for changes on the current component route
     * if the field 'open' container 'energy-saver'
     * directly open the energy saver configuration overlay
     */
    private listenForRouteChanges(): void {
        this.route.params.subscribe((params) => {
            if (!('open' in params)) {
                return;
            }
            if (params.open === 'energy-saver') {
                this.onEnergySaverOpen();
            }
        });
    }


    /**
     * Setup handling for the users online data mode optin
     */
    private setupOptInHandling(): void {
        this.localOptInService.checkStatus();

        const s = this.localOptInService.onDialogSettingsChange.subscribe(result =>
            this.config.optIn = result
        );
        this.addSub(s);

        this.registrationService.getOptInStatus().subscribe(
            res => this.config.optIn = res);
    }


    /**
     * Extract the meters wifi connection rssi value
     * @param response
     * @private
     */
    private extractMeterWifiConnection(response): Observable<number> {
        try {
            return of(response.wlan_rssi);
        } catch (e) {
            return throwError(e);
        }
    }

    /**
     * Handle the latest meter wifi rssi connection quality value
     * @private
     */
    private handleMeterWifiValue(value: number): void {
        this.wifiConnectionQuality = value;
        this.wifiConnected = this.wifiConnectionQuality;
        if (this.wifiConnectionQuality === 0) {
            this.lanConnected = true;
        }
    }


    /**
     * Sets benchmarking optout based on the current value.
     * @private
     */
    private setBenchmarkingOptOut() {
        // benchmark optin
        if (!this.config.householdComparisonOptOut) {
            this.benchmarkService.setOptOutSetting().subscribe({
                next: (res) => {
                }
            });
        } else {
            this.benchmarkService.deleteOptOutSetting().subscribe({
                next: (res) => {
                }
            });
        }
    }
}
