<div class="component-container">
    <div class="svg-container">
        <svg #svgElement></svg>
        <div class="hh-indicator"
             [ngClass]="{'hh-active': active, 'hh-active-inv': invertedActive}"
        ></div>
    </div>
    <div class="m-h-m label" [ngClass]="{'muted': state !== 'running'}">
        <ng-container [ngSwitch]="state">
            <span *ngSwitchCase="'not-started'">
                <strong>Happy Hour</strong> {{message}}
            </span>
            <span *ngSwitchCase="'running'">
                <strong>Happy Hour</strong> {{message}}
            </span>
            <span *ngSwitchCase="'done'">
                <strong>Happy Hour</strong> {{message}}
            </span>
        </ng-container>
    </div>
</div>
