<div class="container" [class.for-header]="forHeader">
    <div class="title" *ngIf="!forHeader">
        {{title}}
    </div>
    <div class="frame" [class.for-header]="forHeader">
        <div class="icon" [class.for-header]="forHeader">
            <span [class]="icon"></span>
        </div>
        <div class="digits" [class.for-header]="forHeader">
            <div *ngFor="let digit of padNumberInternal(value, 6).split('')"
                 class="digit" [class.inactive]="digit === '–'">
                {{digit}}
            </div>
        </div>
        <div class="unit" [class.for-header]="forHeader">
            {{unit}}
        </div>
    </div>
</div>
