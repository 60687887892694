import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'todayValueFormat'
})
export class TodayValueFormatPipe implements PipeTransform {

    transform(value: number, ...args: unknown[]): string {
        if (value === 0.0) {
            return '–';
        }
        const ret = value.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        );
        return ret.trim();
    }

}
