<div class="interaction-space">
    <button class="eon-icon-button small turquoise"
            [disabled]="value === 0"
            (click)="lowerValue()">
        <i class="minus-s white"></i>
    </button>

    <div class="value">
        <div> {{value}} </div>
    </div>

    <button class="eon-icon-button small turquoise"
            (click)="raiseValue()">
        <i class="plus-s white"></i>
    </button>
</div>

