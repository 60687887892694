<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="{{ 'common.menu' | translate }}" class="mobi">
        <span class="burger">
            <i class="top"></i>
            <i class="middle"></i>
            <i class="bottom"></i>
        </span>
            <span class="text"> {{ 'common.menu' | translate }}</span>
        </a>

        <app-menu activeElement="household"></app-menu>
    </aside>
    <div id="inner">

        <main class="page">
            <div class="page-card page-card-override">
                <div>
                    <h1>{{ 'screens.household.title' | translate }}</h1>
                    <strong>{{ 'screens.household.greeting' | translate }} {{ fullname }},</strong>
                    <p>{{ 'screens.household.subtitle' | translate }}</p>
                </div>
                <section>
                    <p class="teaser">{{ propertyType.title }}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="propertyType.selectedValue; else typeEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #typeEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of propertyType.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="propertyType.selectedValue">

                                    <div class="item-image">
                                        <img [src]="determineImagePath(propertyType, item)">
                                    </div>

                                    <div class="item-label">
                                        {{ item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{ propertySize.title }}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="propertySize.selectedValue; else sizeEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #sizeEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of propertySize.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="propertySize.selectedValue">

                                    <div class="item-image">
                                        <img [src]="determineImagePath(propertySize, item)">
                                    </div>

                                    <div class="item-label">
                                        {{ item.title }}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{propertyAge.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="propertyAge.selectedValue; else ageEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #ageEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of propertyAge.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="propertyAge.selectedValue">

                                    <div class="item-image">
                                        <img [src]="determineImagePath(propertyAge, item)">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <p class="teaser">{{occupants.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="occupants.selectedValue; else occEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #occEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of occupants.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="occupants.selectedValue">

                                    <div class="item-image">
                                        <img [src]="determineImagePath(occupants, item)">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{spaceHeating.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="spaceHeating.selectedValue; else spaceEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #spaceEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of spaceHeating.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="spaceHeating.selectedValue">

                                    <div class="item-image">
                                        <img [src]="determineImagePath(spaceHeating, item)">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{waterHeating.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="waterHeating.selectedValue; else waterEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #waterEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of waterHeating.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="waterHeating.selectedValue">

                                    <div class="item-image">
                                        <img [src]="determineImagePath(waterHeating, item)">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{photovoltaic.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="photovoltaic.selectedValue; else photovoltaicEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #photovoltaicEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of photovoltaic.items">
                                <label class="category-item">
                                    <input type="radio" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="photovoltaic.selectedValue">

                                    <div class="item-image">
                                        <img [src]="determineImagePath(photovoltaic, item)">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <p class="teaser">{{appliances.title}}</p>
                    <div class="row">
                        <div class="section-status">
                            <ng-container *ngIf="determineAppliancesSelected(); else appliancesEmpty">
                                <img src="/assets/img/icons/check/check.svg">
                            </ng-container>
                            <ng-template #appliancesEmpty>
                                <img src="/assets/img/icons/empty/empty.svg">
                            </ng-template>
                        </div>
                        <div class="grow-space category-container">
                            <div *ngFor="let item of appliances.items">
                                <label class="category-item">
                                    <input type="checkbox" name="houseType"
                                           [value]="item.iconName" [(ngModel)]="item.selected">

                                    <div class="item-image">
                                        <img [src]="determineApplianceImagePath(appliances, item)">
                                    </div>

                                    <div class="item-label">
                                        {{item.title}}
                                    </div>
                                </label>

                                <div class="item-input">
                                    <app-count-selector [hidden]="!item.selected"
                                                        [(ngModel)]="item.count"
                                                        (ngModelChange)="onCountChanged(item, $event)">
                                    </app-count-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <button class="eon-button-new"
                            (click)="saveSettings()">
                        {{ 'screens.household.buttonSave' | translate }}
                    </button>
                </section>

            </div>
        </main>
    </div>
</div>
