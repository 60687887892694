import {ViewState} from '../enums/view-state.enum';


export interface TodayData {
    today: SingleDayComparisonData;
    comparisonDate: SingleDayComparisonData;
    trend?: TodayTrend;
    hourlyData?: SingleHourData[];
    leftState?: 'active' | 'higher' | 'inactive';
    rightState?: 'active' | 'higher' | 'inactive';
    viewState?: ViewState;
}


export interface SingleDayComparisonData {
    consumption: number;
    costs: number;
    hours: SingleHourData[];
    date?: Date;
}


export interface SingleHourData {
    consumption: number;
    costs: number;
    hour: number;
}


export interface TodayTrend {
    direction: number;
    percentage: number;
    scale: {
        left: number
        right: number
    };
}


export const initialTodayData: TodayData = {
    today: {
        consumption: 0,
        costs: 0,
        hours: [],
        date: new Date(),
    },
    comparisonDate: {
        consumption: 0,
        costs: 0,
        hours: [],
        date: new Date(),
    },
    trend: {
        direction: 0,
        percentage: 0,
        scale: {
            left: 0,
            right: 0
        }
    },
    viewState: ViewState.INITIAL
};
