import {Component, OnInit} from '@angular/core';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import {
    HouseholdComparisonFilterDisplayComponent
} from '../household-comparison-filter-display/household-comparison-filter-display.component';
import {
    HouseholdComparisonFilterFormComponent
} from '../household-comparison-filter-form/household-comparison-filter-form.component';


@Component({
    selector: 'app-household-comparison-detail-sidebar',
    templateUrl: './household-comparison-detail-sidebar.component.html',
    styleUrls: ['./household-comparison-detail-sidebar.component.scss']
})
export class HouseholdComparisonDetailSidebarComponent implements OnInit {

    selectedPortal: Portal<any>;

    filterFormPortal = new ComponentPortal(HouseholdComparisonFilterFormComponent);
    filterDisplayPortal = new ComponentPortal(HouseholdComparisonFilterDisplayComponent);


    constructor() {
    }


    ngOnInit(): void {
        this.initializeView();
    }


    /**
     * Called when a component is attached to the portal.
     * @param ref
     */
    onComponentAttachedToPortal(ref: any): void {
        const instance = ref.instance;
        if (instance instanceof HouseholdComparisonFilterFormComponent) {
            instance.formSubmitted.subscribe((finished) => {
                if (finished) {
                    this.selectedPortal = this.filterDisplayPortal;
                }
            });
        }
        if (instance instanceof HouseholdComparisonFilterDisplayComponent) {
            instance.enterFormMode.subscribe((enterFormMode) => {
                if (enterFormMode) {
                    this.selectedPortal = this.filterFormPortal;
                }
            });
        }
    }


    /**
     * Initializes the view.
     * @private
     */
    private initializeView(): void {
        this.selectedPortal = this.filterDisplayPortal;
        // this.selectedPortal = this.filterFormPortal;
    }

}
