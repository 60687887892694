<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">
                        {{LABELS.TITLE}}
                    </h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Heute"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-s dark-grey"></i>
                    </button>
                </header>

                <section>
                    <div class="today-content">
                        <ng-container *ngIf="todayDetailData$ | async as detailData">
                            <ng-container [ngSwitch]="detailData.viewState">
                                <ng-container *ngSwitchDefault>
                                    <div class="today-visualization grow-space">
                                        <div class="pie-wrapper">
                                            <div class="today-pie left {{ detailData.leftState }}"
                                                 [halfCircle]="'left'"
                                                 [style.transform]="'scale(' + detailData.trend.scale.left + ')'">
                                            </div>
                                            <div class="today-pie right {{ detailData.rightState }}"
                                                 [halfCircle]="'right'"
                                                 [style.transform]="'scale(' + detailData.trend.scale.right + ')'">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="today-stats-wrapper">
                                        <div class="today-stats">
                                            <div>
                                                <div class="day m-b-s">
                                                    <div>
                                                        <app-date-picker-button
                                                            [displaySelectedDate]="true"
                                                            [currentDate]="detailData.comparisonDate.date"
                                                            (dateChanged)="onComparisonDateChange($event)">
                                                        </app-date-picker-button>
                                                    </div>
                                                </div>
                                                <div class="eon-bold cost m-b-s">
                                                    {{ detailData.comparisonDate.costs | todayValueFormat }}
                                                    <small>
                                                        {{ LABELS.UNIT_COST }}
                                                    </small>
                                                </div>
                                                <div class="eon-bold cost m-b-s">
                                                    {{ detailData.comparisonDate.consumption| todayValueFormat }}
                                                    {{ LABELS.UNIT_CONSUMPTION }}
                                                </div>
                                            </div>
                                            <div [class.active]="detailData.trend.direction < 0"
                                                 [class.warn]="detailData.trend.direction > 0">
                                                <div class="eon-regular day right m-b-s">
                                                    {{ LABELS.LABEL_COMPARISON_TODAY }}
                                                </div>
                                                <div class="eon-bold cost m-b-s">
                                                    {{ detailData.today.costs | todayValueFormat }}
                                                    <small>{{ LABELS.UNIT_COST }}</small>
                                                </div>
                                                <div class="eon-bold cost m-b-s">
                                                    {{ detailData.today.consumption| todayValueFormat }}
                                                    {{ LABELS.UNIT_CONSUMPTION }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="today-stats-summary eon-regular">
                                            <ng-container
                                                *ngIf="detailData.trend.percentage > 0 && detailData.trend.percentage < 9999">
                                                <div class="icon"
                                                     [class.icon-thumb]="detailData.trend.direction < 0"
                                                     [class.icon-alert]="detailData.trend.direction > 0">
                                                </div>
                                                <strong>
                                                    {{ detailData.trend.percentage }}
                                                    {{ LABELS.LABEL_FRAGMENT_PERCENT }}
                                                    {{ detailData.trend.direction > 0 ? LABELS.MORE : LABELS.LESS }}
                                                    &nbsp;
                                                </strong>
                                                <ng-container *ngIf="!dateChanged">
                                                        <span>
                                                            {{ LABELS.LABEL_FRAGMENT_LAST }}
                                                            {{ detailData.comparisonDate.date | nameOfDay }}
                                                        </span>
                                                </ng-container>
                                                <ng-container *ngIf="dateChanged">
                                                        <span>
                                                            {{ LABELS.LABEL_FRAGMENT_COMPARED_TO }}
                                                            {{ detailData.comparisonDate.date | date: 'dd.MM.yyyy' }}
                                                        </span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="detailData.trend.percentage === 0">
                                                <div class="icon icon-thumb"></div>
                                                <strong>{{ LABELS.LABEL_FRAGMENT_IDENTICAL }}</strong>
                                                &nbsp;
                                                <ng-container *ngIf="!dateChanged">
                                                    <span>
                                                        {{ LABELS.LABEL_FRAGMENT_TO_LAST}}
                                                        {{ detailData.comparisonDate.date | nameOfDay }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="dateChanged">
                                                    <span>
                                                        {{ LABELS.LABEL_FRAGMENT_TO}}
                                                        {{ detailData.comparisonDate.date | date: 'dd.MM.yyyy' }}
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>

                                </ng-container>
                                <ng-container *ngSwitchCase="ViewState.ERROR">
                                    <div class="center-contents">
                                        {{LABELS.LABEL_ERROR_STATE}}
                                    </div>
                                </ng-container>
                            </ng-container>

                        </ng-container>


                    </div>

                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <h2 class="eon-black">{{TEXTS.INFO.TITLE}}</h2>
                    <p *ngFor="let p of TEXTS.INFO.PARAGRAPHS">
                        {{p}}
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <div class="header-row">
                    <h3>{{LABELS.OVERVIEW_HOURS}}</h3>
                    <button class="toggle-button"
                            [class.activated]="showEntireList"
                            [class.deactivated]="!showEntireList"
                            (click)="toggleEntireListDisplay()"></button>
                </div>

                <ng-container *ngIf="todayDetailData$ | async as detailData">
                    <ng-container [ngSwitch]="detailData.viewState">
                        <ng-container *ngSwitchDefault>
                            <ul class="hours-list">
                                <li *ngFor="let hour of detailData.today.hours; index as i">
                                    <div>
                                        <div class="list-time">
                                            {{ hour.hour }} {{ LABELS.HOUR_START_SUFFIX }}
                                            {{ LABELS.TIME_DIVIDER }}
                                            {{ hour.hour }} {{ LABELS.HOUR_END_SUFFIX }}
                                            {{ LABELS.TIME_SUFFIX }}
                                        </div>
                                        <div class="list-date">
                                            {{LABELS.LIST_TODAY}}
                                        </div>
                                        <div class="list-consumption">
                                            {{ hour.consumption | todayValueFormat}}
                                            {{LABELS.UNIT_CONSUMPTION}}
                                        </div>
                                        <div class="list-cost">
                                            {{ hour.costs | todayValueFormat }}
                                            {{LABELS.UNIT_COST}}
                                        </div>
                                    </div>
                                    <div class="comparison-hours">
                                        <div class="list-time"></div>
                                        <div class="list-date">
                                            {{ detailData.comparisonDate.date | date: 'dd.MM.yyyy' }}
                                        </div>
                                        <div class="list-consumption">
                                            {{detailData.comparisonDate.hours[i].consumption | todayValueFormat}}
                                            {{LABELS.UNIT_CONSUMPTION}}
                                        </div>
                                        <div class="list-cost">
                                            {{detailData.comparisonDate.hours[i].costs | todayValueFormat}}
                                            {{LABELS.UNIT_COST}}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.ERROR">
                            FEHLA
                        </ng-container>
                    </ng-container>

                </ng-container>

            </section>

        </div>
    </div>
</div>
