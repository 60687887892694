<div class="overlay-minimal">
    <div class="overlay-container font-regular">
        <h1>{{ 'popovers.energySaverControl.energySavingTitle' | translate }}</h1>
        <p>{{ 'popovers.energySaverControl.energySavingDescription' | translate }}</p>
        <ul>
            <li>{{ 'popovers.energySaverControl.energySavingModes.low' | translate }}</li>
            <li>{{ 'popovers.energySaverControl.energySavingModes.medium' | translate }}</li>
            <li>{{ 'popovers.energySaverControl.energySavingModes.high' | translate }}</li>
        </ul>

        <div class="slider-container">
            <span class="battery-empty-icon"></span>
            <app-eon-slider [value]="currentThreshold" [range]="'0 2'" [step]="1"
                            (onValueChange)="onSliderChange($event)">
            </app-eon-slider>
            <span class="battery-charged-icon"></span>
        </div>

        <div class="slider-labels eon-bold">
            <span>{{ 'common.zone.low' | translate }}</span>
            <span>{{ 'common.zone.medium' | translate }}</span>
            <span>{{ 'common.zone.high' | translate }}</span>
        </div>

    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
