import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {MeterReadingService} from '../../../services/meter-reading.service';
import {UserService} from '../../../services/user.service';
import {Popover} from '../../../popovers/popover/popover.service';
import {PopoverConfigService} from '../../../popovers/static.popover.config';
import {mergeMap, share, tap} from 'rxjs/operators';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ACTION} from '../../../lib/Action';
import {MeterDataProviderService} from '../../../services/meter-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {InternalMeterConnectionStatus} from '../../../shared/enums/meter-status.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-meter-details',
    templateUrl: './meter-details.component.html',
    styleUrls: ['./meter-details.component.scss'],
})

export class MeterDetailsComponent extends BasePopover implements OnInit, OnDestroy {
    protected readonly ViewState = ViewState;
    protected readonly InternalMeterConnectionStatus = InternalMeterConnectionStatus;

    LABELS;
    TEXTS;

    private passedAction: ACTION = null;

    private meterValueEntrySub: Subscription | null = null;
    private meterReadingsSub: Subscription | null = null;

    private currentMeterValue: number | null = null;

    today = new Date();

    infoVisible = false;
    showMeterValueReportInfo = false;
    hasFeedinData = false;


    meterData$ = this.meterDataProviderService.combinedMeterData$
        .asObservable().pipe(
            share(),
            tap((data) => {
                this.hasFeedinData = data.meterValues.feedin !== undefined;
                this.currentMeterValue = data.meterValues.offtake;
            })
        );

    dateSpecificMeterValues$ = this.meterDataProviderService.dateSpecificMeterData$
        .asObservable();


    constructor(
        protected popoverRef: PopoverRef,
        public userService: UserService,
        private application: ApplicationService,
        private meterReadings: MeterReadingService,
        private notification: ToastrService,
        private popover: Popover,
        private meterDataProviderService: MeterDataProviderService,
        private popoverConfigService: PopoverConfigService,
        private translate: TranslateService
    ) {
        super(popoverRef);
        this.passedAction = this.popoverRef.data;
    }


    ngOnInit() {
        this.initializeMeterReadingFeature();
        this.loadTranslations();
    }


    private loadTranslations(): void {
        this.translate.get('screens.dashboard.meterDetail.labels').subscribe((labels: any) => {
            this.LABELS = labels;
        });

        this.translate.get('screens.dashboard.meterDetail.texts').subscribe((texts: any) => {
            this.TEXTS = texts;
        });
    }

    ngOnDestroy() {
        if (this.meterValueEntrySub) {
            this.meterValueEntrySub.unsubscribe();
            this.meterValueEntrySub = null;
        }
        if (this.meterReadingsSub) {
            this.meterReadingsSub.unsubscribe();
            this.meterReadingsSub = null;
        }
    }


    /**
     * Determines if the meter is connected.
     * @param status
     */
    isMeterConnected(status: InternalMeterConnectionStatus): boolean {
        return status === InternalMeterConnectionStatus.CONNECTED;
    }


    /**
     * Handles the meter value entry pipeline.
     * Opens a popover to enter the new value, or since the current value is passed confirm it.
     */
    onMeterValueEntry(): void {
        const cfg: any = this.popoverConfigService.getEnterMeterValuePopoverConfig();
        cfg.data.value = this.currentMeterValue;

        this.meterValueEntrySub = this.triggerMeterValueEnterCycle(cfg).pipe(
            mergeMap((value) => this.meterReadings.sendNewMeterValue(value)),
        ).subscribe({
            next: (result) => {
                this.translate.get('common.info.meterValueSuccess').subscribe(translations => {
                    this.notification.info(
                        translations.title,
                        translations.message
                    );
                });
                this.initializeMeterReadingFeature();
            },
            error: error => {
                if (!error) {
                    return;
                }
                this.translate.get('common.errors.meterValueError').subscribe(translations => {
                    this.notification.error(
                        translations.title,
                        translations.message
                    );
                });
            }
        });
    }


    /**
     * Requests new values for the selected date.
     * @param date
     */
    onDatePickerDateChange(date: Date): void {
        this.meterDataProviderService.requestMeterValuesForDate(date);
    }


    /**
     * Determines the status message for the meter connection status.
     * @param status
     */
    determineMeterStatusMessage(status: InternalMeterConnectionStatus): string {
        switch (status) {
            case InternalMeterConnectionStatus.CONNECTED:
                return this.LABELS.CONNECTED;
            default:
                return this.LABELS.DISCONNECTED;
        }
    }


    /**
     * Initializes the meter readings feature
     * @private
     */
    private initializeMeterReadingFeature(): void {
        if (!this.userService.isMMEWMSBUser()) {
            return;
        }
        this.meterReadingsSub = this.meterReadings.getMeterReadings().pipe().subscribe({
            next: (res) => {
                if (!res) {
                    return;
                }
                this.showMeterValueReportInfo = res.length > 0;
            },
        });

    }


    /**
     * Triggers the meter value enter overlay cycle.
     * @param valueEntryPopover
     * @private
     */
    private triggerMeterValueEnterCycle(valueEntryPopover: any): Observable<any> {
        return this.popover.open(valueEntryPopover).afterClosed$.pipe(
            mergeMap((action) => {
                    return !action.data ? throwError(null) : of(+action.data);
                }
            ),
            mergeMap((enteredValue) => {
                const currentValue = this.currentMeterValue;
                const plausible =
                    enteredValue <= (currentValue * 2) && enteredValue >= (currentValue * 0.5);
                if (!plausible) {
                    const config = this.popoverConfigService.getMeterValueValidityCheckPopoverConfig();
                    return this.popover.open(config).afterClosed$.pipe(
                        mergeMap(result => {
                            if (!result.data) {
                                return this.triggerMeterValueEnterCycle(valueEntryPopover);
                            }
                            return (of(enteredValue));
                        })
                    );
                }
                return of(enteredValue);
            })
        );
    }
}
