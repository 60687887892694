import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {HttpClient} from '@angular/common/http';
import {of, from} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {environment} from '../../environments/environment';
import {InitializationService} from './initialization.service';
import {MeterService} from './meter.service';

@Injectable({
    providedIn: 'root'
})
export class UserGroupService extends BaseService {

    private readonly USER_GROUP_BASE_URL = environment.urls.userGroup;


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private initialization: InitializationService,
                private meterService: MeterService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
    }

    /**
     * Requests the current users user groups from the management API.
     * Needs to request meter serial number as prerequisite
     */
    async getUserGroupNew(): Promise<UserGroupResponse> {
        const url = `${this.USER_GROUP_BASE_URL}/${constants.api.routes.userGroup.groups}`;

        try {
            const initializationData = await from(this.initialization.getWithCache()).toPromise();

            let productName = null;
            if ('product_name' in initializationData) {
                productName = initializationData.product_name.toLowerCase();
            }

            if (productName) {
                const serialNumber = await this.meterService.getMeterSerialNumber().toPromise();

                if (!serialNumber) {
                    return {groups: []};
                }

                const params = {
                    userId: serialNumber,
                    labelPartnerId: this.user.getActiveUserProvider(),
                    productId: productName,
                };

                return this.http.get(url, {params}).pipe(
                    map((res: { status: string, data: any }) => this.mapDefault(res)),
                    catchError((e) => this.handleError(e))
                ).toPromise();
            } else {
                return {groups: []};
            }
        } catch (error) {
            return {groups: []};
        }
    }
}


export interface UserGroupResponse {
    groups: UserGroup[];
}


export interface UserGroup {
    description: string;
    name: string;
    parameters: {
        base_url: string;
        colors: {
            primary: string;
            text: string;
        }
        dashboardConfiguration: {
            position: number;
            size: string;
            forceAdd: boolean;
            version: number;
        }
        description: string;
        group: any;
        id: string;
        title: string;
    };
    valid_from: string;
    valid_until: string;
}

