<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="{{ 'common.menu' | translate }}" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text">{{ 'common.menu' | translate }}</span>
        </a>

        <app-menu activeElement="contact"></app-menu>
    </aside>

    <div id="inner">
        <main class="page">
            <div class="page-card">
                <h1>{{ 'common.contact' | translate }}</h1>
                <h2>{{ 'screens.contact.sectionTitle' | translate }}</h2>
                <section>
                    <h3>{{ 'screens.contact.sections.phone.title' | translate }}</h3>
                    <p>{{ 'screens.contact.sections.phone.description' | translate }}</p>
                    <button class="eon-button-new"
                            (click)="openPhone()">
                        <i class="phone-sm white m-r-s"></i> {{ 'screens.contact.sections.phone.buttonText' | translate }}
                    </button>
                </section>
                <section>
                    <h3>{{ 'screens.contact.sections.email.title' | translate }}</h3>
                    <p>{{ 'screens.contact.sections.email.description' | translate }}</p>
                    <button class="eon-button-new"
                            (click)="openMail()">
                        <i class="mail-sm white m-r-s"></i> {{ 'screens.contact.sections.email.buttonText' | translate }}
                    </button>
                </section>
            </div>
        </main>
    </div>
</div>
