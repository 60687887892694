export interface AppliancesRetrainingCategories {
    alwaysOn: AppliancesRetrainingCategory;
    refrigeration: AppliancesRetrainingCategory;
    lighting: AppliancesRetrainingCategory;
    entertainment: AppliancesRetrainingCategory;
    laundry: AppliancesRetrainingCategory;
    oven: AppliancesRetrainingCategory;
    spaceHeating: AppliancesRetrainingCategory;
    waterHeating: AppliancesRetrainingCategory;
    electricVehicle: AppliancesRetrainingCategory;
}


export interface AppliancesRetrainingCategory {
    label: string;
    appliances: ApplianceMapping[];
    isInRetraining: boolean;
    retrainingProgressPercentage: number;
    iconPath: string;
    disabled: boolean;
}


export interface ApplianceMapping {
    name: string;
    label: string;
}

export interface AppliancesRetrainingCategoriesListItem {
    category: string;
    data: AppliancesRetrainingCategory;
}


export const initialAppliancesRetrainingCategories: AppliancesRetrainingCategories = {
    alwaysOn: {
        label: 'AlwaysOn',
        appliances: [],
        isInRetraining: false,
        iconPath: '/assets/img/graphics/appliances/alwayson.svg',
        retrainingProgressPercentage: 0,
        disabled: false,
    },
    refrigeration: {
        label: 'Refrigeration',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/refrigeration/refrigeration.png',
        disabled: false,
    },
    lighting: {
        label: 'Lighting',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/lighting.svg',
        disabled: false,
    },
    entertainment: {
        label: 'Entertainment',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/entertainment.svg',
        disabled: false,
    },
    laundry: {
        label: 'Laundry',
        appliances: [
            {
                name: 'dishWasher',
                label: 'Dishwasher',
            },
            {
                name: 'washingMachine',
                label: 'WashingMachine',
            },
            {
                name: 'dryer',
                label: 'Dryer',
            }
        ],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/laundry.svg',
        disabled: false,
    },
    oven: {
        label: 'Cooking',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/cooking.svg',
        disabled: false,
    },
    spaceHeating: {
        label: 'spaceheating',
        appliances: [
            {
                name: 'heatPumps',
                label: 'HeatPumps',
            },
            {
                name: 'airConditioning',
                label: 'Klimaanlage',
            }
        ],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/spaceheating.svg',
        disabled: false,
    },
    waterHeating: {
        label: 'waterheating',
        appliances: [
            {
                name: 'waterBoiler',
                label: 'kettle',
            },
            {
                name: 'electricShower',
                label: 'Elektrische Dusche',
            },
            {
                name: 'flowHeater',
                label: 'instantaneousWaterHeater',
            },
        ],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/waterheating.svg',
        disabled: false,
    },
    electricVehicle: {
        label: 'ElectricCar',
        appliances: [],
        isInRetraining: false,
        retrainingProgressPercentage: 0,
        iconPath: '/assets/img/graphics/appliances/electricvehicle.svg',
        disabled: false,
    }
};
