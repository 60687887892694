<div class="overlay-small">
    <div class="overlay-container">
        <h1>{{ 'screens.profile.passwordResetTitle' | translate }}</h1>
        <p>
            {{ 'screens.profile.passwordResetDescription' | translate }}
        </p>

        <form [formGroup]="form" (ngSubmit)="submitChange()">
            <fieldset class="eon-input">
                <legend>{{ 'screens.profile.oldPasswordLabel' | translate }}</legend>
                <input id="oldPassword" class="eon-input" type="password" formControlName="oldPassword">
                <label for="oldPassword"></label>
            </fieldset>

            <fieldset class="eon-input">
                <legend>{{ 'screens.profile.newPasswordLabel' | translate }}</legend>
                <input id="newPassword1st" class="eon-input" type="password" formControlName="newPassword1">
                <label for="newPassword1st"></label>
            </fieldset>

            <div class="p-v-t"></div>

            <p class="password-info">
                {{ 'screens.profile.passwordInfo' | translate }}
            </p>
            <div class="password-strength">
                <div class="password-strength-indicator">
                    <span *ngFor="let i of [0,1,2,3,4]"
                          [style.background]="determinePasswordStrengthIndicatorColor(i)"
                          [class.active]="i === currentPasswordScore"></span>
                </div>
                <div class="password-score-label eon-regular">
                    {{ 'common.password' | translate }}:
                    <span class="eon-bold"
                          [style.color]="determinePasswordStrengthColor()">
                        {{determinePasswordScoreRatingText()}}</span>
                </div>
            </div>

            <fieldset class="eon-input">
                <legend>{{ 'screens.profile.repeatNewPasswordLabel' | translate }}</legend>
                <input id="newPassword2nd" class="eon-input" type="password" formControlName="newPassword2">
                <label for="newPassword2nd"></label>
            </fieldset>

            <button class="eon-button-new"
                    type="submit"
                    [disabled]="submitDisabled">
                {{ 'screens.profile.changeButton' | translate }}
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
