export const HouseholdAttributeIdentifierMapping = [
    {key: 'PT.01', value: 'household.attributes.propertyType.PT01'},
    {key: 'PT.02', value: 'household.attributes.propertyType.PT02'},
    {key: 'PT.03', value: 'household.attributes.propertyType.PT03'},
    {key: 'PT.04', value: 'household.attributes.propertyType.PT04'},
    {key: 'PT.05', value: 'household.attributes.propertyType.PT05'},
    {key: 'PT.06', value: 'household.attributes.propertyType.PT06'},
    // property size
    {key: 'PS.01', value: 'household.attributes.propertySize.PS01'},
    {key: 'PS.02', value: 'household.attributes.propertySize.PS02'},
    {key: 'PS.03', value: 'household.attributes.propertySize.PS03'},
    {key: 'PS.04', value: 'household.attributes.propertySize.PS04'},
    // property age
    {key: 'PA.01', value: 'household.attributes.propertyAge.PA01'},
    {key: 'PA.02', value: 'household.attributes.propertyAge.PA02'},
    {key: 'PA.03', value: 'household.attributes.propertyAge.PA03'},
    {key: 'PA.04', value: 'household.attributes.propertyAge.PA04'},
    // occupants
    {key: 'OCC.01', value: 'household.attributes.occupants.OCC01'},
    {key: 'OCC.02', value: 'household.attributes.occupants.OCC02'},
    {key: 'OCC.03', value: 'household.attributes.occupants.OCC03'},
    {key: 'OCC.04', value: 'household.attributes.occupants.OCC04'},
    {key: 'OCC.05', value: 'household.attributes.occupants.OCC05'},
    // space heating
    {key: 'SH.01', value: 'household.attributes.spaceHeating.SH01'},
    {key: 'SH.02', value: 'household.attributes.spaceHeating.SH02'},
    {key: 'SH.03', value: 'household.attributes.spaceHeating.SH03'},
    {key: 'SH.04', value: 'household.attributes.spaceHeating.SH04'},
    {key: 'SH.05', value: 'household.attributes.spaceHeating.SH05'},
    {key: 'SH.07', value: 'household.attributes.spaceHeating.SH07'},
    {key: 'SH.06', value: 'household.attributes.spaceHeating.SH06'},
    {key: 'SH.08', value: 'household.attributes.spaceHeating.SH08'},
    {key: 'SH.09', value: 'household.attributes.spaceHeating.SH09'},
    {key: 'SH.10', value: 'household.attributes.spaceHeating.SH10'},
    // water heating
    {key: 'WH.01', value: 'household.attributes.waterHeating.WH01'},
    {key: 'WH.02', value: 'household.attributes.waterHeating.WH02'},
    {key: 'WH.03', value: 'household.attributes.waterHeating.WH03'},
    {key: 'WH.04', value: 'household.attributes.waterHeating.WH04'},
    {key: 'WH.05', value: 'household.attributes.waterHeating.WH05'}
];
