<div class="overlay">
    <div class="overlay-container-cdk">

        <div class="header">
            <div class="grow-space">
                <h2 class="m-a-n">{{ 'popovers.tariffHistory.title' | translate }}</h2>
            </div>
            <button type="button" class="icn-btn-close"
                    (click)="close(null)"></button>
        </div>

        <div class="content">
            <div class="scroll-list">
                <p *ngFor="let t of tariffs; let i = index">
                    <strong>{{provider}}</strong><br>
                    <strong>{{ 'popovers.tariffHistory.tariff' | translate }}:</strong> {{t.name}}<br>

                    <strong>{{ 'popovers.tariffHistory.from' | translate }} </strong>
                    {{determineStartDate(t)}}
                    <strong>{{ 'popovers.tariffHistory.to' | translate }} </strong>
                    {{determineEndDate(t, i)}}<br>

                    <strong>{{ 'popovers.tariffHistory.basePrice' | translate }}:</strong>
                    {{ t.basePrice}}
                    {{ 'popovers.tariffHistory.perYear' | translate }}<br>

                    <strong>{{ 'popovers.tariffHistory.workPrice' | translate }}:</strong>
                    {{t.workPrice}}
                </p>
            </div>
        </div>

    </div>
</div>
