import {Component, Input, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SingleDayComparisonData} from '../../../shared/interfaces/today-tile-data.interfaces';

@Component({
    selector: 'app-today-tile-stats',
    templateUrl: './today-tile-stats.component.html',
    styleUrls: ['./today-tile-stats.component.scss']
})
export class TodayTileStatsComponent implements OnChanges, OnInit {
    LABELS;

    @Input() today: SingleDayComparisonData;
    @Input() comparisonDate: SingleDayComparisonData;


    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.translate.get('screens.dashboard.consumptionAlert.stats')
            .subscribe((texts: any) => {
                this.LABELS = texts;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}
