<div class="date-input" (click)="onButtonClicked()">
    <input #dateSelect id="date-input" type="date"
           min="2018-01-01" [max]="maxDate"
           [value]="formatDateForInput()"
           (change)="onDateChange(dateSelect.value)">

    <label #label class="" for="date-input">
        {{displaySelectedDate && currentDate !== null ? getCurrentDateFormatted() : 'Datum'}}
    </label>

    <div class="icon-container">
        <i class="calendar-s dark-grey"></i>
    </div>
</div>
