import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BaseComponent} from '../../classes/base-component';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {ImprintService} from '../../services/imprint.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'iona-app',
    templateUrl: './imprint.component.html',
    viewProviders: []
})

export class ImprintComponent extends BaseComponent implements OnInit, OnDestroy {

    @ViewChild('imprintContainer', {static: true})
    private imprintContainer: ElementRef<HTMLElement>;

    constructor(private title: Title,
                private optInService: LocalOptInService,
                private imprintService: ImprintService,
                private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.translate.get('screens.imprint.pageTitle').subscribe((translatedTitle: string) => {
            this.title.setTitle(translatedTitle);
        });
        this.optInService.checkStatus();
        this.imprintService.getImprint().subscribe(
            (fileContents) => {
                this.imprintContainer.nativeElement.innerHTML = fileContents;
            }
        );
    }

    ngOnDestroy() {
    }
}
