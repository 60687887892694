import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import * as moment from 'moment';
import {
    TodayDataProviderService
} from '../../../services/data-provider/today-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {share} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-today-details',
    templateUrl: './today-details.component.html',
    styleUrls: ['./today-details.component.scss'],
    viewProviders: []
})

export class TodayDetailsComponent extends BasePopover implements OnInit, OnDestroy {
    LABELS;
    TEXTS;
    protected readonly ViewState = ViewState;

    currentComparisonDate = moment().subtract(7, 'days').toDate();

    infoVisible = false;

    dateChanged = false;
    showEntireList = false;

    todayDetailData$ = this.todayDataProviderService.todayDetailData$.pipe(
        share()
    );


    constructor(
        protected popoverRef: PopoverRef,
        private todayDataProviderService: TodayDataProviderService,
        private translate: TranslateService
    ) {
        super(popoverRef);
    }


    ngOnInit() {
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate();
        this.translate.get('screens.dashboard.todayDetail.labels').subscribe((labels: any) => {
            this.LABELS = labels;
        });

        this.translate.get('screens.dashboard.todayDetail.texts').subscribe((texts: any) => {
            this.TEXTS = texts;
        });
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.todayDataProviderService.killDetailSub();
    }


    /**
     * Callback on comparison date change
     * @param selectedValue
     */
    onComparisonDateChange(selectedValue: Date) {
        this.currentComparisonDate = selectedValue;
        this.dateChanged = true;
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate(
            selectedValue, this.showEntireList
        );
    }


    /**
     * Toggle whether the entire list of hours should be shown
     */
    toggleEntireListDisplay(): void {
        this.showEntireList = !this.showEntireList;
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate(
            this.currentComparisonDate, this.showEntireList
        );
    }
}
