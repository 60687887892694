export enum AppliancesDetailDataMode {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum AppliancesDetailDisplayMode {
    DATA = 'DATA',
    RETRAINING = 'RETRAINING',
}

