import { BannerDef } from '../../components/banner/banner.component';

export const Banners: {
    energySaver: BannerDef,
    meterReading: BannerDef,
    meterConnecting: BannerDef,
    meterConnectingTutorial: BannerDef
} = {
    energySaver: {
        type: 'alert',
        prio: 1,
        main: 'banners.energySaver.main',
        sub: 'banners.energySaver.sub',
        route: [
            'einstellungen',
            'energy-saver'
        ]
    },
    meterReading: {
        type: 'info',
        prio: 2,
        main: 'banners.meterReading.main',
        sub: 'banners.meterReading.sub',
        route: [
            '/'
        ],
        params: {
            detail: 'meter'
        }
    },
    meterConnecting: {
        type: 'info',
        prio: 3,
        main: 'banners.meterConnecting.main',
        sub: '',
        route: [
            '/registrieren'
        ],
        params: {
            jumpToMeterstate: true
        }
    },
    meterConnectingTutorial: {
        type: 'info',
        prio: 2,
        main: 'banners.meterConnectingTutorial.main',
        sub: '',
        route: [
            '/registrieren'
        ],
        params: {
            jumpToTutorial: true
        }
    }
};
