import {TranslateService} from '@ngx-translate/core';

export const translateAppliance = (translate: TranslateService) => (value: string): string => {
    return translate.instant(`screens.dashboard.appliances.${value}`) || value;
};

export const translateApplianceLowercase = (translate: TranslateService) => (value: string): string => {
    return translate.instant(`screens.dashboard.appliances.${value.toLowerCase()}`) || value;
};

export interface AppliancesDiagramCallout {
    label: string;
    value: string;
    image: string;
    applianceProfileComplete: boolean;
    color: string;
}
