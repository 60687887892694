import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NilmService} from '../nilm.service';
import {DataProviderBaseService} from './data-provider-base.service';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {
    AppliancesRetrainingCategories,
    initialAppliancesRetrainingCategories
} from '../../shared/interfaces/appliances-retraining-category.interfaces';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class ApplianceRetrainingDataProviderService extends DataProviderBaseService {

    private readonly retrainingTsFormat = 'YYYYMMDD';
    private readonly retrainingTimeDays = 30;
    private readonly retrainingCategories = initialAppliancesRetrainingCategories;

    private skipProgressCalculation = false;
    private skipProgressCalculationLastTimestamp: Date | null = null;

    appliancesRetrainingData$ =
        new BehaviorSubject<AppliancesRetrainingCategories>(null);


    constructor(
        private nilm: NilmService,
        private translate: TranslateService
    ) {
        super();
        this.retrainingCategories = this.initializeTranslatedCategories();
    }

    private initializeTranslatedCategories(): AppliancesRetrainingCategories {
        const translatedCategories = {...initialAppliancesRetrainingCategories};

        Object.keys(translatedCategories).forEach(key => {
            const category = translatedCategories[key as keyof AppliancesRetrainingCategories];
            category.label =
                this.translate.instant(`screens.dashboard.appliances.${category.label}`);

            category.appliances = category.appliances.map(appliance => ({
                ...appliance,
                label: this.translate.instant(`screens.dashboard.appliances.${appliance.label}`)
            }));
        });

        return translatedCategories;
    }

    destroy() {
        super.destroy();
    }


    /**
     * Retrieves the current NILM status (or cache) and constructs the data for the appliances
     * retraining view.
     * @param useCache
     */
    getAppliancesRetrainingData(useCache = true) {
        const s = this.nilm.getNilmStatusData(useCache).pipe(
            map((nilmStatusData) => {
                const appliancesInRetraining = nilmStatusData.retrainSchedule;
                if (!appliancesInRetraining) {
                    return this.retrainingCategories;
                }
                const retrainingKeys = Object.keys(appliancesInRetraining);
                if (retrainingKeys.length === 0) {
                    return this.retrainingCategories;
                }
                if (moment().diff(this.skipProgressCalculationLastTimestamp, 'hours') >= 24) {
                    this.skipProgressCalculation = false;
                }
                const categoryKeys = Object.keys(this.retrainingCategories);
                for (const retrainingKey of retrainingKeys) {
                    for (const categoryKey of categoryKeys) {
                        if (categoryKey.toLowerCase() === retrainingKey.toLowerCase()) {
                            this.retrainingCategories[categoryKey].isInRetraining = true;
                            if (this.skipProgressCalculation) {
                                this.retrainingCategories[categoryKey].retrainingProgressPercentage
                                    = this.calculateRetrainingProgressPercentage(
                                    appliancesInRetraining[retrainingKey]
                                );
                            }
                            this.retrainingCategories[categoryKey].disabled = false;
                        }
                        for (const appliance of this.retrainingCategories[categoryKey].appliances) {
                            const applianceInRetraining =
                                appliance.name.toLowerCase() === retrainingKey.toLowerCase();
                            if (applianceInRetraining) {
                                if (this.skipProgressCalculation) {
                                    const percentage = this.calculateRetrainingProgressPercentage(
                                        appliancesInRetraining[retrainingKey]
                                    );
                                    this.retrainingCategories[categoryKey]
                                        .retrainingProgressPercentage = percentage;
                                }
                                this.retrainingCategories[categoryKey]
                                    .isInRetraining = applianceInRetraining;
                                this.retrainingCategories[categoryKey].disabled = false;
                                break;
                            }
                        }
                    }
                }
                return this.retrainingCategories;
            }),
        ).subscribe({
            next: (data) => {
                this.appliancesRetrainingData$.next(data);
            }
        });
    }


    /**
     * Marks a category as disabled.
     * @param category
     */
    disableCategory(category: string[]): void {
        for (const c of category) {
            this.retrainingCategories[c].disabled = true;
            this.retrainingCategories[c].retrainingProgressPercentage = 0;
        }
    }


    /**
     * Disables the progress calculation for the retraining process.
     * @param skip
     */
    setSkipProgressCalculation(skip: boolean) {
        this.skipProgressCalculation = skip;
        this.skipProgressCalculationLastTimestamp = new Date();
    }


    /**
     * calculates the retraining progress percentage given only the ending date.
     *
     * @param finishedTimestamp
     * @private
     */
    private calculateRetrainingProgressPercentage(finishedTimestamp: string) {
        const end = moment(finishedTimestamp, this.retrainingTsFormat)
            .locale('de');
        const now = moment().locale('de');
        const start = moment(end)
            .locale('de')
            .subtract(this.retrainingTimeDays, 'days');

        const total = end.unix() - start.unix();
        const elapsed = now.unix() - start.unix();
        return (elapsed / total) * 100;
    }
}

