import {InternalMeterConnectionStatus} from '../enums/meter-status.enum';
import {ViewState} from '../enums/view-state.enum';


/**
 * Meter Base Stats
 * =================================================================================================
 */
export interface MeterBaseStats {
    connectionStatus: InternalMeterConnectionStatus;
    meterSerialNumber: string;
}


export const initialMeterBaseStats: MeterBaseStats = {
    connectionStatus: InternalMeterConnectionStatus.PENDING,
    meterSerialNumber: '- --- -- ---- ----',
};


/**
 * Meter Values
 * =================================================================================================
 */
export interface MeterValues {
    offtake: number | undefined;
    feedin: number | undefined;
}


export const initialMeterValues: MeterValues = {
    offtake: undefined,
    feedin: undefined
};


/**
 * Meter Data
 */

export interface MeterData {
    wifiConnectionQuality: number;
    meterValues: MeterValues;
    meterStats: MeterBaseStats;
    viewState: ViewState;
}


export const initialMeterData: MeterData = {
    wifiConnectionQuality: 0,
    meterValues: initialMeterValues,
    meterStats: initialMeterBaseStats,
    viewState: ViewState.LOADING,
};

