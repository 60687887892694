<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: E.ON Smart Control Stecker - Details; previous_screen: Übersicht"
         (click)="onTileClicked($event)">

        <div class="tile-inner">
            <h3 class="tile-heading">{{ 'screens.dashboard.tiles.powerChecker.stecker' | translate }}</h3>
            <div class="tile-content col powerchecker-tile-content">
                <div class="splitrow">
                    <div class="center-contents powerchecker-toggle">
                        <app-eon-toggle [state]="state"
                                        (stateChanged)="onStateChange($event)">
                        </app-eon-toggle>
                    </div>
                    <div class="center-contents-v">
                        <h4 class="powerchecker-name eon-regular">{{plugName }}</h4>
                        <h4 class="powerchecker-room eon-bold">{{plugRoom }}</h4>
                    </div>
                </div>

                <div class="powerchecker-info-text eon-regular" [innerHTML]="('screens.dashboard.tiles.powerChecker.powercheckerInfo' | translate: { smartControl: 'E.ON Smart Control' })">
                </div>

            </div>
        </div>
    </div>
</div>
