import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {UserService} from './user.service';
import {Observable, of, Subject, throwError} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {RegularLoginResponse} from './auth.service';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class MfaService extends BaseService {
    onTokenVerification = new Subject();
    onPostMFACode = new Subject<MfaTokenVerificationResponse>();

    mfaUserId = '';
    mfaSession = '';

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    getMFAStatus(): Observable<boolean> {
        if (this.application.isDemoMode()) {
            return of(null);
        }
        return this.mfaApiGetRequest(constants.api.routes.mfa.status).pipe(
            map(res => this.mapDefault(res)),
            map(res => res.mfa_status)
        );
    }

    enableMFA(): Observable<any> {
        const payload = {access_token: this.auth.getToken()};
        return this.mfaApiPostRequest(constants.api.routes.mfa.enable, payload).pipe(
            mergeMap(res => of(this.mapDefault(res))),
            mergeMap(data => {
                try {
                    return of(data.secret_code);
                } catch (e) {
                    return throwError(e);
                }
            })
        );
    }

    disableMFA(): Observable<any> {
        const payload = {access_token: this.auth.getToken()};
        return this.mfaApiPostRequest(constants.api.routes.mfa.disable, payload).pipe(
            mergeMap(response => {
                if (response.status === 'ok') {
                    return of(true);
                }
            }),
            catchError(error => of(null))
        );
    }

    postMFATokenStandalone(code: string) {
        const url = this.AUTH_BASE_URL;
        const headers = this.generateLoginHeaders();
        const body = {
            method: 'mfa-login',
            userId: this.mfaUserId,
            session: this.mfaSession,
            userCode: code
        };
        this.http.post(url, JSON.stringify(body), {headers}).pipe(
            mergeMap((result) => of({success: true, data: result})),
            catchError((error) => of({success: false, data: error}))
        ).subscribe((result) => this.onPostMFACode.next(result));
    }

    verifyMFAToken(code: string): Observable<any> {
        const payload = {access_token: this.auth.getToken(), user_code: code};
        return this.mfaApiPostRequest(constants.api.routes.mfa.verifySoftwareToken, payload);
    }

    verifyMFATokenStandalone(code: string): void {
        const payload = {access_token: this.auth.getToken(), user_code: code};
        this.mfaApiPostRequest(constants.api.routes.mfa.verifySoftwareToken, payload).pipe(
            mergeMap(response => {
                if (response.status === 'ok') {
                    return of(true);
                }
                return of(null);
            }),
            catchError(error => of(null))
        ).subscribe(
            data => this.onTokenVerification.next(data),
            error => this.onTokenVerification.next(false)
        );
    }

    private mfaApiGetRequest(method: string): Observable<any> {
        const url = this.API_BASE_URL + method;
        return this.http.get(url);
    }

    private mfaApiPostRequest(method: string, payload): Observable<any> {
        const url = this.API_BASE_URL + method;
        return this.http.post(url, payload).pipe(
            // tap(res => console.log('initial response', res)),
        );
    }

    private generateLoginHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            // 'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });
    }
}

export interface MfaTokenVerificationResponse {
    success: boolean;
    data: RegularLoginResponse | HttpErrorResponse | any;
}
