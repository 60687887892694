import {ElementRef, Injectable, Injector} from '@angular/core';
import {
    ConnectionPositionPair,
    Overlay,
    OverlayConfig,
    PositionStrategy
} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {PopoverContent, PopoverRef} from './popover-ref';
import {PopoverComponent} from './popover.component';

export interface PopoverParams<T> {
    width?: string | number;
    height?: string | number;
    content: PopoverContent;
    data?: T;
    position?: 'absolute' | 'relative';
    placement?: string | { x: number, y: number };
    element?: ElementRef;
    hasBackdrop?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class Popover {
    constructor(private overlay: Overlay, private injector: Injector) {
    }

    /**
     * Opens the popover with specified config.
     * @param content
     * @param data
     * @param width
     * @param height
     * @param position
     * @param hasBackdrop
     * @param placement
     * @param element
     */
    open<T>({
                content,
                data,
                width,
                height,
                position,
                hasBackdrop,
                placement,
                element
            }: PopoverParams<T>): PopoverRef<T> {
        const overlayRef = this.overlay.create(this.getOverlayConfig({
            width,
            height,
            position,
            hasBackdrop,
            placement,
            element
        }));
        const popoverRef = new PopoverRef<T>(overlayRef, content, data);
        const injector = this.createInjector(popoverRef, this.injector);
        overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
        return popoverRef;
    }


    /**
     * Convenience Function that returns an overlay config Object from given values.
     * @param width
     * @param height
     * @param position
     * @param hasBackdrop
     * @param placement
     * @param element
     */
    private getOverlayConfig({
                                 width,
                                 height,
                                 position,
                                 hasBackdrop,
                                 placement,
                                 element
                             }): OverlayConfig {
        const config = new OverlayConfig({
            hasBackdrop: true,
            width,
            height,
            backdropClass: hasBackdrop ? 'popover-backdrop' : '',
            scrollStrategy: this.overlay.scrollStrategies.noop()
        });

        if (!position || !placement) {
            config.positionStrategy = this.getAbsoluteOverlayPosition('center center');
        }

        if (position && !placement) {
            config.positionStrategy = this.getAbsoluteOverlayPosition('center center');
        }

        if (position === 'absolute') {
            config.positionStrategy = this.getAbsoluteOverlayPosition(placement);
        } else if (position === 'relative') {
            if (element) {
                config.positionStrategy = this.getRelativeOvelayPosition(element, placement);
            } else {
                console.log('no element specified');
            }
        }

        return config;
    }


    /**
     * Returns a PositionStrategy object for relative overlay placement.
     * @param element
     * @param placement
     */
    private getRelativeOvelayPosition(element, placement: string): PositionStrategy {
        return this.overlay.position().flexibleConnectedTo(element)
            .withPositions(this.getPositions()).withPush(false);
    }

    private getPositions(): ConnectionPositionPair[] {
        return [
            {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom'
            },
            {
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            },
        ];
    }

    /**
     * Returns a PositionStrategy object for absolute overlay placement.
     * @param placement can be a string pair or explicite x y values
     */
    private getAbsoluteOverlayPosition(placement: string | { x: number, y: number }): PositionStrategy {
        if (typeof placement === 'string') {
            const axes = placement.trim().split(' ');
            const h = axes[0];
            const v = axes[1];
            let pos = this.overlay.position().global();
            if (h === 'center') {
                pos = pos.centerHorizontally();
            } else if (h === 'start') {
                pos = pos.left('0');
            } else if (h === 'end') {
                pos = pos.right('0');
            } else {
                console.log('wrong placement option for vertical placement');
            }
            if (v === 'center') {
                pos = pos.centerVertically();
            } else if (v === 'start') {
                pos = pos.left('0');
            } else if (v === 'end') {
                pos = pos.right('0');
            } else {
                console.log('wrong placement option for vertical placement');
            }
            return pos;
        }

        return this.overlay.position().global().left(`${placement.x}px`).top(`${placement.y}px`);
    }

    createInjector(popoverRef: PopoverRef, injector: Injector) {
        const tokens = new WeakMap([[PopoverRef, popoverRef]]);
        return new PortalInjector(injector, tokens);
    }

}
