<main class="register-container" [ngStyle]="{background : determineBackgroundColor()}">

    <div class="info-button"
         [ngClass]="{bright: isBright()}"
         (click)="openFAQ()">
    </div>

    <!--
  -- ACCOUNT CREATION --------------------------------------
-->
    <section class="white" *ngIf="currentStep === onboardingSteps.accountCreation">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>

        <form class="login-form"
              [formGroup]="voucherForm"
              (ngSubmit)="validateVoucher()">
            <div class="grow-space">
                <fieldset class="eon-input">
                    <legend>{{ 'screens.register.onBoarding.accountCreation.emailLabel' | translate }}</legend>
                    <input id="email" class="eon-input" type="text"
                           formControlName="email">
                    <label for="email"></label>
                </fieldset>

                <fieldset class="eon-input">
                    <legend>{{ 'common.code' | translate }}</legend>
                    <input id="code" class="eon-input" type="text"
                           formControlName="code">
                    <label for="code"></label>
                </fieldset>
            </div>

            <div class="center-contents btn-container">
                <button class="eon-button-new" type="submit">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" target="_self" class="eon-link-new"
                   routerLink="/">
                    {{ 'common.login' | translate }}
                </a>
            </div>
        </form>
    </section>


    <!---- SET PASSWORD ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.passwordEntry">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>
        <h1>{{ 'screens.register.onBoarding.passwordEntry.heading' | translate }}</h1>
        <form class="login-form" [formGroup]="passwordForm" (ngSubmit)="registerUser()">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>{{ 'common.password' | translate }}</legend>
                        <input id="password1" class="eon-input" type="password"
                               formControlName="password1">
                        <label for="password1"></label>
                    </fieldset>
                    <p class="password-info">
                        {{ 'screens.register.onBoarding.passwordEntry.passwordInfo' | translate }}
                    </p>
                    <div class="password-strength">
                        <div class="password-strength-indicator">
                        <span *ngFor="let i of [0,1,2,3,4]"
                              [style.background]="determinePasswordStrengthIndicatorColor(i)"
                              [class.active]="i === currentPasswordScore"></span>
                        </div>
                        <div class="password-score-label eon-regular">
                            {{ 'common.password' | translate }}: <span
                            class="eon-bold"
                            [style.color]="determinePasswordStrengthColor()">
                        {{ determinePasswordScoreRatingText() }}</span>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>{{ 'screens.register.onBoarding.passwordEntry.confirmPasswordLabel' | translate }}</legend>
                        <input id="password2" class="eon-input" type="password"
                               formControlName="password2">
                        <label for="password2"></label>
                    </fieldset>
                </div>
            </div>
            <div class="btn-container center-contents">
                <button class="eon-button-new"
                        type="submit"
                        [disabled]="setPasswordDisabled">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>


    <!---- HARDWARE PRESENT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.hardwarePresent">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1 class="m-b-x">{{ 'screens.register.onBoarding.hardwarePresent.heading' | translate }}?</h1>
        <div class="checklist-container">
            <ul class="checklist">
                <li class="eon-bold">{{ getDeviceTitle() }}</li>
                <li class="eon-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.opticalReader' | translate }}</li>
                <li class="eon-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.pin' | translate }}</li>
                <li class="eon-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.batteries' | translate }}</li>
                <li class="eon-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.meterAccess' | translate }}</li>
            </ul>
            <p class="subtext">
                {{ 'screens.register.onBoarding.hardwarePresent.subtext' | translate }}
            </p>
        </div>

        <div class="center-contents btn-container">
            <button class="eon-button-new"
                    (click)="setStep(onboardingSteps.analyticsOptIn)">
                {{ 'screens.register.onBoarding.hardwarePresent.yesButton' | translate }}
            </button>
        </div>
        <div class="center-contents">
            <a href="javascript:;" target="_self"
               class="eon-link-new"
               (click)="hardwareNotReady()">
                {{ 'screens.register.onBoarding.hardwarePresent.noLink' | translate }}
            </a>
        </div>
    </section>

    <!---- ANALYTICS ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.analyticsOptIn">
        <h1 class="m-b-x">{{ 'screens.register.onBoarding.analyticsOptIn.heading' | translate }}</h1>

        <form class="login-form"
              (submit)="checkOptInResponse(trackingCb.checked, optinCb.checked, optinAnalyticsDataCb.checked)">
            <div class="permissions-list">
                <div class="grow-space">
                    <label class="eon-checkbox" for="tracking-checkbox">
                        <input #trackingCb type="checkbox" name="tracking-checkbox" id="tracking-checkbox">
                        {{ 'screens.register.onBoarding.analyticsOptIn.googleAnalytics.label' | translate }}
                    </label>
                    <div class="btn-container font-regular">
                        {{ 'screens.register.onBoarding.analyticsOptIn.googleAnalytics.description' | translate }}
                    </div>
                </div>

                <div class="grow-space">
                    <label class="eon-checkbox" for="optin-checkbox">
                        <input #optinCb type="checkbox" name="optin-checkbox" id="optin-checkbox">
                        {{ 'screens.register.onBoarding.analyticsOptIn.onlineMode.label' | translate }}
                    </label>
                    <div class="btn-container font-regular">
                        {{ 'screens.register.onBoarding.analyticsOptIn.onlineMode.description' | translate: {device: isSmartBox2() ? getDeviceTitle() : 'Hardware'} }}
                    </div>
                </div>

                <div class="grow-space">
                    <label class="eon-checkbox" for="data-checkbox">
                        <input #optinAnalyticsDataCb type="checkbox" name="data-checkbox" id="data-checkbox">
                        {{ 'screens.register.onBoarding.analyticsOptIn.dataProcessing.label' | translate }}
                    </label>
                    <div class="btn-container font-regular">
                        {{ 'screens.register.onBoarding.analyticsOptIn.dataProcessing.description' | translate }}
                    </div>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>

    <!---- BOX POWER CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.box.powerConnect">
        <h1 class="m-b-x">
            {{ 'screens.register.onBoarding.powerConnect.heading' | translate: {device: isSmartBox2() ? getDeviceTitle() : 'Hardware'} }}
        </h1>
        <p class="white">
            {{ 'screens.register.onBoarding.powerConnect.paragraph' | translate }}
        </p>
        <div class="device-video-container">
            <video class="m-v-x" width="100%" height="100%" autoplay muted loop>
                <source type="video/mp4"
                        [src]="isSmartBox2() ? '../../../assets/media/onboarding/onboarding_connect_power_2.mp4' : '../../../assets/media/onboarding/onboarding_connect_power.mp4'">
            </video>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.box.lanConnect)">
                {{ 'common.iDid' | translate }}
            </button>
        </div>
    </section>

    <!---- BOX LAN CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.box.lanConnect">
        <h1 class="m-b-x">{{ 'screens.register.onBoarding.boxLanConnect.heading' | translate }}</h1>
        <div class="device-video-container">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="../../../assets/media/onboarding/onboarding_connect_lan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.box.macEntry)">
                {{ 'common.iDid' | translate }}
            </button>
        </div>
    </section>

    <!---- BOX MAC ENTRY ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>{{ 'screens.register.onBoarding.boxMacEntry.heading' | translate }}</h1>
        <h2>{{ 'screens.register.onBoarding.boxMacEntry.subheading' | translate }}</h2>
        <form class="login-form m-t-x" (submit)="setFirstMACAddress(mac.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>{{ 'common.macIdLabel' | translate }}</legend>
                        <input #mac class="eon-input" type="text"
                               [textMask]="macAddressMask"
                               [(ngModel)]="firstMac" [ngModelOptions]="{standalone: true}"
                               [placeholder]="macPlaceholder">
                    </fieldset>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>

    <!---- BOX MAC ENTRY 2 ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry2">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>{{ 'screens.register.onBoarding.boxMacEntry2.heading' | translate }}</h1>
        <form class="login-form m-t-x" (submit)="registerDevice(mac2.value,true)">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>{{ 'common.macIdLabel' | translate }}</legend>
                        <input #mac2 class="eon-input" type="text"
                               [textMask]="macAddressMask"
                               [(ngModel)]="secondMac" [ngModelOptions]="{standalone: true}"
                               [placeholder]="macPlaceholder">
                    </fieldset>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>

    <!---- BOX CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.box.connecting"
             class="big connection">
        <h1>{{ 'screens.register.onBoarding.boxConnecting.heading' | translate }}</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.boxConnecting.progress.preparingBox' | translate }}</div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.boxConnecting.progress.searchingMeter' | translate }}</div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.boxConnecting.progress.connectingMeter' | translate }}</div>
                </li>
            </ul>
            <ng-container *ngIf="meterStatus > 0">
                <div>
                    <button type="submit" class="eon-button-new turquoise-inverted"
                            (click)="isEDGUser ? setStep(onboardingSteps.tariffEntry) :
                               setStep(onboardingSteps.accountSetup)">
                        {{ 'common.goToDashboard' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <!---- PLUG NOTE MAC ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.noteMac">
        <h1>{{ 'screens.register.onBoarding.plugNoteMac.heading' | translate:{device: isSmartBox2() ? 'Ihres E.ON Smart Control Box 2.0' : 'Ihres E.ON Smart Control Steckers'} }}</h1>
        <div class="center-contents m-v-x">
            <img
                [src]="!isSmartBox2() ? '../../../assets/img/onboarding/plug-mac.png' : '../../../assets/img/onboarding/mac_box.png'"
                alt="" [style.height]="isSmartBox2() ? '170px' : '400px'">
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.powerConnect)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!---- PLUG POWER CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.powerConnect">
        <h1 class="m-b-x">{{ 'screens.register.onBoarding.plugPowerConnect.heading' | translate:{device: isSmartBox2() ? getDeviceTitle() : 'Hardware'} }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.plugPowerConnect.instructions' | translate }}
        </p>
        <div class="device-video-container">
            <video class="m-v-x" width="100%" autoplay muted loop>
                <source type="video/mp4"
                        [src]="isSmartBox2() ? '../../../assets/media/onboarding/onboarding_connect_power_2.mp4' : '../../../assets/media/onboarding/onboarding_connect_power.mp4'">
            </video>
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.appSelect)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!---- APP SELECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.appSelect">
        <h1>{{ 'screens.register.onBoarding.appSelect.heading' | translate:{device: getDeviceTitle()} }}</h1>
        <div class="center-contents m-v-x">
            <button type="submit" class="eon-button-new"
                    (click)="setStep(onboardingSteps.devices.plug.wpsConnect)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
        <div>
            <p>
                {{ 'screens.register.onBoarding.appSelect.instructions' | translate }}
            </p>
            <div class="store-links m-b-l">
                <a target="_blank" class="center-contents"
                   href='https://play.google.com/store/apps/details?id=com.iona_energy.android&hl=en'>
                    <img class="p-r-l" alt='Jetzt bei Google Play' src='../../../assets/images/badges/googleplay.png'/>
                </a>
                <a target="_blank" class="center-contents"
                   href="https://apps.apple.com/de/app/iona/id1434046824">
                    <img class="p-l-l" src="../../../assets/images/badges/appstore.png" alt="Laden im AppStore">
                </a>
            </div>
        </div>
    </section>

    <!---- WPS CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.wpsConnect">
        <h1 *ngIf="isSmartBox2()">{{ 'screens.register.onBoarding.wpsConnect.headingSmartBox2' | translate }}</h1>
        <h1 *ngIf="!isSmartBox2()">{{ 'screens.register.onBoarding.wpsConnect.headingOther' | translate }}</h1>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source type="video/mp4"
                        [src]=" isSmartBox2() ? '../../../assets/media/box_2.mp4' : '../../../assets/media/powerchecker-2.mp4'">
            </video>
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.wpsRouter)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!---- WPS ROUTER ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.wpsRouter">
        <h1>{{ 'screens.register.onBoarding.wpsRouter.heading' | translate }}</h1>
        <p class="info-white">{{ 'screens.register.onBoarding.wpsRouter.info' | translate }}</p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="../../../assets/media/wlan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.wifiConnected)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!---- WPS CONNECTED---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.wifiConnected">
        <h1>{{ 'screens.register.onBoarding.wifiConnected.heading' | translate:{deviceTitle: getDeviceTitle()} }}</h1>
        <div class="m-v-x">
            <img
                [src]="isSmartBox2() ? '../../../assets/images/box-onboarding-final-state.png' : '../../../assets/images/powerchecker-onboarding-final-state.png'"
                alt="Blau leuchtender Powerchecker" [style.height]="isSmartBox2() ? '300px' : '400px'">
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.macEntry)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!---- PLUG MAC ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.macEntry">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>{{ 'screens.register.onBoarding.macEntry.heading' | translate:{deviceTitle: getDeviceTitle()} }}</h1>
        <h2>{{ 'screens.register.onBoarding.macEntry.subheading' | translate:{deviceTitle: getDeviceTitle()} }}</h2>
        <form class="login-form m-t-x"
              [formGroup]="deviceMacForm"
              (submit)="setPlugMacAddress()">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>MAC-ID</legend>
                        <input class="eon-input" type="text"
                               [formControl]="deviceMacForm.controls.mac"
                               [textMask]="macAddressMask"
                               [placeholder]="macPlaceholder">
                    </fieldset>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new"
                        [disabled]="deviceMacForm.invalid">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>

    <!---- READER MAC ENTRY ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.findAndEnterORMacAddress">
        <h1>{{ 'screens.register.onBoarding.readerMacEntry.heading' | translate }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.readerMacEntry.description' | translate }}
        </p>
        <form class="login-form m-t-x"
              [formGroup]="readerMacForm"
              (ngSubmit)="setOpticalReaderMacAddress()">
            <div class="grow-space">
                <fieldset class="eon-input white">
                    <legend>MAC-ID</legend>
                    <input type="text" class="iona-input"
                           [minlength]="16"
                           [formControl]="readerMacForm.controls.mac"
                           [textMask]="opticalReaderMacAddressMask"
                           [placeholder]="opticalReaderMacPlaceholder">
                </fieldset>
            </div>
            <div class="grow-space">
                <img src="/assets/img/onboarding/optical-reader-mac.png" width="100%">
            </div>
            <div class="center-contents btn-container">
                <button class="eon-button-new turquoise-inverted"
                        type="submit"
                        [disabled]="readerMacForm.invalid">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>

    <!-- READER & PLUG POSITIONING -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.positioningOR">
        <h1>{{ 'screens.register.onBoarding.positioningOR.heading' | translate }}</h1>
        <p *ngIf="isSmartBox2()" class="white">
            {{ 'screens.register.onBoarding.positioningOR.descriptionSmartBox' | translate }}
        </p>
        <p *ngIf="!isSmartBox2()" class="white">
            {{ 'screens.register.onBoarding.positioningOR.descriptionPlug' | translate }}
        </p>
        <div class="grow-space">
            <div class="center-contents p-a-x">
                <img
                    [src]="isSmartBox2() ? '/assets/img/onboarding/box-2.0-smart-connect.png' : '/assets/img/onboarding/optical-reader-powerchecker-placement.png'"
                    [style.width]="isSmartBox2() ? '135%' : '115%'">
            </div>
        </div>
        <div class="center-contents btn-container">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.preparingPlug)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!-- PRECONDITION STATE -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.preparingPlug">
        <h1>{{ 'screens.register.onBoarding.preparingPlug.heading' | translate }}</h1>
        <p *ngIf="isSmartBox2()" class="white">
            {{ 'screens.register.onBoarding.preparingPlug.descriptionSmartBox' | translate }}
        </p>
        <p *ngIf="!isSmartBox2()" class="white">
            {{ 'screens.register.onBoarding.preparingPlug.descriptionPlug' | translate }}
        </p>
        <div class="flex-col grow-space">
            <div class="center-contents p-a-x">
                <img
                    [src]="isSmartBox2() ? '/assets/img/onboarding/smart-box-2.0.png' : '/assets/img/onboarding/powerchecker-ready.webp'"
                    [style.width]="isSmartBox2() ? '100%' : '30%'">
            </div>
            <div class="flex grow-space center-contents">
                <ng-container *ngIf="!registrationOnboardSuccessful">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                </ng-container>
                <ng-container *ngIf="registrationOnboardSuccessful">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                </ng-container>
            </div>
        </div>
        <div class="center-contents btn-container">
            <button class="eon-button-new turquoise-inverted"
                    [disabled]="!registrationOnboardSuccessful"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!-- START COMMISSIONING -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission">
        <h1 *ngIf="isSmartBox2()">{{ 'screens.register.onBoarding.startCommissioning.headingSmartBox' | translate }}</h1>
        <h1 *ngIf="!isSmartBox2()">{{ 'screens.register.onBoarding.startCommissioning.headingPlug' | translate }}</h1>
        <p *ngIf="isSmartBox2()" class="white">
            {{ 'screens.register.onBoarding.startCommissioning.descriptionSmartBox' | translate }}
        </p>
        <p *ngIf="!isSmartBox2()" class="white">
            {{ 'screens.register.onBoarding.startCommissioning.descriptionPlug' | translate }}
        </p>
        <div class="flex-col center-contents">
            <ng-container [ngSwitch]="commissionStatus">
                <ng-container *ngSwitchCase="'loading'">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                    <p class="m-t-l center-text white">
                        {{ 'screens.register.onBoarding.startCommissioning.loadingText' | translate: {deviceTitle: isSmartBox2() ? getDeviceTitle() : 'Smart Control Stecker'} }}
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'success'">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        {{ 'screens.register.onBoarding.startCommissioning.successText' | translate: {deviceTitle: isSmartBox2() ? getDeviceTitle() : 'Smart Control Stecker'} }}
                    </p>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted"
                                [disabled]="false"
                                (click)="startFirmwareUpdate()">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <img src="/assets/img/onboarding/loading-info.webp" width="75px" height="65px">
                    <p class="m-t-l center-text white">
                        {{ 'screens.register.onBoarding.startCommissioning.errorText' | translate: {deviceTitle: isSmartBox2() ? getDeviceTitle() : 'Smart Control Stecker'} }}
                    </p>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted commissionErrorButtons"
                                (click)="resetMacAddressEntry()" [disabled]="false">
                            {{ 'common.errors.retryButton' | translate }}
                        </button>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted commissionErrorButtons" [disabled]="false"
                                (click)="openSupportDialog()">
                            {{ 'common.supportButton' | translate }}
                        </button>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted commissionErrorButtons" [disabled]="true">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'initial'">
                    <div class="m-v-x">
                        <video width="100%" height="100%" autoplay muted loop>
                            <source src="assets/media/onboarding/power-and-button.mp4" type="video/mp4">
                        </video>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted"
                                (click)="startCommission()">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </section>

    <!-- AFTER UPDATE POWERCYCLE OR -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.infoProcessDuration">
        <h1>{{ 'screens.register.onBoarding.afterUpdatePowerCycle.heading' | translate }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.afterUpdatePowerCycle.description' | translate: {deviceTitle: getDeviceTitle()} }}
        </p>
        <div class="flex-col grow-space center-contents">
            <ng-container [ngSwitch]="firmwareUpdateStatus">
                <ng-container *ngSwitchCase="'loading'">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                    <p class="m-t-l center-text white">
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.loadingText' | translate }}
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'uptodate'">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.uptodateText' | translate }}
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <img src="/assets/img/onboarding/loading-info.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.errorText' | translate }}
                    </p>
                    <p class="white">
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.errorAdditionalText' | translate }}
                    </p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="firmwareIsUpdating">
                {{ 'screens.register.onBoarding.afterUpdatePowerCycle.updatingText' | translate }}
            </ng-container>
        </div>
        <div class="center-contents btn-container">
            <button class="eon-button-new turquoise-inverted"
                    [disabled]="!(firmwareUpdateStatus === 'uptodate' || firmwareUpdateStatus === 'error')"
                    (click)="setStep(onboardingSteps.deviceSelection)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!-- OPTICAL READER DEVICE SELECTION -->
    <section *ngIf="currentStep === onboardingSteps.deviceSelection">
        <h1>{{ 'screens.register.onBoarding.deviceSelection.heading' | translate }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.deviceSelection.paragraph' | translate: {deviceTitle: getDeviceTitle()} }}
        </p>
        <div class="flow-image-container">
            <div>
                <img src="assets/img/graphics/optical-reader-flow.png" alt="" width="100%">
            </div>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep1)">
                {{ 'screens.register.onBoarding.deviceSelection.button' | translate }}
            </button>
        </div>
    </section>

    <!-- OPTICAL READER INSTALLATION STEP 1 -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep1">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep1.heading' | translate }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.orSetup.readerInstallationStep1.paragraph' | translate }}
        </p>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="../../../assets/media/onboarding/optical_reader/erna-04.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep2)">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep1.button' | translate }}
            </button>
        </div>
    </section>

    <!-- OPTICAL READER INSTALLATION STEP 2 -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep2">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep2.heading' | translate }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.orSetup.readerInstallationStep2.paragraph' | translate }}
        </p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="../../../assets/media/onboarding/optical_reader/erna-05.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep3)">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep2.button' | translate }}
            </button>
        </div>
    </section>

    <!-- OPTICAL READER INSTALLATION STEP 3 -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep3">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep3.heading' | translate }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.orSetup.readerInstallationStep3.paragraph' | translate }}
        </p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="../../../assets/media/onboarding/powercycle.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep4)">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep3.button' | translate }}
            </button>
        </div>
    </section>

    <!-- OPTICAL READER INSTALLATION STEP 4 -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep4">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep4.heading' | translate }}</h1>
        <p class="white">
            {{ 'screens.register.onBoarding.orSetup.readerInstallationStep4.paragraph' | translate }}
        </p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="../../../assets/media/onboarding/optical_reader/erna-07.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="afterOrOnboardingComplete()">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep4.button' | translate }}
            </button>
        </div>
    </section>

    <!-- PLUG CONNECTION -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connecting"
             class="big connection">
        <h1>{{ 'screens.register.onBoarding.orSetup.plug.connecting.heading' | translate:{deviceTitle: getDeviceTitle()} }}</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connecting.steps.prepare' | translate:{deviceTitle: getDeviceTitle()} }}</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connecting.steps.search' | translate }}</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connecting.steps.connect' | translate }}</div>
                </li>
                <li class="progress-label-sub"></li>
            </ul>
            <ng-container *ngIf="meterStatus > 0">
                <div>
                    <button type="submit" class="eon-button-new turquoise-inverted"
                            (click)="isEDGUser ? setStep(onboardingSteps.tariffEntry) : setStep(onboardingSteps.accountSetup)">
                        {{ 'common.goToDashboard' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <!-- PLUG CONNECTION -->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connectingOR"
             class="big connection">
        <h1>{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.heading' | translate:{deviceTitle: getDeviceTitle()} }}</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.prepare' | translate:{deviceTitle: getDeviceTitle()} }}</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.search' | translate }}</div>
                </li>
                <li class="progress-label-sub">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.pressButton' | translate }}</li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.connect' | translate }}</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 3), 'progress': (meterStatus == 3)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 3}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.unlock' | translate }}</div>
                </li>
                <li class="progress-label-sub">
                    {{ meterStatus === 3 ? ('screens.register.onBoarding.orSetup.plug.connectingOR.steps.waiting' | translate) : '' }}
                </li>
            </ul>
            <ng-container *ngIf="meterStatus >= 0">
                <div>
                    <button type="submit" class="eon-button-new turquoise-inverted"
                            (click)="afterConnectionFinished()">
                        {{ 'common.goToDashboard' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <!---- ACCOUNT SETUP ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.accountSetup">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>{{ 'screens.register.onBoarding.accountSetup.welcome' | translate }}</h1>
        <h2>
            {{ 'screens.register.onBoarding.accountSetup.introduction' | translate }}
        </h2>
        <div class="center-contents btn-container">
            <button type="submit" class="eon-button-new"
                    routerLink="/mein-haushalt">
                {{ 'screens.register.onBoarding.accountSetup.button' | translate }}
            </button>
        </div>
    </section>

    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.connectionError">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>
            {{ 'screens.register.onBoarding.boxConnectionError.title'
            | translate: {
            deviceArticle: device === 'plug' ? 'den' : 'die',
            deviceType: device === 'plug' ? 'Stecker' : 'Box',
            smartBoxVersion: isSmartBox2() ? '2.0' : ''
        }
            }}
        </h1>
        <h2>
            {{ 'screens.register.onBoarding.boxConnectionError.subtitle' | translate }}
        </h2>
        <div class="last-growing">
            <div class="center-contents btn-container">
                <button class="eon-button-new"
                        (click)="setStep(onboardingSteps.devices.box.macEntry)">
                    {{ 'screens.register.onBoarding.boxNotFoundError.buttonMacEntry' | translate }}
                </button>
            </div>
            <div class="center-contents">
                <button class="eon-button-new"
                        (click)="device == 'plug' ?
                        setStep(onboardingSteps.devices.plug.macEntry) :
                        setStep(onboardingSteps.devices.box.macEntry)">
                    {{ 'screens.register.onBoarding.boxConnectionError.buttonReconnect' | translate }}
                </button>
            </div>
        </div>
    </section>


    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.notFoundError">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>
            {{ 'screens.register.onBoarding.boxNotFoundError.title'
            | translate: {
            deviceArticle: device === 'plug' ? 'den' : 'die',
            deviceType: device === 'plug' ? 'Stecker' : 'Box',
            smartBoxVersion: isSmartBox2() ? '2.0' : ''
        }
            }}
        </h1>
        <h2>
            {{ 'screens.register.onBoarding.boxNotFoundError.subtitle' | translate }}
        </h2>
        <div class="last-growing">
            <div class="center-contents btn-container">
                <button class="eon-button-new"
                        (click)="device == 'plug' ?
                        setStep(onboardingSteps.devices.plug.macEntry) :
                        setStep(onboardingSteps.devices.box.macEntry)">
                    {{ 'screens.register.onBoarding.boxNotFoundError.buttonMacEntry' | translate }}
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" class="eon-link-new" target="_self"
                   (click)="openSupportDialog()"
                   angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_profile_cancel">
                    {{ 'screens.register.onBoarding.boxNotFoundError.supportLink' | translate }}
                </a>
            </div>
        </div>
    </section>

    <!-- SOMETHING FAILED -->
    <section class="white" *ngIf="currentStep === onboardingSteps.detailFailed">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>{{ 'screens.register.onBoarding.detailFailed.title' | translate }}</h1>
        <h2>{{ 'screens.register.onBoarding.detailFailed.text' | translate }}</h2>
        <div class="last-growing">
            <button class="eon-button-new"
                    (click)="retryMeterStatus()">
                {{ 'screens.register.onBoarding.detailFailed.retry' | translate }}
            </button>
        </div>
    </section>

    <!-- OPTICAL READER PIN ENTRY -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.pinEntry">
        <h1>{{ 'screens.register.onBoarding.opticalReader.pinEntry.title' | translate }}</h1>
        <h2>{{ 'screens.register.onBoarding.opticalReader.pinEntry.subtitle' | translate }}</h2>
        <form class="login-form m-t-x" (submit)="setPIN(pin.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>Pin</legend>
                        <input #pin class="eon-input" type="text"
                               (keyup)="onPinChange(pin.value)"
                               [attr.maxlength]="4">
                    </fieldset>
                </div>
            </div>
            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new"
                        [disabled]="pinEntrySubmissionDisabled">
                    {{ 'screens.register.onBoarding.opticalReader.pinEntry.buttonAccept' | translate }}
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" class="eon-link-new"
                   (click)="setPIN(null)">
                    {{ 'screens.register.onBoarding.opticalReader.pinEntry.linkSkip' | translate }}
                </a>
            </div>
        </form>
    </section>
</main>
