import {DetailBannerData} from '../../interfaces/detail-banner.interfaces';

export const appliancesProfileUpdateBanner: DetailBannerData = {
    paragraphs: [
        {
            type: 'text',
            text: '',
            buttonText: '',
            buttonId: ''
        },
        {
            type: 'button',
            text: '',
            buttonText: '',
            buttonId: 'update-profile'
        }
    ],
    sideButton: {
        visible: true,
        isClose: true
    }
};

export const appliancesRetrainingBanner: DetailBannerData = {
    paragraphs: [
        {
            type: 'text',
            text: '',
            buttonText: '',
            buttonId: ''
        }
    ],
    sideButton: {
        visible: true,
        isClose: false
    }
};
